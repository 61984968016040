import PasswordRulesList, {
  PasswordRule,
} from '@components/PasswordRulesInput/components/PasswordRulesList';
import PasswordInput from '@pedidosya/web-fenix/atoms/PasswordInput';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

type PasswordRulesInputProps = {
  autoFocus?: boolean;
  id?: string;
  label: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void | Promise<void>;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void | Promise<void>;
  rules: PasswordRule[];
  successMessage?: string;
};

const PasswordRulesInput = ({
  autoFocus = false,
  id,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  rules,
  successMessage,
  ...props
}: PasswordRulesInputProps): JSX.Element => {
  const [passwordFocus, setPasswordFocus] = React.useState(false);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setPasswordFocus(false);
    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setPasswordFocus(true);
    if (typeof onFocus === 'function') {
      onFocus(event);
    }
  };

  return (
    <Container>
      <PasswordInput
        id={id}
        autoFocus={autoFocus}
        label={label}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...props}
      />
      <PasswordRulesList
        rules={rules}
        showSuccessMessage={!passwordFocus}
        successMessage={successMessage}
      />
    </Container>
  );
};

export default PasswordRulesInput;
