import circle from '@assets/icons/circle_gray_filled.svg';
import { Text } from '@components/Fenix/Typography';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import * as React from 'react';
import styled from 'styled-components';

const RuleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space('spacing-component-medium')};
  margin-left: ${({ theme }) => theme.space('spacing-component-xlarge')};
`;

export const RuleItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space('spacing-component-medium')};
`;

const Image = styled.img``;

import { RuleListProps } from './types';

function PasswordRulesList({
  rules,
  showSuccessMessage,
  successMessage,
}: RuleListProps): JSX.Element {
  const hasUncheckedRules = rules.some((rule) => rule.checked === false);

  return (
    <>
      <RuleList>
        {(hasUncheckedRules || !showSuccessMessage) &&
          rules.map((rule) => (
            <RuleItem key={rule.order}>
              {rule.checked ? (
                <FenixIcon
                  token="circle-check"
                  size="small"
                  color="icon-color-action-validated-default"
                  filled
                />
              ) : (
                <Image src={circle} />
              )}
              <Text size="small" color={rule.checked ? 'feedback-positive' : 'action-inactive'}>
                {rule.message}
              </Text>
            </RuleItem>
          ))}
      </RuleList>
      {rules.length > 0 && !hasUncheckedRules && showSuccessMessage && (
        <Text size="small" color="feedback-positive" style={{ marginLeft: '8px' }}>
          {successMessage}
        </Text>
      )}
    </>
  );
}

export default PasswordRulesList;
