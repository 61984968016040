import { createGlobalStyle } from 'styled-components';

const RootGlobalStyle = createGlobalStyle`
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;

  /* disable text selection highlighting */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

body {
  background-color: #F9F6F4;

  .modal__overlay {
    background-color: ${({ theme }) => theme.color('shape-color-background-secondary')};
  }
}

a, a:hover {
  text-decoration: none;
}

input, textarea {
  -webkit-user-select: auto !important;
  cursor: text;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

button:focus-visible,
[role=button]:focus-visible,
a:focus-visible,
input:focus,
textarea:focus {
  outline: none !important;
}

h1, fieldset {
  margin: 0px;
  padding: 0px;
}
`;

export default RootGlobalStyle;
