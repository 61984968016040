import { MobileOnlyComponent } from '@components/MobileOnlyComponent';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { FIXED_FOOTER_ID } from './constants';

const Container = styled.div`
  position: fixed;
  ${({ allowedWidth, placeholderHeight }) =>
    allowedWidth > 0
      ? css`
          width: ${allowedWidth}px;
          min-height: ${placeholderHeight}px;
        `
      : css`
          position: inherit;
        `};
`;

const ContainerFooterRef = styled.div`
  ${({ placeholderHeight }) =>
    placeholderHeight &&
    css`
      height: ${placeholderHeight}px;
    `}
`;

class FixedFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholderHeight: 0,
      allowedWidth: 0,
    };

    this.footerRef = React.createRef();
    this.containerFooterRef = React.createRef();
  }

  componentDidMount() {
    this.setPlaceholderHeight();
  }

  setPlaceholderHeight = () => {
    const placeholderHeight = this.footerRef?.current?.clientHeight;
    const allowedWidth = this.containerFooterRef?.current?.clientWidth;

    if (placeholderHeight > 0) this.setState({ placeholderHeight });
    if (allowedWidth > 0) this.setState({ allowedWidth });
  };

  render() {
    const { children, className } = this.props;
    const { placeholderHeight, allowedWidth } = this.state;
    return (
      <>
        <Container
          id={FIXED_FOOTER_ID}
          className={className}
          ref={this.footerRef}
          allowedWidth={allowedWidth}
          placeholderHeight={placeholderHeight}
        >
          {children}
        </Container>
        <MobileOnlyComponent>
          <ContainerFooterRef
            placeholderHeight={placeholderHeight}
            ref={this.containerFooterRef}
            id="fixed_footer_ref"
          />
        </MobileOnlyComponent>
      </>
    );
  }
}

FixedFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]).isRequired,
  className: PropTypes.string.isRequired,
};

export default styled(FixedFooter)``;
