import PropTypes from 'prop-types';
import React from 'react';

function Form({ onSubmit, children, ...props }) {
  const onFormSubmit = (e) => {
    // remove focus from all elements
    // do not remove, prevent some visual bugs
    if (document.activeElement) {
      document.activeElement.blur();
    }

    if (e) e.preventDefault();
    onSubmit(e);
  };

  return (
    <form {...props} onSubmit={onFormSubmit}>
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  noValidate: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

Form.defaultProps = {
  noValidate: true,
};

export default Form;
