import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CodeField from '@pedidosya/web-fenix/atoms/CodeField';

const ONLY_NUMBERS_REGEX = /^[0-9\b]+$/;
let oldValue = '';

const Digit = ({
  code,
  current,
  onFocus,
  onKeyDown,
  onChange,
  currentInput,
  showCodeInputError,
  setCodeInputError,
}) => {
  const [currentState, setCurrentState] = useState(current === currentInput ? 'active' : 'enabled');
  const inputRefs = useRef([]);

  const handleKeyDown = (event) => {
    const input = event.target;
    oldValue = input.value;
    onKeyDown(current - 1)(event);
  };

  const handleInput = (event) => {
    const input = event.target;
    if (!ONLY_NUMBERS_REGEX.test(input.value)) {
      input.value = input.value ? oldValue : '';
    }
  };

  const handleChange = (event) => {
    const input = event.target;
    if (input.value !== oldValue && (input.value === '' || ONLY_NUMBERS_REGEX.test(input.value))) {
      onChange({ digit: input.value, index: current });
    }
  };

  useEffect(() => {
    const inputRef = inputRefs.current[currentInput];

    if (inputRef) {
      inputRef.focus();
      setCurrentState('active');
    } else {
      setCurrentState('enabled');
    }

    if (showCodeInputError) {
      setCurrentState('error');
    }
  }, [currentInput, showCodeInputError]);

  return (
    <CodeField
      aria-label={`Digit-${current}`}
      type="tel"
      disabled={!(current === currentInput)}
      maxlength={1}
      value={code}
      state={currentState}
      size="large"
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      onChange={handleChange}
      onClick={() => setCodeInputError(null)}
      onFocus={() => setCodeInputError(null) && onFocus(current)}
      ref={(inputRef) => {
        inputRefs.current[current] = inputRef;
      }}
    />
  );
};

Digit.propTypes = {
  code: PropTypes.string.isRequired,
  currentInput: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

export default Digit;
