import { Link } from '@app/Router';
import { RoutePaths } from '@app/routes';
import {
  BottomSheetTrackingVariation,
  BottomSheetTrackingView,
  TrackingActions,
  TrackingEvents,
} from '@app/tracking';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import BottomSheet from '@components/BottomSheet';
import { useIntl } from '@providers/IntlProvider';
import { useTracker } from '@providers/TrackerProvider';
import { isIOSDevice } from '@utils/utils';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ChoosePhotoItem, { FileProps } from './components/ChoosePhotoItem';
import CropperModal from './components/CropperModal';
import DeletePhotoItem from './components/DeletePhotoItem';
import DropDownOption from './components/DropDownOption';

const messages = defineMessages({
  title: {
    id: 'changeAvatarBottomSheet.title',
    defaultMessage: 'Edición de foto',
  },
  take_photo_button: {
    id: 'changeAvatarBottomSheet.take_photo_button',
    defaultMessage: 'Tomar una foto',
  },
  choose_photo_button: {
    id: 'changeAvatarBottomSheet.choose_photo_button',
    defaultMessage: 'Elegir de la galería',
  },
  change_photo_button: {
    id: 'changeAvatarBottomSheet.change_photo_button',
    defaultMessage: 'Cambiar foto',
  },
  delete_photo_button: {
    id: 'changeAvatarBottomSheet.delete_photo_button',
    defaultMessage: 'Eliminar foto',
  },
  choosePhotoErrorResponse: {
    id: 'changeAvatarBottomSheet.choose_photo.error_response',
    defaultMessage: 'No pudimos cargar tu foto. Reintenta en unos minutos.',
  },
  choosePhotoSuccessResponse: {
    id: 'changeAvatarBottomSheet.choose_photo.success_response',
    defaultMessage: 'Cambiaste tu foto de perfil.',
  },
  deletePhotoErrorResponse: {
    id: 'changeAvatarBottomSheet.delete_photo.error_response',
    defaultMessage: 'No pudimos eliminar tu foto. Reintenta en unos minutos.',
  },
  deletePhotoSuccessResponse: {
    id: 'changeAvatarBottomSheet.delete_photo.success_response',
    defaultMessage: 'Eliminaste tu foto de perfil.',
  },
});

const OptionsList = styled.ul`
  padding: ${({ theme }) => `${theme.space('spacing-06')} ${theme.space('spacing-0')}`};
`;

type ChangeAvatarBottomSheetProps = {
  isOpen: boolean;
  close: () => void;
  isAvatarChangeLoading: (value: boolean) => void;
};

const INIT_VALUE: FileProps = {
  img: '',
  type: '',
};

const ChangeAvatarBottomSheet = ({
  isOpen = false,
  close,
  isAvatarChangeLoading,
}: ChangeAvatarBottomSheetProps): JSX.Element => {
  const isIOS = isIOSDevice();
  const { formatMessage } = useIntl();
  const tracker = useTracker();
  const navigate = useNavigate();
  const [file, setFile] = React.useState(INIT_VALUE);
  const [isCropperOpen, setCropperOpen] = React.useState(false);

  const handleCancelPress = (): void => {
    tracker.track(TrackingEvents.BottomSheetClicked, {
      bottomSheetVariation: BottomSheetTrackingVariation.ProfilePicture,
      clickLocation: TrackingActions.Cancel,
      no_number_check_box: '(not set)',
    });
    close();
  };

  const handleCropperModalClose = (): void => {
    setCropperOpen(false);
    setFile(INIT_VALUE);
  };

  const handleCropperModalAccept = (): void => {
    handleCropperModalClose();
    navigate(RoutePaths.Home, { state: { reload: true } });
  };

  const handleTakePhotoPress = (): void => {
    tracker.track(TrackingEvents.BottomSheetClicked, {
      bottomSheetVariation: BottomSheetTrackingVariation.ProfilePicture,
      clickLocation: TrackingActions.TakePicture,
      no_number_check_box: '(not set)',
    });
    close();
  };

  const handleChoosePhotoPress = (): void => {
    tracker.track(TrackingEvents.BottomSheetClicked, {
      bottomSheetVariation: BottomSheetTrackingVariation.ProfilePicture,
      clickLocation: isIOS ? TrackingActions.ChangePicture : TrackingActions.SelectPicture,
      no_number_check_box: '(not set)',
    });
  };

  const handleChoosePhotoSuccess = (file: FileProps): void => {
    close();
    setFile(file);
    setCropperOpen(true);
  };

  const handleDeletePhotoPress = (): void => {
    tracker.track(TrackingEvents.BottomSheetClicked, {
      bottomSheetVariation: BottomSheetTrackingVariation.ProfilePicture,
      clickLocation: TrackingActions.DeletePicture,
      no_number_check_box: '(not set)',
    });
  };

  const handleDeletePhotoSuccess = (): void => {
    close();
    navigate(RoutePaths.Home, { state: { reload: true } });
  };

  React.useEffect(() => {
    if (isOpen) {
      tracker.track(TrackingEvents.BottomSheetShown, {
        bottomSheetView: BottomSheetTrackingView.Low,
        bottomSheetVariation: BottomSheetTrackingVariation.ProfilePicture,
      });
    }
  }, [isOpen, tracker]);

  return (
    <>
      <CropperModal
        isOpen={isCropperOpen}
        file={file}
        successMessage={formatMessage(messages.choosePhotoSuccessResponse)}
        errorMessage={formatMessage(messages.choosePhotoErrorResponse)}
        accept={handleCropperModalAccept}
        close={handleCropperModalClose}
        onLoading={isAvatarChangeLoading}
      />
      <BottomSheet
        open={isOpen}
        title={formatMessage(messages.title)}
        onCancel={handleCancelPress}
        onClickOut={close}
      >
        <OptionsList role="list" aria-label="Opciones de Avatar">
          {!isIOS && (
            <Link to={RoutePaths.EditAvatar}>
              <DropDownOption
                label={formatMessage(messages.take_photo_button)}
                icon={<FenixIcon token="camera" color="icon-color-tertiary" size="small" />}
                onClick={handleTakePhotoPress}
              />
            </Link>
          )}
          <ChoosePhotoItem
            label={
              isIOS
                ? formatMessage(messages.change_photo_button)
                : formatMessage(messages.choose_photo_button)
            }
            errorMessage={formatMessage(messages.choosePhotoErrorResponse)}
            onSuccess={handleChoosePhotoSuccess}
            onError={close}
            onClick={handleChoosePhotoPress}
          />
          <DeletePhotoItem
            label={formatMessage(messages.delete_photo_button)}
            successMessage={formatMessage(messages.deletePhotoSuccessResponse)}
            errorMessage={formatMessage(messages.deletePhotoErrorResponse)}
            onLoading={isAvatarChangeLoading}
            onSuccess={handleDeletePhotoSuccess}
            onError={close}
            onClick={handleDeletePhotoPress}
          />
        </OptionsList>
      </BottomSheet>
    </>
  );
};

export default ChangeAvatarBottomSheet;
