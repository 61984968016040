import type { PublicUser } from '@app/types';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import * as queryKeys from '@utils/query-key-factory';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export function useMe(): UseQueryResult<PublicUser> {
  return useQuery(queryKeys.me(), async function getUser(): Promise<PublicUser> {
    const response = await axios.get<PublicUser>(USER_ENDPOINTS.ME);

    return response.data;
  });
}
