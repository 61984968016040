import { Skeleton } from '@pedidosya/web-fenix/atoms';
import * as React from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.space('spacing-08')};
  width: 100%;
  box-sizing: border-box;
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;
`;

const TitleSection = styled(ItemContent)`
  padding: ${({ theme }) => theme.space('spacing-08')};
  margin-bottom: ${({ theme }) => theme.space('spacing-07')};
  gap: 4px;
`;

const BorderBottom = styled.div`
  width: calc(100% - ${({ theme }) => theme.space('spacing-16')});
  background-color: ${({ theme }) => theme.color('shape-color-surface-action-oncontent-pressed')};
  margin: 0 auto;
  height: 1px;
`;

function FavoritesSkeleton(): JSX.Element {
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      <TitleSection>
        <Skeleton
          width="shape-size-action-50"
          height="shape-size-action-4"
          shape="square"
          style={{ marginBottom: '12px' }}
        />
        <Skeleton width="shape-size-action-24" height="shape-size-action-4" shape="square" />
        <Skeleton width="shape-size-action-50" height="shape-size-action-4" shape="square" />
      </TitleSection>
      {Array.from({ length: 12 })
        .fill('')
        .map((_n, i) => (
          <React.Fragment key={i}>
            <ItemContainer>
              <Skeleton
                width="shape-size-action-12"
                height="shape-size-action-12"
                shape="square"
                style={{ marginRight: '8px' }}
              />
              <ItemContent>
                <Skeleton height="shape-size-action-4" shape="square" style={{ width: '100%' }} />
                <Skeleton height="shape-size-action-4" shape="square" style={{ width: '100%' }} />
                <Skeleton height="shape-size-action-4" shape="square" style={{ width: '50%' }} />
              </ItemContent>
            </ItemContainer>
            <BorderBottom />
          </React.Fragment>
        ))}
    </>
  );
}

export { FavoritesSkeleton };
