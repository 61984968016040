import { usePublicEnv } from '@app/contexts/PublicEnv';
import { GlobalRoutePaths } from '@app/routes';
import { TrackingClickLocations, TrackingEvents } from '@app/tracking';
import { PublicEnvironment } from '@app/types';
import pedidosYa from '@assets/pedidosya.svg';
import { ItemSection } from '@components/Fenix/ItemSection';
import { Title } from '@components/Fenix/Typography';
import { PageTitle } from '@components/Shell';
import ShellFooter from '@components/ShellFooter';
import { useFlagVariations } from '@hooks/useFlagVariations';
import { BottomNav } from '@pedidosya/web-bottom-nav';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import fenixTheme from '@pedidosya/web-fenix/theme';
import { useAuth } from '@providers/AuthProvider';
import { useDevice } from '@providers/DeviceProvider';
import { useIntl } from '@providers/IntlProvider';
import { useTracker } from '@providers/TrackerProvider';
import { SUPPORT_ITEMS } from '@services/my-account';
import { getExternalLoginUrl } from '@utils/external-login';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

const messages = defineMessages({
  title: {
    id: 'menu.title',
    defaultMessage: 'Te damos la bienvenida',
  },
  help_title: {
    id: 'menu.help_title',
    defaultMessage: 'Ayuda en línea',
  },
  terms_title: {
    id: 'menu.terms_title',
    defaultMessage: 'Términos y condiciones',
  },
  policies_title: {
    id: 'menu.policies_title',
    defaultMessage: 'Políticas de privacidad',
  },
  register_login_title: {
    id: 'menu.register_login_title',
    defaultMessage: 'Registrarme / Iniciar sesión',
  },
});

type IntlMessage = keyof typeof messages;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
`;

const HeaderContainer = styled.div`
  margin: 0 25% 50px;
  text-align: center;
`;

const ListItemsContainer = styled.div``;

const LoginItemContainer = styled.div`
  padding-bottom: 8px;
`;

const Image = styled.img`
  display: block;
  width: 72px;
  height: 72px;
  margin: 0 auto 16px;
`;

type ItemIconProps = {
  icon: string;
};

function ItemIcon({ icon }: ItemIconProps): JSX.Element {
  return <FenixIcon token={icon} color="icon-color-primary" size="small" />;
}

const goToHome = () => {
  window.location.href = GlobalRoutePaths.Home;
};

function Header(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <HeaderContainer>
      <Image src={pedidosYa} alt="PedidosYa" />
      <Title>{formatMessage(messages.title)}</Title>
    </HeaderContainer>
  );
}

type GroupItemPressProperties = {
  url: string;
  tracking: {
    name: string;
  };
};

type GroupItemPressHandler = ({ url, tracking }: GroupItemPressProperties) => () => void;

function ListItems(): JSX.Element {
  const { formatMessage } = useIntl();
  const tracker = useTracker();

  const handleItemPress: GroupItemPressHandler =
    ({ url, tracking }: GroupItemPressProperties) =>
    () => {
      tracker.track(TrackingEvents.MyAccountClicked, {
        userLoggedIn: false,
        clickLocation: tracking.name,
      });

      window.location.href = url;
    };

  return (
    <ListItemsContainer>
      {SUPPORT_ITEMS.map((item, index) => {
        const label =
          item.title in messages ? formatMessage(messages[item.title as IntlMessage]) : '';
        const isLastItem = index + 1 === SUPPORT_ITEMS.length;

        return (
          <ItemSection
            key={item.link}
            label={label}
            icon={<ItemIcon icon={item.icon} />}
            borderBottom={!isLastItem}
            onClick={handleItemPress({ url: item.link, tracking: item.tracking })}
          />
        );
      })}
    </ListItemsContainer>
  );
}

function LoginSection(): JSX.Element {
  const { formatMessage } = useIntl();
  const tracker = useTracker();

  const { environment, country }: PublicEnvironment = usePublicEnv();
  const externalLoginUrl = getExternalLoginUrl({
    environment,
    countryShortName: country.shortName,
  });

  const handleLoginRegisterPress = (url: string) => () => {
    tracker.track(TrackingEvents.MyAccountClicked, {
      userLoggedIn: false,
      clickLocation: TrackingClickLocations.LoginRegister,
    });

    window.location.href = url;
  };

  return (
    <LoginItemContainer>
      <ItemSection
        label={formatMessage(messages.register_login_title)}
        icon={<ItemIcon icon="log-in" />}
        onClick={handleLoginRegisterPress(externalLoginUrl)}
      />
    </LoginItemContainer>
  );
}

function LoggedOutUserMenu(): JSX.Element {
  const { isAuthenticated: isLoggedUser } = useAuth();
  const { isBottomNavHomeEnabled } = useFlagVariations();
  const { isDesktop } = useDevice();
  const tracker = useTracker();

  React.useEffect(() => {
    if (!isDesktop) {
      document.body.style.backgroundColor = fenixTheme.color('shape-color-background-primary');
    }

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [isDesktop]);

  return (
    <React.Fragment>
      <PageTitle showBack={!isBottomNavHomeEnabled} onBack={goToHome} />
      <Container data-testid="logged-out-user-menu-div">
        <Header />
        <LoginSection />
        <ListItems />
      </Container>
      {isBottomNavHomeEnabled && (
        <ShellFooter hideBorderTop={true}>
          <BottomNav tracker={tracker} origin={'my_profile'} userInfo={{ isLoggedUser }} />
        </ShellFooter>
      )}
    </React.Fragment>
  );
}

export { LoggedOutUserMenu };
