import styled from 'styled-components';

const TitleContainer = styled.div`
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme }) => theme.color('shape-color-surface-action-oncontent-focused')};
`;

export default TitleContainer;
