import React from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import fenixTheme from '@pedidosya/web-fenix/theme';
import BoxMessage from '@pedidosya/web-fenix/molecules/BoxMessage';

export const ErrorBoundary = ({ error, ...props }) => {
  return (
    <ThemeProvider theme={fenixTheme}>
      <BoxMessage
        variant="quiet"
        type="error"
        title="Error"
        message="Tuvimos un problema"
        onClose={() => {}}
        {...props}
      />
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
