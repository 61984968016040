import { Text } from '@components/Fenix/Typography';
import { useIntl } from '@providers/IntlProvider';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

const messages = defineMessages({
  retake: {
    id: 'photo_confirmation.retake',
    defaultMessage: 'Repetir',
  },
  usePhoto: {
    id: 'photo_confirmation.use_photo',
    defaultMessage: 'Usar foto',
  },
});

const Container = styled.div`
  align-items: center;
  background: #000;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  position: relative;
  width: 100vw;
`;

const Footer = styled.footer`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  height: 30vh;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
`;

const ActionContainer = styled.div`
  align-items: center;
  background: rgb(30, 30, 30);
  display: flex;
  flex-grow: 1;
  height: 30%;
  justify-content: space-between;
`;

const PhotoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const Photo = styled.img``;

type PhotoConfirmationProps = {
  photo: string;
  onRetakePress: () => void;
  onUsePhotoPress: () => void;
};

function PhotoConfirmation({
  photo,
  onRetakePress,
  onUsePhotoPress,
}: PhotoConfirmationProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <PhotoContainer>
        <Photo src={photo} alt="avatar" />
      </PhotoContainer>
      <Footer>
        <ActionContainer>
          <Text color="inverted" style={{ padding: '0 16px' }} onClick={onRetakePress}>
            {formatMessage(messages.retake)}
          </Text>
          <Text color="inverted" style={{ padding: '0 16px' }} onClick={onUsePhotoPress}>
            {formatMessage(messages.usePhoto)}
          </Text>
        </ActionContainer>
      </Footer>
    </Container>
  );
}

export { PhotoConfirmation };
