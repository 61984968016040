import ServiceResponse from '@commons/services/models/ServiceResponse';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

type DeleteAccountRequest = {
  token: string;
};

export function useDeleteAccount() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ token }: DeleteAccountRequest) => {
      try {
        await axios.delete(USER_ENDPOINTS.ME, {
          params: { token },
        });

        return new ServiceResponse({ success: true });
      } catch (error) {
        return new ServiceResponse({ error });
      }
    },
    {
      onSettled: () => {
        queryClient.clear();
      },
    },
  );
}
