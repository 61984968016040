import { defineMessages } from 'react-intl';

const successMessages = defineMessages({
  title: {
    id: 'delete.success.title',
    defaultMessage: '¡Hasta pronto!',
  },
  subtitle: {
    id: 'delete.success.subtitle',
    defaultMessage:
      'En minutos se eliminará tu cuenta. Para volver a pedir, tendrás que registrarte de nuevo.',
  },
  done: {
    id: 'delete.success.done',
    defaultMessage: 'Entendido',
  },
});

const tokenErrorMessages = defineMessages({
  title: {
    id: 'delete.tokenError.title',
    defaultMessage: 'Este link ya no está disponible',
  },
  subtitle: {
    id: 'delete.tokenError.subtitle',
    defaultMessage:
      'Para eliminar tu cuenta, ingresa a la sección “Ayuda en línea” en “Mi perfil”.',
  },
  done: {
    id: 'delete.tokenError.done',
    defaultMessage: 'Entendido',
  },
});

const sessionErrorMessages = defineMessages({
  title: {
    id: 'delete.sessionError.title',
    defaultMessage: 'No pudimos eliminar tu cuenta',
  },
  subtitle: {
    id: 'delete.sessionError.subtitle',
    defaultMessage: 'Para continuar, inicia sesión desde “Ingresar” en la barra inferior.',
  },
  done: {
    id: 'delete.sessionError.done',
    defaultMessage: 'Entendido',
  },
});

const genericErrorMessages = defineMessages({
  title: {
    id: 'delete.genericError.title',
    defaultMessage: 'No pudimos eliminar tu cuenta',
  },
  subtitle: {
    id: 'delete.genericError.subtitle',
    defaultMessage: 'Reintenta en unos minutos desde la sección “Ayuda en línea” en “Mi perfil”.',
  },
  done: {
    id: 'delete.genericError.done',
    defaultMessage: 'Entendido',
  },
});

export { genericErrorMessages, sessionErrorMessages, successMessages, tokenErrorMessages };
