import { GlobalRoutePaths, RoutePaths } from '@app/routes';
import { TrackingClickLocations, TrackingEvents } from '@app/tracking';
import {
  ChangeAvatarBottomSheetProvider,
  useChangeAvatarBottomSheet,
} from '@providers/ChangeAvatarBottomSheetProvider';
import { useDevice } from '@providers/DeviceProvider';
import { useTracker } from '@providers/TrackerProvider';
import * as React from 'react';
import { Outlet, useMatch } from 'react-router';
import { Col, Grid, Row } from './components/Grid';
import { Header } from './components/Header';
import { MenuNavigation } from './components/MenuNavigation';

const goToHome = (): void => {
  window.location.href = GlobalRoutePaths.Home;
};

const DesktopLayout = (): JSX.Element => {
  const tracker = useTracker();
  const { open: openChangeAvatarBottomSheet } = useChangeAvatarBottomSheet();

  const handleCameraPress = (): void => {
    tracker.track(TrackingEvents.MyAccountClicked, {
      userLoggedIn: true,
      clickLocation: TrackingClickLocations.ProfilePicture,
    });

    openChangeAvatarBottomSheet();
  };

  return (
    <React.Fragment>
      <Header onBack={goToHome} />
      <Grid>
        <Row fullSize>
          <Col size={1}>
            <MenuNavigation onCameraClick={handleCameraPress} />
          </Col>
          <Col size={2}>
            <Outlet />
          </Col>
          <Col size={1} collapse="lg"></Col>
        </Row>
      </Grid>
    </React.Fragment>
  );
};

const MobileLayout = (): JSX.Element => <Outlet />;

const Layout = (): JSX.Element => {
  const { isDesktop } = useDevice();

  const isEditAvatarRoute =
    useMatch({
      path: RoutePaths.EditAvatar,
    }) !== null;

  if (isEditAvatarRoute) {
    return <Outlet />;
  }

  return isDesktop ? <DesktopLayout /> : <MobileLayout />;
};

const Shell = (): JSX.Element => (
  <ChangeAvatarBottomSheetProvider>
    <Layout />
  </ChangeAvatarBottomSheetProvider>
);

export { Shell };
