import { To } from 'history';

enum GlobalRoutePaths {
  ClearSession = '/clear-session',
  Home = '/',
  ShopList = '/restaurantes?lat={0}&lng={1}',
  CustomerService = '/customer-service',
}

enum RoutePaths {
  ChangePassword = '/my-account/change-password',
  Delete = '/my-account/delete',
  Home = '/my-account',
  EditAvatar = '/my-account/edit-avatar',
  EditPersonalInformation = '/my-account/edit-personal-information',
  PersonalData = '/my-account/personal-data',
  PhoneValidation = '/my-account/phone-validation',
  Favorites = '/my-account/favorites',
}

type ShellRoutePaths = Exclude<
  RoutePaths,
  RoutePaths.Delete | RoutePaths.PhoneValidation | RoutePaths.Favorites
>;

const routes = Object.values(RoutePaths) as string[];

export function isMicrositeRoute(route: To) {
  return typeof route === 'string' && routes.includes(route);
}

export { GlobalRoutePaths, RoutePaths, ShellRoutePaths };
