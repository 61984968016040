import type { PublicUser } from '@app/types';
import * as React from 'react';

type AuthContextProperties = {
  isAuthenticated: boolean;
  user: PublicUser;
};

const AuthContext = React.createContext<AuthContextProperties>(undefined as AuthContextProperties);
AuthContext.displayName = 'AuthContext';

type AuthProviderProperties = {
  children: React.ReactNode;
  user: PublicUser;
};

function AuthProvider({ children, user }: AuthProviderProperties): JSX.Element {
  const value = React.useMemo(
    () => ({ isAuthenticated: Object.keys(user).length > 0, user }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth(): AuthContextProperties {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
