import styled from 'styled-components';

export const BottomSheetContainer = styled.div`
  padding: 0px ${({ theme }) => theme.space('spacing-10')};
`;

export const CountryContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color('shape-color-stroke-primary')};
  padding-bottom: ${({ theme }) => theme.space('spacing-08')};
  padding-top: ${({ theme }) => theme.space('spacing-10')};
  cursor: pointer;
`;

export const CountryContent = styled.div`
  align-items: center;
  display: flex;
  height: 23px;
`;

export const CountryFlag = styled.img`
  height: 14px;
  width: 20px;
`;

export const CountryName = styled.div`
  flex-grow: 1;
  padding: 0 ${({ theme }) => theme.space('spacing-06')};
`;
