import { usePublicEnv } from '@app/contexts/PublicEnv';
import { TrackingEvents, TrackingOrigins } from '@app/tracking';
import { ChannelPhoneConfig, ChannelsLabels, CountryPhoneConfig } from '@app/types';
import { FormBody, FormFooter } from '@components/Form';
import { Layout } from '@components/Layout';
import OverlayLoader from '@components/OverlayLoader';
import { PageTitle } from '@components/Shell';
import { useCountriesPhoneConfig } from '@hooks/use-countries-phone-config';
import { useChannelsPhoneConfig } from '@hooks/use-channels-phone-config';
import { useSendPhoneCode } from '@hooks/use-send-phone-code';
import { Button } from '@pedidosya/web-fenix/atoms';
import { useAuth } from '@providers/AuthProvider';
import { useIntl } from '@providers/IntlProvider';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CountrySelector from './components/CountryConfigurations';
import PhoneContainer from './components/PhoneContainer';
import { PHONE_VALIDATION_TYPE } from './../../constants';
import { DEFAULT_SEND_CHANNELS, BUTTONS_ORDER, MIN_PHONE_LENGTH } from './constants';
import pvMessages from './../../messages';
import messages from './messages';

const FooterButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.space('spacing-04')};
`;

type EnterPhoneNumberSuccessData = {
  phoneNumber: string;
  phoneValidationType: string;
  channels: ChannelPhoneConfig[];
};

type EnterPhoneNumberProps = {
  onSuccess: ({ phoneNumber, phoneValidationType, channels }: EnterPhoneNumberSuccessData) => void;
  onBack: () => void;
};

function EnterPhoneNumber({ onSuccess, onBack }: EnterPhoneNumberProps): JSX.Element {
  const { isAuthenticated, user } = useAuth();
  const { country: countryEnv } = usePublicEnv();
  const { formatMessage } = useIntl();
  const toast = useToast();
  const tracker = useTracker();

  const [showCountrySelector, setShowCountrySelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryPhoneConfig>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hasTrackedStart, setHasTrackedStart] = useState(false);

  const { data: countries, isLoading: isLoadingCountries } = useCountriesPhoneConfig();
  const { data: sendCodeChannels, isLoading: isLoadingChannels } = useChannelsPhoneConfig();
  const { mutateAsync: sendPhoneCodeMutateAsync, isLoading: isLoadingSendPhoneCode } =
    useSendPhoneCode();

  useEffect(() => {
    if (!hasTrackedStart && selectedCountry) {
      setHasTrackedStart(true);

      tracker.track(TrackingEvents.PhoneValidationStarted, {
        phoneValidationOrigin: TrackingOrigins.Home,
        countryPrefix: selectedCountry?.phonePrefix,
      });
    }
  }, [hasTrackedStart, selectedCountry, tracker]);

  useEffect(() => {
    if (countries) {
      const currentCountry = countries.find(({ shortName }) => shortName === countryEnv.shortName);
      setSelectedCountry(currentCountry || countries[0]);
    }
  }, [countries, countryEnv.shortName]);

  const handleCountryClose = () => setShowCountrySelector(false);
  const handleCountryPress = () => setShowCountrySelector(true);

  const handleCountrySelect = (country: CountryPhoneConfig) => {
    setSelectedCountry(country);
    setShowCountrySelector(false);
  };

  const handlePhoneNumberChange = ({ target: { value } }: any) => setPhoneNumber(value);

  const getButtonsOrdered = () => {
    if (!sendCodeChannels) return DEFAULT_SEND_CHANNELS;
    return sendCodeChannels.channels?.sort((a, b) => a.priority - b.priority);
  };
  const channelButtons = getButtonsOrdered();

  const getButtonLabel = (key: string) => formatMessage(pvMessages[key as ChannelsLabels] ?? null);
  const checkPriority = (priority: number | string) => priority === BUTTONS_ORDER.FIRST_ORDER;

  const requestCode = async (type: string) => {
    tracker.track(TrackingEvents.PhoneValidationSent, {
      phoneValidationOrigin: TrackingOrigins.Home,
      phoneValidationType: type,
      countryPrefix: selectedCountry?.phonePrefix,
    });

    const fullPhoneNumber = `${selectedCountry?.phonePrefix}${phoneNumber}`;

    const { success, error } = await sendPhoneCodeMutateAsync({
      type,
      phoneNumber: fullPhoneNumber,
      userId: isAuthenticated ? user.id : null,
    });

    if (error) {
      toast.error(`${formatMessage(messages[error?.code] ?? messages.genericError)}`);
    }

    if (success) {
      onSuccess({
        phoneNumber: fullPhoneNumber,
        phoneValidationType: type,
        channels: channelButtons,
      });
    }
  };

  const sendCode = (type: string) => {
    if ([PHONE_VALIDATION_TYPE.SMS, PHONE_VALIDATION_TYPE.WHATSAPP].includes(type)) {
      requestCode(type.toLowerCase());
    }
  };

  const hasInitialData =
    !isLoadingChannels && !isLoadingCountries && countries?.length > 0 && selectedCountry;
  const isButtonDisabled = phoneNumber.length < MIN_PHONE_LENGTH || isLoadingSendPhoneCode;

  if (!hasInitialData) {
    return <OverlayLoader />;
  }

  return (
    <>
      <CountrySelector
        title={formatMessage(messages.countriesTitle)}
        countries={countries}
        selectedCountry={selectedCountry}
        open={showCountrySelector}
        onSelect={handleCountrySelect}
        onClickOut={handleCountryClose}
      />
      <Layout isLoading={isLoadingSendPhoneCode}>
        <PageTitle title={formatMessage(messages.title)} onBack={onBack} />
        <FormBody>
          <PhoneContainer
            phoneNumber={phoneNumber}
            handleChange={handlePhoneNumberChange}
            onCountryPress={handleCountryPress}
            country={selectedCountry}
          />
        </FormBody>
        <FormFooter hideBorderTop>
          <FooterButtonsContainer>
            {channelButtons.map(({ channel, priority }) => (
              <Button
                key={channel}
                elementId={`send_code_${channel}_button`}
                label={getButtonLabel(channel)}
                hierarchy={checkPriority(priority) ? 'primary' : 'tertiary'}
                size="large"
                state={isButtonDisabled ? 'disabled' : 'enabled'}
                disabled={isButtonDisabled}
                fullWidth={checkPriority(priority)}
                onClick={() => sendCode(channel)}
              />
            ))}
          </FooterButtonsContainer>
        </FormFooter>
      </Layout>
    </>
  );
}

export { EnterPhoneNumber, EnterPhoneNumberSuccessData };
