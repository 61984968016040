import { GlobalRoutePaths, isMicrositeRoute, RoutePaths } from '@app/routes';
import {
  TrackingBannerHighlightType,
  TrackingBannerScreenType,
  TrackingClickLocations,
  TrackingEvents,
} from '@app/tracking';
import type { PublicUser } from '@app/types';
import defaultAvatarPNG from '@assets/default-avatar.png';
import plusBackground from '@assets/plus/plus-background.svg';
import tagPlus from '@assets/plus/plus-tag.svg';
import { ItemSection } from '@components/Fenix/ItemSection';
import OverlayLoader from '@components/OverlayLoader';
import ShellFooter from '@components/ShellFooter';
import {
  Label,
  Subtitle as CategoryTitle,
  Text,
  Title as Username,
} from '@components/Fenix/Typography';
import { Layout } from '@components/Layout';
import { LogoutProvider, useLogoutModal } from '@components/Logout';
import {
  LogoutFromOtherDevicesProvider,
  useLogoutFromOtherDevicesModal,
} from '@components/LogoutFromOtherDevices';
import { MobileOnlyComponent } from '@components/MobileOnlyComponent';
import { PageTitle } from '@components/Shell';
import { useFlagVariations } from '@hooks/useFlagVariations';
import { useHomeConfiguration } from '@hooks/use-home-configuration';
import { useMe } from '@hooks/use-me';
import { useUserPlusEntrypointAvailable } from '@hooks/use-user-plus-entrypoint_available';
import { useUserPlusSubscription } from '@hooks/use-user-plus-subscription';
import { BottomNav } from '@pedidosya/web-bottom-nav';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import { CardContainer } from '@pedidosya/web-fenix/molecules';
import fenixTheme from '@pedidosya/web-fenix/theme';
import { useAuth } from '@providers/AuthProvider';
import { useChangeAvatarBottomSheet } from '@providers/ChangeAvatarBottomSheetProvider';
import { useDevice } from '@providers/DeviceProvider';
import { useIntl } from '@providers/IntlProvider';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';
import { Group, Item, PLUS_ITEM } from '@services/my-account';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import messages from './messages';
import { HomeSkeleton } from './skeleton';

type IntlMessage = keyof typeof messages;

const UserInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 0;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

const PlusBackgroundSection = styled.div<{ src: string }>`
  background-color: ${({ theme }) => theme.color('shape-color-surface-secondary')};
  position: absolute;
  z-index: 0;
  width: ${({ theme }) => `calc(100% - ${theme.space('spacing-18')})`};
  height: 100%;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
      background-position: left center;
      background-size: cover;
    `}
`;

const PlaceholderAvatar = styled.div`
  background-color: ${({ theme }) => theme.color('shape-color-surface-quaternary')};
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin-left: auto;
  z-index: 0;
`;

const Avatar = styled.img<{ loaded: boolean }>`
  display: none;
  background-color: ${({ theme }) => theme.color('shape-color-surface-quaternary')};
  border: 3px solid ${({ theme }) => theme.color('shape-color-stroke-action-enabled-inverted')};
  border-radius: 50%;
  box-shadow: 0px 4px 8px 0px rgba(16, 4, 35, 0.12);
  box-sizing: border-box;
  width: 72px;
  height: 72px;
  margin-left: auto;
  position: relative;
  ${({ loaded }) =>
    loaded &&
    css`
      display: block;
    `}
`;

const Identity = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme.space('spacing-component-medium')};
`;

const TagPlusImage = styled.img`
  margin-top: ${({ theme }) => theme.space('spacing-component-small')};
`;

const ChangeAvatar = styled.div`
  background-color: ${({ theme }) => theme.color('shape-color-surface-action-activated-disabled')};
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
`;

const FlexBoxCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const BannerContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-large')} ${theme.space('spacing-component-xlarge')}`};
`;

const Banner = styled.div`
  background-color: ${({ theme }) => theme.color('shape-color-surface-secondary')};
  border-radius: 8px;
  padding: ${({ theme }) => theme.space('spacing-component-medium')};
  text-align: center;
`;

const ShortcutsContainer = styled.div<{ items: number }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  margin: ${({ theme, items }) =>
    `${theme.space('spacing-layout-medium')} ${items < 4 ? '24px' : '10px'} ${theme.space(
      'spacing-layout-large',
    )}`};
`;

const ShortcutItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 77px;
  padding: ${({ theme }) => theme.space('spacing-component-small')};
  text-align: center;
`;

const ShortcutItemLabelContainer = styled.div`
  width: 100%;
  height: 23px;
  margin-top: ${({ theme }) => theme.space('spacing-component-medium')};
`;

const DivContainer = styled.div``;

const GroupContainer = styled.div<{ isDesktop?: boolean }>`
  margin: ${({ theme }) =>
    `${theme.space('spacing-0')} ${theme.space('spacing-08')} ${theme.space('spacing-16')}`};

  ${({ isDesktop = false }) =>
    !isDesktop &&
    css`
      background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
      padding-bottom: ${({ theme }) => theme.space('spacing-component-xlarge')};
      margin: ${({ theme }) => theme.space('spacing-layout-large')} 0
        ${({ theme }) => theme.space('spacing-component-medium')};
    `}

  &:last-child {
    margin-bottom: 0;
  }
`;

const ListContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.space('spacing-16')};

  &:last-child {
    margin-bottom: 0;
  }
`;

const CategoryTitleSection = styled.div`
  margin: ${({ theme }) =>
    `${theme.space('spacing-0')} ${theme.space('spacing-08')} ${theme.space('spacing-04')}`};
`;

function PhoneValidationResult(): JSX.Element {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const toast = useToast();

  const [searchParams] = useSearchParams();
  const phoneValidationResult = searchParams.get('pv');

  React.useEffect(() => {
    if (phoneValidationResult === 'success') {
      navigate(RoutePaths.EditPersonalInformation, { replace: true });
      toast.success(formatMessage(messages.phone_validation_success));
    }
  }, [navigate, formatMessage, phoneValidationResult, toast]);

  return null;
}

type UserAvatarProps = {
  user: PublicUser;
  isPlus: boolean;
  onCameraClick: React.MouseEventHandler;
};

function UserAvatar({ user, isPlus, onCameraClick }: UserAvatarProps): JSX.Element {
  const [isAvatarLoaded, setAvatarLoaded] = React.useState(false);

  return (
    <AvatarContainer>
      {isPlus && <PlusBackgroundSection src={plusBackground} />}
      {!isAvatarLoaded && <PlaceholderAvatar />}
      <Avatar
        alt={user.nickname || `${user.name} ${user.lastName}`}
        src={user.avatar || defaultAvatarPNG}
        loaded={isAvatarLoaded}
        onLoad={() => setAvatarLoaded(true)}
      />
      <ChangeAvatar aria-label="CameraButton" role="button" onClick={onCameraClick}>
        <Icon name="camera" />
      </ChangeAvatar>
    </AvatarContainer>
  );
}

type UserInfoProps = {
  user: PublicUser;
  isPlus: boolean;
  isBottomNavHomeEnabled: boolean;
};

function UserInfo({ user, isPlus, isBottomNavHomeEnabled }: UserInfoProps): JSX.Element {
  const tracker = useTracker();
  const { open: openChangeAvatarBottomSheet } = useChangeAvatarBottomSheet();

  const handleCameraPress = (): void => {
    tracker.track(TrackingEvents.MyAccountClicked, {
      userLoggedIn: true,
      clickLocation: TrackingClickLocations.ProfilePicture,
    });

    openChangeAvatarBottomSheet();
  };

  return (
    <UserInfoContainer style={{ marginTop: isBottomNavHomeEnabled ? '16px' : '0' }}>
      <UserAvatar user={user} isPlus={isPlus} onCameraClick={handleCameraPress} />
      <Identity>
        <Username weight="low">{user.nickname || `${user.name} ${user.lastName}`} </Username>
        <Text color="tertiary">{user.email}</Text>
        {isPlus && <TagPlusImage src={tagPlus} alt="TagPlus" />}
      </Identity>
    </UserInfoContainer>
  );
}

function HighlightBanner(): JSX.Element {
  const tracker = useTracker();

  const handleBannerPress = () => {
    tracker.track(TrackingEvents.MyAccountClicked, {
      userLoggedIn: true,
      clickLocation: TrackingBannerHighlightType.SubscribeToPlus,
    });
    tracker.track(TrackingEvents.BannerClicked, {
      screenType: TrackingBannerScreenType.Profile,
      highlightType: TrackingBannerHighlightType.SubscribeToPlus,
    });

    window.location.assign(PLUS_ITEM.link);
  };

  React.useEffect(() => {
    tracker.track(TrackingEvents.BannerLoaded, {
      screenType: TrackingBannerScreenType.Profile,
      highlightType: TrackingBannerHighlightType.SubscribeToPlus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BannerContainer>
      <Banner>
        <Text size="small" color="inverted" onClick={handleBannerPress}>
          <FormattedMessage
            {...messages.banner_subscription_message}
            values={{
              plus: (
                <b>
                  <u>PedidosYa Plus</u>
                </b>
              ),
            }}
          />
        </Text>
      </Banner>
    </BannerContainer>
  );
}

type ItemIconProps = {
  name: string;
  size?: 'small' | 'medium';
};

function Icon({ name, size = 'small' }: ItemIconProps): JSX.Element {
  return <FenixIcon token={name} color="icon-color-primary" size={size} />;
}

type ShortcutsProps = {
  items: Item[];
};

type GroupItemPressProperties = {
  url: RoutePaths | string;
  tracking: {
    name: string;
  };
};

type GroupItemPressHandler = ({ url, tracking }: GroupItemPressProperties) => () => void;

function Shortcuts({ items }: ShortcutsProps): JSX.Element {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const tracker = useTracker();

  const handleItemPress: GroupItemPressHandler =
    ({ url, tracking }: GroupItemPressProperties) =>
    () => {
      tracker.track(TrackingEvents.MyAccountClicked, {
        userLoggedIn: true,
        clickLocation: tracking.name,
      });

      if (isMicrositeRoute(url)) {
        navigate(url);
      } else {
        window.location.assign(url);
      }
    };

  if (items.length === 0) {
    return null;
  }

  return (
    <ShortcutsContainer items={items.length} role="list" aria-label="Shortcuts">
      {items.map((item) => {
        const title =
          item.title in messages ? formatMessage(messages[item.title as IntlMessage]) : '';

        return (
          <ShortcutItemContainer
            key={item.link}
            role="listitem"
            aria-label={title}
            onClick={handleItemPress({ url: item.link, tracking: item.tracking })}
          >
            <CardContainer
              withBorder={false}
              withShadow={true}
              style={{ width: '69px', height: '69px' }}
            >
              <FlexBoxCenter>
                <Icon name={item.icon} size="medium" />
              </FlexBoxCenter>
            </CardContainer>
            <ShortcutItemLabelContainer>
              <FlexBoxCenter>
                <Label color="action-enabled-loud">{title}</Label>
              </FlexBoxCenter>
            </ShortcutItemLabelContainer>
          </ShortcutItemContainer>
        );
      })}
    </ShortcutsContainer>
  );
}

type GroupsProps = {
  groups: Group[];
  footer: JSX.Element;
};

function Groups({ groups, footer }: GroupsProps): JSX.Element {
  const { isDesktop } = useDevice();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const tracker = useTracker();

  const handleItemPress: GroupItemPressHandler =
    ({ url, tracking }: GroupItemPressProperties) =>
    () => {
      tracker.track(TrackingEvents.MyAccountClicked, {
        userLoggedIn: true,
        clickLocation: tracking.name,
      });

      if (isMicrositeRoute(url)) {
        navigate(url);
      } else {
        window.location.assign(url);
      }
    };

  if (groups.length === 0) {
    return null;
  }

  return (
    <DivContainer>
      <GroupContainer isDesktop={isDesktop} role="list" aria-label="Groups">
        {groups.map(
          (group) =>
            group &&
            group.items.length > 0 && (
              <ListContainer key={group.title}>
                <CategoryTitleSection>
                  <CategoryTitle>
                    {formatMessage(messages[group.title as IntlMessage])}
                  </CategoryTitle>
                </CategoryTitleSection>
                <CardContainer withBorder={false} withShadow={false}>
                  {group.items.map((item, index) => {
                    const label =
                      item.title in messages
                        ? formatMessage(messages[item.title as IntlMessage])
                        : '';
                    const isLastItem = index + 1 === group.items.length;

                    return (
                      <ItemSection
                        key={item.link}
                        label={label}
                        icon={<Icon name={item.icon} />}
                        borderBottom={!isLastItem}
                        onClick={handleItemPress({ url: item.link, tracking: item.tracking })}
                        action={<Icon name="chevron-right" />}
                      />
                    );
                  })}
                </CardContainer>
              </ListContainer>
            ),
        )}
        <ListContainer>{footer}</ListContainer>
      </GroupContainer>
    </DivContainer>
  );
}

function Logout(): JSX.Element {
  const { formatMessage } = useIntl();
  const { open: openLogoutModal } = useLogoutModal();
  const { open: openLogoutFromOtherDevicesModal } = useLogoutFromOtherDevicesModal();
  const { isAuthenticationWithDeviceEnabled } = useFlagVariations();
  const tracker = useTracker();

  const handleLogoutPress = (): void => {
    tracker.track(TrackingEvents.MyAccountClicked, {
      userLoggedIn: true,
      clickLocation: TrackingClickLocations.Logout,
    });
    openLogoutModal();
  };

  const handleLogoutFromOtherDevicesPress = (): void => {
    tracker.track(TrackingEvents.MyAccountClicked, {
      userLoggedIn: true,
      clickLocation: TrackingClickLocations.LogoutAll,
    });
    openLogoutFromOtherDevicesModal();
  };

  return (
    <CardContainer withBorder={false} withShadow={false}>
      {isAuthenticationWithDeviceEnabled && (
        <ItemSection
          label={formatMessage(messages.logout_from_other_devices_label)}
          icon={<Icon name="log-out" />}
          borderBottom={true}
          onClick={handleLogoutFromOtherDevicesPress}
        />
      )}
      <ItemSection
        label={formatMessage(messages.logout_label)}
        icon={<Icon name="log-out" />}
        onClick={handleLogoutPress}
      />
    </CardContainer>
  );
}

const getShortcutItems = (group: Group) => group.items.filter((item) => item.isShortcut);
const getNonShortcutItems = (group: Group) => group.items.filter((item) => !item.isShortcut);

const goToHome = (): void => {
  window.location.href = GlobalRoutePaths.Home;
};

type GroupWithFilteredItemsHandler = (group: Group) => Group;

function Home(): JSX.Element {
  const { formatMessage } = useIntl();
  const { isDesktop } = useDevice();
  const { isAuthenticated: isLoggedUser } = useAuth();
  const { data: me, isLoading: isLoadingMe } = useMe();
  const { data: homeConfig, isLoading: isLoadingHomeConfig } = useHomeConfiguration();
  const { data: isPlus, isLoading: isLoadingPlusSubscription } = useUserPlusSubscription();
  const { data: plusEntrypointAvailable, isLoading: isLoadingPlusEntrypointAvailable } =
    useUserPlusEntrypointAvailable();
  const { isBottomNavHomeEnabled, isMyAccountBannerEnabled } = useFlagVariations();
  const tracker = useTracker();

  const isPlusEntrypointAvailable = !!plusEntrypointAvailable?.available;

  React.useEffect(() => {
    if (!isDesktop) {
      document.body.style.backgroundColor = fenixTheme.color('shape-color-background-primary');
    }

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [isDesktop]);

  const getGroupWithFilteredItems: GroupWithFilteredItemsHandler = (group: Group) => {
    const filteredItems = group.items
      .filter((item) => (!isBottomNavHomeEnabled ? !item.isSidemenuItem : true))
      .filter((item) => (isDesktop ? !item.mobileOnly : true))
      .filter((item) => (!isPlusEntrypointAvailable ? item.title !== PLUS_ITEM.title : true));

    return { ...group, items: filteredItems };
  };

  const homeGroups = homeConfig?.groups.filter((group) => group.title in messages) || [];
  const groupsWithFilteredItems = homeGroups.map((group) => getGroupWithFilteredItems(group));

  const shortcuts =
    !isDesktop && isBottomNavHomeEnabled
      ? groupsWithFilteredItems
          .map((group) => getShortcutItems(group))
          .reduce((prev, current) => [...prev, ...current], [])
      : [];

  const groups =
    !isDesktop && isBottomNavHomeEnabled
      ? groupsWithFilteredItems.map((group) => ({ ...group, items: getNonShortcutItems(group) }))
      : groupsWithFilteredItems;

  const BottomNavComponent =
    !isDesktop && isBottomNavHomeEnabled ? (
      <ShellFooter hideBorderTop={true}>
        <BottomNav tracker={tracker} origin={'my_profile'} userInfo={{ isLoggedUser }} />
      </ShellFooter>
    ) : null;

  if (
    isLoadingMe ||
    isLoadingHomeConfig ||
    isLoadingPlusSubscription ||
    isLoadingPlusEntrypointAvailable
  ) {
    return (
      <Layout>
        {isDesktop ? <OverlayLoader /> : <HomeSkeleton />}
        {BottomNavComponent}
      </Layout>
    );
  }

  return (
    <Layout>
      <PhoneValidationResult />
      {(isDesktop || (!isDesktop && !isBottomNavHomeEnabled)) && (
        <PageTitle
          title={isDesktop ? formatMessage(messages.header) : ''}
          showBack={isDesktop ? false : !isBottomNavHomeEnabled}
          onBack={goToHome}
        />
      )}
      <MobileOnlyComponent>
        <UserInfo user={me} isPlus={isPlus} isBottomNavHomeEnabled={isBottomNavHomeEnabled} />
        {!isPlus && isPlusEntrypointAvailable && isMyAccountBannerEnabled && <HighlightBanner />}
        <Shortcuts items={shortcuts} />
      </MobileOnlyComponent>
      <Groups
        groups={groups}
        footer={
          <LogoutProvider>
            <LogoutFromOtherDevicesProvider>
              <Logout />
            </LogoutFromOtherDevicesProvider>
          </LogoutProvider>
        }
      />
      {BottomNavComponent}
    </Layout>
  );
}

export { Home };
