import { VERIFY_PHONE_CODE_ERROR_CODES } from '@commons/services/constants/ErrorCodes';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'phoneValidation.codeValidator.title',
    defaultMessage: 'Ingresa el código',
  },
  subtitle: {
    id: 'phoneValidation.codeValidator.subtitle',
    defaultMessage: 'Enviamos un código al {phoneNumber} para validar tu número de celular.',
  },
  sendCodeQuestion: {
    id: 'phoneValidation.codeValidator.sendCodeQuestion',
    defaultMessage: '¿No lo recibiste?',
  },
  countDownLabel: {
    id: 'phoneValidation.codeValidator.countDownLabel',
    defaultMessage: 'Reenviar código en {countDown}',
  },
  sendCodeAgain: {
    id: 'phoneValidation.codeValidator.sendCodeAgain',
    defaultMessage: 'Reenviar código',
  },
  tooltipContentTitle: {
    id: 'phoneValidation.codeValidator.tooltipContent.title',
    defaultMessage: '¿Necesitas ayuda?',
  },
  tooltipContentSubtitle: {
    id: 'phoneValidation.codeValidator.tooltipContent.subtitle',
    defaultMessage: 'Si no puedes validar tu celular, ingresa a Ayuda en línea > Mi perfil.',
  },
  tooltipContentButton: {
    id: 'phoneValidation.codeValidator.tooltipContent.button',
    defaultMessage: 'Ir a Ayuda en línea',
  },
  sendCodeErrorMessage: {
    id: 'phoneValidation.codeValidator.sendCode.errorMessage',
    defaultMessage: 'Uy... algo pasó. Vuelve a intentar en unos minutos.',
  },
  successMessage: {
    id: 'phoneValidation.codeValidator.successMessage',
    defaultMessage: 'Validaste tu número de celular.',
  },
  errorToastActionLabel: {
    id: 'phoneValidation.codeValidator.toast.errorToastActionLabel',
    defaultMessage: 'Ayuda en línea',
  },
  [VERIFY_PHONE_CODE_ERROR_CODES.ALREADY_VALIDATED_BY_OTHER_USER]: {
    id: 'phoneValidation.codeValidator.errors.alreadyValidatedByOtherUser',
    defaultMessage: 'No pudimos validar tu número de celular.',
  },
  [VERIFY_PHONE_CODE_ERROR_CODES.VALIDATION_FAILED]: {
    id: 'phoneValidation.codeValidator.errors.validationFailed',
    defaultMessage: 'El código es incorrecto o ya venció',
  },
  genericError: {
    id: 'phoneValidation.codeValidator.errors.genericError',
    defaultMessage: 'Lo sentimos, tuvimos un problema. Vuelve a ingresar el código.',
  },
});

export default messages;
