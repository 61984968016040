import { Skeleton } from '@pedidosya/web-fenix/atoms';
import * as React from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space('spacing-08')};
  width: 100%;
  box-sizing: border-box;
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

function PersonalInformationSkeleton(): JSX.Element {
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      {Array.from({ length: 4 })
        .fill('')
        .map((_n, i) => (
          <React.Fragment key={i}>
            <ItemContainer>
              <ItemContent>
                <Skeleton
                  width="shape-size-action-41"
                  height="shape-size-action-4"
                  shape="square"
                />
                <Skeleton
                  width="shape-size-action-17"
                  height="shape-size-action-4"
                  shape="square"
                />
              </ItemContent>
              <Skeleton width="shape-size-action-06" height="shape-size-action-06" shape="square" />
            </ItemContainer>
          </React.Fragment>
        ))}
    </>
  );
}

export { PersonalInformationSkeleton };
