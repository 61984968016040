// Check for Mobile iOS Device
export function isIOSDevice() {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

  const isIOS = /iPhone|iPad|iPod/i.test(
    navigator.userAgent ||
      navigator.vendor ||
      (window.opera && opera.toString() === `[object Opera]`),
  );

  return isIOS;
}

/**
 * Get time from date str
 * expects a str in the "YYYY-MM-DDTHH:mm:ssZ"
 */
export function getTimeFromTimezoneDate(dateStr) {
  if (!dateStr) return '';
  if (dateStr.indexOf('T') < 0) return dateStr;

  const time = dateStr.split('T')[1];

  return time.split(':').slice(0, 2).join(':');
}
