import { useDevice } from '@providers/DeviceProvider';
import * as React from 'react';

type MobileOnlyComponentProperties = {
  children: React.ReactNode;
};

export function MobileOnlyComponent({ children }: MobileOnlyComponentProperties): JSX.Element {
  const { isDesktop } = useDevice();

  return <React.Fragment>{isDesktop ? null : children}</React.Fragment>;
}
