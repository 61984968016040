import { RoutePaths } from '@app/routes';
import { TrackingActions, TrackingEvents } from '@app/tracking';
import { PhotoConfirmation } from '@components/camera/photo-confirmation';
import { PhotoTaker } from '@components/camera/photo-taker';
import { Layout } from '@components/Layout';
import { ViewPortWindowHeightHackFix } from '@components/ViewPortWindowHeightHackFix';
import { useUpdateUserAvatar } from '@hooks/use-update-user-avatar';
import { useIntl } from '@providers/IntlProvider';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const messages = defineMessages({
  saveErrorResponse: {
    id: 'edit_avatar.save.error_response',
    defaultMessage: 'No pudimos cargar tu foto. Reintenta en unos minutos.',
  },
  saveSuccessResponse: {
    id: 'edit_avatar.save.success_response',
    defaultMessage: 'Cambiaste tu foto de perfil.',
  },
});

const Main = styled.main`
  display: flex;
  flex-grow: 1;
`;

const initialPhoto = '';

function EditAvatar(): JSX.Element {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const toast = useToast();
  const [photo, setPhoto] = React.useState(initialPhoto);
  const { mutateAsync: updateUserAvatarAsync, isLoading: isUpdateUserAvatarLoading } =
    useUpdateUserAvatar();
  const tracker = useTracker();

  const handleOnTakePhoto = (dataUri: string): void => {
    setPhoto(dataUri);
  };

  const handleRetakePress = (): void => {
    setPhoto(initialPhoto);
  };

  const handleUsePhotoPress = async (): Promise<void> => {
    try {
      // strip base encoded data type
      const avatar = photo.split(',').pop();
      await updateUserAvatarAsync({ avatar });
      tracker.track(TrackingEvents.MyAccountUpdated, {
        action: TrackingActions.TakePicture,
      });
      toast.success(formatMessage(messages.saveSuccessResponse));
    } catch (error) {
      tracker.track(TrackingEvents.MyAccountUpdateFailed, {
        action: TrackingActions.TakePicture,
        errorMessage: error?.code || error.message,
      });
      toast.error(formatMessage(messages.saveErrorResponse));
    } finally {
      navigate(RoutePaths.Home);
    }
  };

  return (
    <Layout isLoading={isUpdateUserAvatarLoading}>
      <ViewPortWindowHeightHackFix />
      <Main>
        {photo === initialPhoto ? (
          <PhotoTaker onTakePhoto={handleOnTakePhoto} />
        ) : (
          <PhotoConfirmation
            photo={photo}
            onRetakePress={handleRetakePress}
            onUsePhotoPress={handleUsePhotoPress}
          />
        )}
      </Main>
    </Layout>
  );
}

export { EditAvatar };
