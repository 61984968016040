import { CardContainer } from '@pedidosya/web-fenix/molecules';
import { useDevice } from '@providers/DeviceProvider';
import * as React from 'react';
import styled from 'styled-components';

const Card = styled(CardContainer)<{ isDesktop?: boolean }>`
  width: auto;
  padding: ${({ isDesktop }) => (isDesktop ? '16px' : '0')};
  margin: 0 16px;

  > div {
    margin-bottom: 32px;
  }
`;

type FormBodyProps = {
  children: React.ReactNode;
};

const FormBody = ({ children }: FormBodyProps) => {
  const { isDesktop } = useDevice();

  return (
    <Card withBorder={false} withShadow={isDesktop} isDesktop={isDesktop}>
      {children}
    </Card>
  );
};

export { FormBody };
