import { TrackingActions, TrackingEvents } from '@app/tracking';
import Modal from '@components/Modal';
import { useUpdateUserAvatar } from '@hooks/use-update-user-avatar';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';
import { isIOSDevice } from '@utils/utils';
import * as React from 'react';
import Cropper from 'react-cropper';

import { FileProps } from './ChoosePhotoItem';
import CropperModalGlobalStyle from './CropperModal.globalstyle';

type CropperModalProps = {
  isOpen: boolean;
  file: FileProps;
  successMessage: string;
  errorMessage: string;
  accept: () => void;
  close: () => void;
  onLoading: (value: boolean) => void;
};

const CropperModal = ({
  isOpen,
  file,
  successMessage,
  errorMessage,
  accept,
  close,
  onLoading,
}: CropperModalProps) => {
  const isIOS = isIOSDevice();
  const cropperRef = React.useRef(null);
  const tracker = useTracker();
  const toast = useToast();
  const { mutateAsync: updateUserAvatarAsync, isLoading: isUpdateUserAvatarLoading } =
    useUpdateUserAvatar();

  React.useEffect(() => {
    onLoading(isUpdateUserAvatarLoading);
  }, [onLoading, isUpdateUserAvatarLoading]);

  const getCroppedImg = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      return cropperRef.current?.cropper
        .getCroppedCanvas({
          minWidth: 256,
          minHeight: 256,
          maxWidth: 1024,
          maxHeight: 1024,
        })
        .toDataURL(file.type);
    }

    return;
  };

  const saveAvatar = async (img: string) => {
    try {
      const avatar = img.split(',').pop();
      const type = file.type.split('/').pop();
      await updateUserAvatarAsync({ avatar, type });
      tracker.track(TrackingEvents.MyAccountUpdated, {
        action: isIOS ? TrackingActions.ChangePicture : TrackingActions.SelectPicture,
      });
      toast.success(successMessage);
      accept();
    } catch (error) {
      tracker.track(TrackingEvents.MyAccountUpdateFailed, {
        action: isIOS ? TrackingActions.ChangePicture : TrackingActions.SelectPicture,
        errorMessage: error?.code || error.message,
      });
      toast.error(errorMessage);
      close();
    }
  };

  const handleAccept = () => {
    const croppedImg = getCroppedImg();
    if (croppedImg) {
      saveAvatar(croppedImg);
    } else {
      toast.error(errorMessage);
      close();
    }
  };

  return (
    <>
      <CropperModalGlobalStyle />
      <Modal
        open={isOpen}
        onClose={close}
        onAccept={handleAccept}
        closeOnOutsideClick={false}
        acceptLabel="Guardar"
        cancelLabel="Cancelar"
      >
        <Cropper
          ref={cropperRef}
          style={{ minHeight: 100, maxHeight: '60vh', maxWidth: '45vh' }}
          checkOrientation={false}
          guides={true}
          zoomable={false}
          src={file.img}
          viewMode={3}
          initialAspectRatio={1}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          autoCropArea={1}
          aspectRatio={1 / 1}
        />
      </Modal>
    </>
  );
};

export default CropperModal;
