export function isValidUrl(url) {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

/**
 * Checks pased url against valid urls
 * expects a an array of valid ulrs strings
 */
export const validateUrl = (url, validUrls) => {
  try {
    const decodedUri = decodeURIComponent(url);
    const urlObj = new URL(decodedUri);
    const isValidUrl = validUrls.some((validUrl) => urlObj.origin === validUrl);

    return isValidUrl;
  } catch (error) {
    return false;
  }
};
