import { Device } from '@app/tracking';
import * as React from 'react';

export enum DeviceWidthBreakpoint {
  Desktop = 1024,
  Mobile = 768,
}

function calculateDeviceFromScreenSize(): Device {
  const width = window?.innerWidth || 0;

  if (width < DeviceWidthBreakpoint.Mobile) {
    return Device.Mobile;
  }

  if (width >= DeviceWidthBreakpoint.Mobile && width <= DeviceWidthBreakpoint.Desktop) {
    return Device.Tablet;
  }

  return Device.Desktop;
}

type DeviceContextProperties = {
  device: Device;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
};

const DeviceContext = React.createContext<DeviceContextProperties>(
  undefined as DeviceContextProperties,
);
DeviceContext.displayName = 'DeviceContext';

type DeviceProviderProps = {
  children: React.ReactNode;
  device: Device;
};

function DeviceProvider({ children, device: initialDevice }: DeviceProviderProps): JSX.Element {
  const [device, setDevice] = React.useState(initialDevice);

  const handleResize = React.useCallback(() => {
    const maybeNewDevice = calculateDeviceFromScreenSize();
    if (maybeNewDevice !== device) {
      setDevice(maybeNewDevice);
    }
  }, [device]);

  const value = React.useMemo(
    () => ({
      device,
      isDesktop: device === Device.Desktop,
      isMobile: device === Device.Mobile,
      isTablet: device === Device.Tablet,
    }),
    [device],
  );

  React.useEffect(() => {
    if (!device) handleResize();
  }, [device, handleResize]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
}

function useDevice(): DeviceContextProperties {
  const context = React.useContext(DeviceContext);
  if (context === undefined) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }

  return context;
}

export { DeviceProvider, useDevice };
