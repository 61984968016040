import FixedFooter from '@components/ShellFooter/FixedFooter';
import { useDevice } from '@providers/DeviceProvider';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled(FixedFooter)`
  background-color: ${({ theme, isDesktop }) =>
    isDesktop ? 'transparent' : theme.color('shape-color-background-primary')};
  padding: ${({ theme, withPadding }) =>
    withPadding ? `${theme.space('spacing-10')} ${theme.space('spacing-08')}` : '0'};
  width: 100%;
  position: ${({ isDesktop }) => (isDesktop ? 'initial' : 'fixed')};
  bottom: 0;
  z-index: 1;
  box-sizing: border-box;
  border-top-right-radius: ${({ rounded }) => (rounded ? '40px' : 0)};
  border-top-left-radius: ${({ rounded }) => (rounded ? '40px' : 0)};
  box-shadow: ${({ hideBorderTop }) =>
    hideBorderTop ? 'none' : 'rgba(181, 129, 108, 0.5) 0px -16px 34px -28px'};
`;

export function ShellFooter({
  children,
  className = undefined,
  hideBorderTop = false,
  withPadding = false,
  rounded = false,
}) {
  const { isDesktop } = useDevice();
  if (!children) {
    return null;
  }

  const T = typeof children === 'function' ? children() : children;
  if (!T) return null;

  return (
    <Container
      className={className}
      isDesktop={isDesktop}
      hideBorderTop={hideBorderTop}
      withPadding={withPadding}
      rounded={rounded}
    >
      {T}
    </Container>
  );
}

ShellFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  className: PropTypes.string,
  hideBorderTop: PropTypes.bool,
  withPadding: PropTypes.bool,
  rounded: PropTypes.bool,
};
// Do not export as styled component, will break logic.
export default ShellFooter;
