import { usePublicEnv } from '@app/contexts/PublicEnv';
import { TrackingActions, TrackingEvents } from '@app/tracking';
import { PublicEnvironment } from '@app/types';
import { CHANGE_PASSWORD_ERROR_CODES } from '@commons/services/constants/ErrorCodes';
import { HttpApiError } from '@commons/services/models/ServiceResponse';
import Form, { FormBody, FormFooter } from '@components/Form';
import { Layout } from '@components/Layout';
import OverlayLoader from '@components/OverlayLoader';
import PasswordRulesInput, { PasswordRule } from '@components/PasswordRulesInput';
import { PageTitle } from '@components/Shell';
import { ChangePasswordFormValues, useChangePassword } from '@hooks/use-change-password';
import { Button } from '@pedidosya/web-fenix/atoms';
import PasswordInput from '@pedidosya/web-fenix/atoms/PasswordInput';
import fenixTheme from '@pedidosya/web-fenix/theme';
import { useDevice } from '@providers/DeviceProvider';
import { useIntl } from '@providers/IntlProvider';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import { getExternalLoginUrl } from '@utils/external-login';
import axios from 'axios';
import { FormikErrors, useFormik } from 'formik';
import * as React from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { TRACKING_ERR_MSG_INCORRECT_PASSWORD, TRACKING_ERR_MSG_UNKNOWN } from './constants';
import messages from './messages';

const CurrentPasswordContainer = styled.div``;

const useStrongPasswordRules = () =>
  useMutation(async () => await axios.get(USER_ENDPOINTS.PASSWORD_RULES));

function ChangePassword(): JSX.Element {
  const [rules, setRules] = React.useState<PasswordRule[]>([]);
  const { isDesktop } = useDevice();
  const navigate = useNavigate();
  const toast = useToast();
  const tracker = useTracker();
  const { formatMessage } = useIntl();
  const { mutateAsync: rulesMutateAsync, isLoading: rulesLoading } = useStrongPasswordRules();
  const { mutateAsync: changePasswordMutateAsync, isLoading: changePasswordLoading } =
    useChangePassword();

  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== 'default';

  const { environment, country }: PublicEnvironment = usePublicEnv();
  const externalForgotPasswordUrl = getExternalLoginUrl({
    environment,
    countryShortName: country.shortName,
    path: 'forgot-password',
    params: { originPath: 'my-account' },
  });

  const handleGoBackPress = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const isIncorrectPasswordCode = (error: HttpApiError) =>
    error?.code === CHANGE_PASSWORD_ERROR_CODES.USR_INCORRECT_PASSWORD;

  const getTrackingErrorMessage = (error: HttpApiError) => {
    if (isIncorrectPasswordCode(error)) {
      return TRACKING_ERR_MSG_INCORRECT_PASSWORD;
    }

    return error?.code || error.message || TRACKING_ERR_MSG_UNKNOWN;
  };

  const formik = useFormik<ChangePasswordFormValues>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    onSubmit: async (values, formikHelpers) => {
      const { error, success } = await changePasswordMutateAsync(values);
      if (error) {
        tracker.track(TrackingEvents.ChangePasswordFailed, {
          errorMessage: getTrackingErrorMessage(error),
        });
        tracker.track(TrackingEvents.MyAccountUpdateFailed, {
          action: TrackingActions.ChangePassword,
          errorMessage: getTrackingErrorMessage(error),
        });

        if (isIncorrectPasswordCode(error)) {
          formikHelpers.setErrors({ oldPassword: formatMessage(messages.oldPasswordErrorMessage) });
        } else {
          toast.error(formatMessage(messages.saveErrorResponse));
        }
      }

      if (success) {
        tracker.track(TrackingEvents.ChangePasswordCompleted);
        tracker.track(TrackingEvents.MyAccountUpdated, {
          action: TrackingActions.ChangePassword,
        });
        toast.success(formatMessage(messages.saveSuccessResponse));
        if (doesAnyHistoryEntryExist) {
          handleGoBackPress();
        }
      }
    },
    validate: (values) => {
      const errors: FormikErrors<ChangePasswordFormValues> = {};
      if (values.oldPassword.trim().length === 0) {
        errors.oldPassword = '';
      }

      const validateRules = rules.map((rule) => {
        const rgx = new RegExp(rule.rule);

        return {
          ...rule,
          checked: rgx.test(values.newPassword),
        };
      });

      const hasUncheckedRule = validateRules.some((rule) => rule.checked === false);
      if (hasUncheckedRule) {
        errors.newPassword = '';
      }

      setRules(validateRules);

      return errors;
    },
  });

  const isButtonDisabled = !formik.dirty || !formik.isValid || formik.isSubmitting;

  React.useEffect(() => {
    if (!isDesktop) {
      document.body.style.backgroundColor = fenixTheme.color('shape-color-background-primary');
    }

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [isDesktop]);

  React.useEffect(() => {
    async function getStrongPasswordRules() {
      try {
        const { data } = await rulesMutateAsync();
        setRules(data.map((rule: any) => ({ ...rule, checked: false })));
        tracker.track(TrackingEvents.ChangePasswordLoaded);
      } catch {
        toast.error(formatMessage(messages.passwordRulesErrorResponse));
        handleGoBackPress();
      }
    }

    getStrongPasswordRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (rulesLoading) {
    return <OverlayLoader />;
  }

  return (
    <Layout isLoading={changePasswordLoading}>
      <PageTitle title={formatMessage(messages.title)} onBack={handleGoBackPress} />
      <Form onSubmit={formik.handleSubmit}>
        <FormBody>
          <CurrentPasswordContainer>
            <PasswordInput
              data-testid="current-password-input"
              label={formatMessage(messages.oldPasswordLabel)}
              isError={!!formik.errors.oldPassword}
              errorMessage={formik.errors.oldPassword || ''}
              value={formik.values.oldPassword}
              onChange={formik.handleChange('oldPassword')}
            />
            <Button
              label={formatMessage(messages.forgotPassword)}
              hierarchy="tertiary"
              size="medium"
              href={externalForgotPasswordUrl}
            />
          </CurrentPasswordContainer>
          <PasswordRulesInput
            data-testid="new-password-input"
            label={formatMessage(messages.newPasswordLabel)}
            value={formik.values.newPassword}
            onChange={formik.handleChange('newPassword')}
            rules={rules}
            successMessage={formatMessage(messages.newPasswordSuccessMessage)}
          />
        </FormBody>
        <FormFooter>
          <Button
            label={formatMessage(messages.accept)}
            size="large"
            type="submit"
            state={isButtonDisabled ? 'disabled' : 'enabled'}
            disabled={isButtonDisabled}
            fullWidth
          />
        </FormFooter>
      </Form>
    </Layout>
  );
}

export { ChangePassword };
