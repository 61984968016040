import ServiceResponse from '@commons/services/models/ServiceResponse';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import axios from 'axios';
import { useMutation } from 'react-query';

export type ChangePasswordFormValues = {
  oldPassword: string;
  newPassword: string;
};

export function useChangePassword() {
  return useMutation(async (payload: ChangePasswordFormValues) => {
    try {
      await axios.put(USER_ENDPOINTS.CHANGE_PASSWORD, payload);

      return new ServiceResponse({ success: true });
    } catch (error) {
      return new ServiceResponse({ error });
    }
  });
}
