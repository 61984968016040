import { isServer } from '@commons/utils';
import { getWindowObject } from '@utils/documentWindow';
import * as React from 'react';

type MaybeWindow = Window | null;

const WindowContext = React.createContext<MaybeWindow>(undefined as MaybeWindow);

type WindowProviderProperties = {
  children: React.ReactNode;
};

export function WindowProvider({ children }: WindowProviderProperties): JSX.Element {
  const [value, setWindow] = React.useState(getWindowObject);
  const fromServer = isServer();

  React.useEffect(() => {
    if (!fromServer) {
      setWindow(getWindowObject);
    }
  }, [fromServer]);

  return <WindowContext.Provider value={value}>{children}</WindowContext.Provider>;
}

export function useWindow(): MaybeWindow {
  const context = React.useContext(WindowContext);
  if (context === undefined) {
    throw new Error('useWindow() must be used within a WindowProvider');
  }

  return context;
}
