import { Input } from '@pedidosya/web-fenix/atoms';
import * as React from 'react';

const SEPARATOR = '/';
const MAX_LENGTH = 10;
const BACKSPACE_KEY = 'Backspace';
const DELETE_KEY = 'Delete';

let oldValue = '';
let lastValidValue = '';
let pressedBackspaceOrDelete = false;
let currentCursorPosition = 0;

const isValidDateFormat = (value: string) => {
  const dayRegExp = [/^[0-3]/, /^(0[1-9]|1\d|2\d|3[01])/];
  const monthRegExp = [/^[01]/, /^(0[1-9]|1[0-2])/];
  const yearRegExp = [
    /^[1-9]/,
    /^(1[6-9]|[2-9]\d)/,
    /^(1[6-9]|[2-9]\d)\d/,
    /^(1[6-9]|[2-9]\d)\d\d$/,
  ];

  const splitValue = value.split(SEPARATOR);
  const dayValue = splitValue[0];
  const monthValue = splitValue[1];
  const yearValue = splitValue[2];

  if (
    (dayValue && dayValue.length > dayRegExp.length) ||
    (monthValue && monthValue.length > monthRegExp.length) ||
    (yearValue && yearValue.length > yearRegExp.length)
  )
    return false;

  const isValidDayFormat = !dayValue || (dayValue && dayRegExp[dayValue.length - 1].test(dayValue));
  const isValidMonthFormat =
    !monthValue || (monthValue && monthRegExp[monthValue.length - 1].test(monthValue));
  const isValidYearFormat =
    !yearValue || (yearValue && yearRegExp[yearValue.length - 1].test(yearValue));

  if (isValidDayFormat && isValidMonthFormat && isValidYearFormat) return true;

  return false;
};

type InputDateMaskProps = {
  label: string;
  autoFocus?: boolean;
  errorMessage?: string;
  value: string;
  disabled: boolean;
  onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (event: string | React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
};

function InputDateMask({
  label,
  autoFocus,
  errorMessage,
  value,
  disabled,
  onFocus,
  onChange,
}: InputDateMaskProps) {
  const [currentValue, setCurrentValue] = React.useState(value);

  React.useEffect(() => {
    lastValidValue = value;
    setCurrentValue(value);
  }, [value]);

  const handleBlur = (event: React.FocusEvent) => {
    const input = event.target as HTMLInputElement;
    input.value = lastValidValue;
    setCurrentValue(input.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const input = event.target as HTMLInputElement;

    const keysAllowed = [BACKSPACE_KEY, DELETE_KEY, 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    const keyValue = event.key;

    if (!/^[0-9]$/.test(keyValue) && !keysAllowed.includes(keyValue)) {
      event.preventDefault();
    }

    oldValue = input.value;
    pressedBackspaceOrDelete = event.key === BACKSPACE_KEY || event.key === DELETE_KEY;
    currentCursorPosition = input.selectionEnd;

    if (pressedBackspaceOrDelete) {
      const currentCursorPositionValue = input.value.split('')[currentCursorPosition - 1];
      const nextToCurrentCursorPositionValue = input.value.split('')[currentCursorPosition];
      if (
        currentCursorPositionValue === SEPARATOR &&
        nextToCurrentCursorPositionValue !== undefined
      ) {
        event.preventDefault();
      }
    }

    if (!pressedBackspaceOrDelete) {
      const isLessThanTwoSeparators =
        input.value.split('').filter((str) => str === SEPARATOR).length < 2;
      if (isLessThanTwoSeparators && (input.value.length == 2 || input.value.length == 5)) {
        input.value += SEPARATOR;
      }
    }
  };

  const handleInput = (event: React.FormEvent) => {
    const input = event.target as HTMLInputElement;

    if (!pressedBackspaceOrDelete && !isValidDateFormat(input.value)) {
      input.value = input.value ? oldValue : '';
      input.selectionStart = input.selectionEnd = currentCursorPosition;
    }
  };

  const handleChange = (event: React.ChangeEvent) => {
    const input = event.target as HTMLInputElement;
    setCurrentValue(input.value);
    if (input.value.length === 0) {
      lastValidValue = '';
      onChange('');
    }
    if (input.value.length === MAX_LENGTH) {
      lastValidValue = input.value;
      onChange(input.value);
    }
  };

  return (
    <Input
      data-testid="input-date"
      autoFocus={autoFocus}
      inputMode="decimal"
      aria-label={label}
      label={label}
      isError={!!errorMessage}
      errorMessage={errorMessage}
      value={currentValue}
      maxLength={10}
      onFocus={onFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}

export default InputDateMask;
