import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'home.header',
    defaultMessage: 'Mi cuenta',
  },
  banner_subscription_message: {
    id: 'home.banner_subscription_message',
    defaultMessage: 'Suscríbete a {plus} y ahorra en tus pedidos.',
  },
  profile_title: {
    id: 'home.profile_title',
    defaultMessage: 'Perfil',
  },
  personal_info_title: {
    id: 'home.personal_info_title',
    defaultMessage: 'Información personal',
  },
  personal_info_subtitle: {
    id: 'home.personal_info_subtitle',
    defaultMessage: 'Datos personales y de la cuenta',
  },
  addresses_title: {
    id: 'home.addresses_title',
    defaultMessage: 'Direcciones',
  },
  addresses_subtitle: {
    id: 'home.addresses_subtitle',
    defaultMessage: 'Direcciones de entrega',
  },
  benefits_title: {
    id: 'home.benefits_title',
    defaultMessage: 'Beneficios',
  },
  plus_subscription_title: {
    id: 'home.plus_subscription_title',
    defaultMessage: 'PedidosYa Plus',
  },
  benefits_program_title: {
    id: 'home.benefits_program_title',
    defaultMessage: 'Programas de beneficios',
  },
  my_activity_title: {
    id: 'home.my_activity_title',
    defaultMessage: 'Mi actividad',
  },
  favorites_title: {
    id: 'home.favorites_title',
    defaultMessage: 'Favoritos',
  },
  support_title: {
    id: 'home.support_title',
    defaultMessage: 'Soporte',
  },
  legal_title: {
    id: 'home.legal_title',
    defaultMessage: 'Información legal',
  },
  help_title: {
    id: 'home.help_title',
    defaultMessage: 'Ayuda en línea',
  },
  terms_title: {
    id: 'home.terms_title',
    defaultMessage: 'Términos y condiciones',
  },
  policies_title: {
    id: 'home.policies_title',
    defaultMessage: 'Políticas de privacidad',
  },
  logout_from_other_devices_label: {
    id: 'home.logout_from_other_devices.label',
    defaultMessage: 'Cerrar sesión en otros dispositivos',
  },
  logout_from_other_devices_caption: {
    id: 'home.logout_from_other_devices.caption',
    defaultMessage: 'Se cerrarán tus sesiones abiertas en otros dispositivos.',
  },
  logout_label: {
    id: 'home.logout.label',
    defaultMessage: 'Cerrar sesión',
  },
  phone_validation_success: {
    id: 'home.phone_validation_success',
    defaultMessage: 'Validaste tu número de celular.',
  },
});

export default messages;
