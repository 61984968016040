import { TrackingEvents } from '@app/tracking';
import { Button } from '@pedidosya/web-fenix/atoms';
import { Modal } from '@pedidosya/web-fenix/organisms';
import { useIntl } from '@providers/IntlProvider';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import axios from 'axios';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { useMutation } from 'react-query';

const useLogoutFromOtherDevices = () =>
  useMutation(async () => {
    await axios.post(USER_ENDPOINTS.LOGOUT_FROM_OTHER_DEVICES);
  });

const messages = defineMessages({
  accept: {
    id: 'logoutFromOtherDevicesModal.action.accept',
    defaultMessage: 'Cerrar otras sesiones',
  },
  cancel: {
    id: 'logoutFromOtherDevicesModal.action.cancel',
    defaultMessage: 'Cancelar',
  },
  subtitle: {
    id: 'logoutFromOtherDevicesModal.subtitle',
    defaultMessage: '¿Quieres cerrar tu sesión en otros dispositivos?',
  },
  title: {
    id: 'logoutFromOtherDevicesModal.title',
    defaultMessage: 'Cerrar otras sesiones',
  },
  serviceError: {
    id: 'logoutFromOtherDevicesModal.service.error',
    defaultMessage: 'Uy... algo pasó. Intenta nuevamente más tarde.',
  },
  serviceSuccess: {
    id: 'logoutFromOtherDevicesModal.service.success',
    defaultMessage: 'Ya cerramos tu sesión en los otros dispositivos.',
  },
});

export enum TrackingActions {
  Cancel = 'cancel',
  Logout = 'logout',
}

export enum TrackingClickLocation {
  Button = 'button',
  Outside = 'outside',
}

export enum TrackingTypes {
  LogoutFromOtherDevices = 'logout_from_other_devices',
}

type LogoutFromOtherDevicesModalProps = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

export function LogoutFromOtherDevicesModal({
  isOpen,
  close,
}: LogoutFromOtherDevicesModalProps): JSX.Element {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const { mutateAsync } = useLogoutFromOtherDevices();
  const tracker = useTracker();

  const handleAcceptPress = async (): Promise<void> => {
    tracker.track(TrackingEvents.ModalClosed, {
      type: TrackingTypes.LogoutFromOtherDevices,
      action: TrackingActions.Logout,
      clickLocation: TrackingClickLocation.Button,
    });

    try {
      close();
      await mutateAsync();
      toast.success(formatMessage(messages.serviceSuccess));
    } catch {
      toast.error(formatMessage(messages.serviceError));
    }
  };

  const handleCancelPress = (): void => {
    tracker.track(TrackingEvents.ModalClosed, {
      type: TrackingTypes.LogoutFromOtherDevices,
      action: TrackingActions.Cancel,
      clickLocation: TrackingClickLocation.Button,
    });
    close();
  };

  const handleClose = (): void => {
    tracker.track(TrackingEvents.ModalClosed, {
      type: TrackingTypes.LogoutFromOtherDevices,
      action: TrackingActions.Cancel,
      clickLocation: TrackingClickLocation.Outside,
    });
    close();
  };

  React.useEffect(() => {
    if (isOpen) {
      tracker.track(TrackingEvents.ModalLoaded, {
        modalType: TrackingTypes.LogoutFromOtherDevices,
      });
    }
  }, [isOpen, tracker]);

  return (
    <Modal
      data-testid="modal"
      open={isOpen}
      onClose={handleClose}
      title={formatMessage(messages.title)}
      text={formatMessage(messages.subtitle)}
      primaryButton={
        <Button
          fullWidth
          label={formatMessage(messages.accept)}
          size="large"
          onClick={handleAcceptPress}
        />
      }
      secondaryButton={
        <Button
          fullWidth
          hierarchy="secondary"
          label={formatMessage(messages.cancel)}
          size="large"
          onClick={handleCancelPress}
        />
      }
    />
  );
}

type LogoutFromOtherDevicesContextProps = {
  open: () => void;
};

const LogoutFromOtherDevicesContext = React.createContext<LogoutFromOtherDevicesContextProps>(
  {} as LogoutFromOtherDevicesContextProps,
);
LogoutFromOtherDevicesContext.displayName = 'LogoutFromOtherDevicesContext';

type LogoutFromOtherDevicesProviderProps = {
  children?: React.ReactElement;
};

export function LogoutFromOtherDevicesProvider({
  children,
}: LogoutFromOtherDevicesProviderProps): JSX.Element {
  const [isOpen, setOpen] = React.useState(false);

  function open(): void {
    setOpen(true);
  }

  function close(): void {
    setOpen(false);
  }

  return (
    <LogoutFromOtherDevicesContext.Provider value={{ open }}>
      {children}
      <LogoutFromOtherDevicesModal isOpen={isOpen} open={open} close={close} />
    </LogoutFromOtherDevicesContext.Provider>
  );
}

export function useLogoutFromOtherDevicesModal(): LogoutFromOtherDevicesContextProps {
  const context = React.useContext(LogoutFromOtherDevicesContext);
  if (context === undefined) {
    throw new Error(
      'useLogoutFromOtherDevicesModal must be used within a LogoutFromOtherDevicesProvider',
    );
  }

  return context;
}
