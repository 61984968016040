import * as React from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.space('spacing-04')};
`;

const StyledOption = styled.li<{ state: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: ${({ theme }) => theme.size('shape-size-action-12')};
  ${({ theme }) => theme.font('font-body-midcontrast-sentence-large')};
  color: ${({ theme, state }) =>
    theme.color(
      state === 'error' ? 'text-color-feedback-error' : 'text-color-action-enabled-quiet',
    )};
  background-color: ${({ theme }) => theme.color('shape-color-surface-action-default-enabled')};
  padding: ${({ theme }) => `${theme.space('spacing-0')} ${theme.space('spacing-08')}`};
`;

type DropDownOptionProps = {
  label: string;
  icon?: JSX.Element;
  state?: string;
  onClick?: () => void;
};

const DropDownOption = ({ label, icon, state, onClick }: DropDownOptionProps): JSX.Element => (
  <StyledOption role="listitem" aria-label={label} state={state} onClick={onClick}>
    {icon && <IconContainer>{icon}</IconContainer>}
    {label}
  </StyledOption>
);

export default DropDownOption;
