export const SEND_PHONE_CODE_ERROR_CODES = {
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  QUOTA_EXCEEDED: 'QUOTA_EXCEEDED',
};

export const VERIFY_PHONE_CODE_ERROR_CODES = {
  ALREADY_VALIDATED_BY_OTHER_USER: 'ALREADY_VALIDATED_BY_OTHER_USER',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
};

export const UPDATE_PERSONAL_DATA_ERROR_CODES = {
  INVALID_PARAMS: 'INVALID_PARAMS',
};

export const CHANGE_PASSWORD_ERROR_CODES = {
  USR_INCORRECT_PASSWORD: 'USR_INCORRECT_PASSWORD',
};
