import type { UserValidations } from '@app/types';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import * as queryKeys from '@utils/query-key-factory';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export function useUserValidations(): UseQueryResult<UserValidations, any> {
  return useQuery(
    queryKeys.userValidations(),
    async function getUserPersonalData(): Promise<unknown> {
      const response = await axios.get<unknown>(USER_ENDPOINTS.USER_VALIDATIONS);

      return response.data;
    },
    {
      retry: 0,
    },
  );
}
