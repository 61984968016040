import ServiceResponse from '@commons/services/models/ServiceResponse';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import axios from 'axios';
import { useMutation } from 'react-query';

const USER = 'user';

export function useLogout() {
  return useMutation(async () => {
    try {
      await axios.post(USER_ENDPOINTS.LOGOUT);
      sessionStorage.removeItem(USER);

      return new ServiceResponse({ success: true });
    } catch (error) {
      return new ServiceResponse({ error });
    }
  });
}
