import { FunWithFlagsApi, FWFContext } from '@providers/FunWithFlagsProvider';
import { PublicEnvironment } from '@app/types';

export async function initializeFWF(
  fwf: FunWithFlagsApi,
  { sessionId, user, country }: PublicEnvironment,
) {
  const context: FWFContext = {
    ...(Object.keys(user).length > 0 && Object.keys(country).length > 0
      ? {
          country: country.shortName,
          globalEntityId: `PY_${country.shortName.toUpperCase()}`,
        }
      : {}),
    googleClientId: sessionId,
    platform: 'mweb',
    ...(Object.keys(user).length > 0
      ? {
          email: user.email,
          userId: user.id.toString(),
        }
      : {}),
  };
  fwf.setContext(context);
}
