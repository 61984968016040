import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import pkg from '../package.json';
import Root from './root.component';
import { ErrorBoundary } from './ErrorBoundary';

const rootId = `single-spa-application:${pkg.name}`;
let domElement = document.getElementById(rootId);

if (!document.body.contains(domElement)) {
  domElement = document.createElement('section');
  domElement.id = rootId;
  document.body.appendChild(domElement);
}

const lifecycles = singleSpaReact({
  domElementGetter: () => domElement,
  React,
  ReactDOM,
  renderType: () => (domElement.hasChildNodes() ? 'hydrate' : 'render'),
  rootComponent: Root,
  errorBoundary: (error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, unmount } = lifecycles;
export const mount = [
  async (props) => {
    const fwf = await props?.fwfPromise?.catch(() => undefined);
    const tracker = await props?.trackerPromise?.catch(() => undefined);
    lifecycles.mount({ ...props, tracker, fwf });
  },
  async () => domElement.setAttribute('data-hydrated', 'true'),
];
