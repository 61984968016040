import { useCallback, useEffect, useState } from 'react';

export const TIMER_TYPE = {
  DECREMENTAL: 'DECREMENTAL',
  INCREMENTAL: 'INCREMENTAL',
};

const noop = () => {};

const useActionableTimer = ({
  initialTime = 0,
  interval = 1000,
  step = 1,
  timerType = TIMER_TYPE.INCREMENTAL,
  endTime,
  onTimeOver = noop,
}) => {
  const [time, setTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const [isTimeOver, setIsTimeOver] = useState(false);

  const reset = useCallback(() => {
    setIsRunning(false);
    setIsTimeOver(false);
    setTime(initialTime);
  }, [initialTime]);

  const start = useCallback(() => {
    if (isTimeOver) {
      reset();
    }

    setIsRunning(true);
  }, [reset, isTimeOver]);

  const pause = useCallback(() => {
    setIsRunning(false);
  }, []);

  useEffect(() => {
    if (isRunning && time === endTime) {
      setIsRunning(false);
      setIsTimeOver(true);

      onTimeOver();
    }
  }, [endTime, onTimeOver, time, isRunning]);

  useEffect(() => {
    let intervalId = null;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((previousTime) =>
          timerType === TIMER_TYPE.DECREMENTAL ? previousTime - step : previousTime + step,
        );
      }, interval);
    } else if (intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRunning, step, timerType, interval]);

  return { isRunning, pause, reset, start, time };
};

export default useActionableTimer;
