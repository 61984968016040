import Vendor, { FavoriteItemsResult, VendorListState } from '@app/pages/Favorites/model';
import * as queryKeys from '@app/utils/query-key-factory';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import axios from 'axios';
import { useQuery } from 'react-query';

function getVendorsByState(result: FavoriteItemsResult, state: string) {
  const items = result.items.find((item) => item.state === state);
  return items?.vendors ?? [];
}

export function useUserFavoriteVendors() {
  return useQuery(queryKeys.userFavoriteVendors(), async function getUserFavoriteVendors() {
    try {
      const { data } = await axios.get<FavoriteItemsResult>(USER_ENDPOINTS.FAVORITE_VENDORS, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      const vendorsAvailable = getVendorsByState(data, VendorListState.AVAILABLE)
        .filter((v) => !!v.id && !!v.name && !!v.url)
        .map((v) => new Vendor(v, true));
      const vendorsUnavailable = getVendorsByState(data, VendorListState.UNAVAILABLE)
        .filter((v) => !!v.id && !!v.name && !!v.url)
        .map((v) => new Vendor(v, false));

      return [...vendorsAvailable, ...vendorsUnavailable];
    } catch (error) {
      return null;
    }
  });
}
