import axiosError from '@commons/utils/axios/axiosError';
import { AxiosError } from 'axios';

type ApiError = {
  readonly code?: string;
  readonly messages?: string[];
};

export type HttpApiError = ApiError & {
  readonly message: string;
  readonly networkStatus: number;
  readonly networkUrl: string;
  readonly body: any;
  readonly data?: string;
};

type ServiceResponseProps<T> = {
  success?: boolean;
  data?: T;
  error?: AxiosError<string | ApiError>;
};

class ServiceResponse<T = any> {
  success: boolean;
  data: T;
  error: HttpApiError;

  constructor({ success, data, error }: ServiceResponseProps<T>) {
    this.success = success === undefined ? !error : !!success;
    this.data = data;
    this.error = axiosError(error);
  }
}

export default ServiceResponse;
