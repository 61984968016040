import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, createContext } from 'react';
import axios from 'axios';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import fenixTheme from '@pedidosya/web-fenix/theme';
import BoxMessage from '@pedidosya/web-fenix/molecules/BoxMessage';
import OverlayLoader from '@components/OverlayLoader';

import pkg from '../../../package.json';

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const { data } = await axios.get(`/api/${pkg.name}/env`, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });

  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv) return;
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, [getPublicEnv, publicEnv]);

  if (error)
    return (
      <BoxMessage
        variant="quiet"
        type="error"
        title="Error"
        message="Tuvimos un problema"
        onClose={() => {}}
      />
    );

  if (!publicEnv)
    return (
      <ThemeProvider theme={fenixTheme} config={{ debugger: context?.environment === 'local' }}>
        <OverlayLoader />
      </ThemeProvider>
    );

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
