import { Text } from '@components/Fenix/Typography';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space('spacing-08')};
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
`;

const SecondRow = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space('spacing-02')};
`;

const Content = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.space('spacing-06')};
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BorderBottom = styled.div`
  width: calc(100% - ${({ theme }) => theme.space('spacing-16')});
  background-color: ${({ theme }) => theme.color('shape-color-surface-quaternary')};
  margin: 0 auto;
  height: 1px;
`;

type ItemSectionProps = {
  label: string;
  caption?: string | JSX.Element;
  icon?: JSX.Element;
  action?: JSX.Element;
  borderBottom?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  onMouseOver?: React.MouseEventHandler;
};

const ItemSection = ({
  label,
  caption,
  icon,
  action,
  borderBottom = false,
  disabled = false,
  onClick,
  onMouseOver,
}: ItemSectionProps) => (
  <React.Fragment>
    <Container role="listitem" onClick={onClick} onMouseOver={onMouseOver}>
      <FirstRow>
        <Content>
          {icon && <IconContainer>{icon}</IconContainer>}
          <Text color={disabled ? 'action-disabled-default' : 'primary'}>{label}</Text>
        </Content>
        {action && <ActionContainer>{action}</ActionContainer>}
      </FirstRow>
      {caption && (
        <SecondRow>
          <Text size="small" color={disabled ? 'action-disabled-default' : 'tertiary'}>
            {caption}
          </Text>
        </SecondRow>
      )}
    </Container>
    {borderBottom && <BorderBottom />}
  </React.Fragment>
);

export { ItemSection };
