import ShellFooter from '@components/ShellFooter';
import { useDevice } from '@providers/DeviceProvider';
import * as React from 'react';

type FormFooterProps = {
  children: React.ReactNode;
  hideBorderTop?: boolean;
};

const FormFooter = ({ children, hideBorderTop }: FormFooterProps) => {
  const { isDesktop } = useDevice();

  return (
    <ShellFooter withPadding={true} hideBorderTop={hideBorderTop || isDesktop}>
      {children}
    </ShellFooter>
  );
};

export { FormFooter };
