import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { Flags } from '@fwf/flags';
import { Router } from '@app/Router';
import fenixTheme from '@pedidosya/web-fenix/theme';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import GlobalStyles from '@pedidosya/web-fenix/theme/GlobalStyles';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import initSentry from '@app/sentry';
import { AuthProvider as AuthProviderBase } from '@providers/AuthProvider';
import { DeviceProvider as DeviceProviderBase } from '@providers/DeviceProvider';
import { FunWithFlagsProvider } from '@providers/FunWithFlagsProvider';
import { IntlProvider as IntlProviderBase } from '@providers/IntlProvider';
import { ToastProvider } from '@providers/ToastProvider';
import { TrackerProvider } from '@providers/TrackerProvider';
import { WindowProvider } from '@providers/WindowProvider';

import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from './contexts/PublicEnv';
import RootGlobalStyle from './root.globalstyle';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const flags = [
  Flags.MyAccountAuthenticateWithDevice,
  Flags.PhoneValidation,
  Flags.BottomNavHomeExperiment,
  Flags.MyAccountBannerEnabled,
];

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  const { children, ...rest } = props;

  return (
    <IntlProviderBase locale={locale} {...rest}>
      {children}
    </IntlProviderBase>
  );
};

const SentryProvider = ({ children }) => {
  const { userId, environment, country } = usePublicEnv();

  const sentryConfig = {
    dsn: SENTRY_DSN,
    isEnabled: environment !== 'local',
    environment,
  };

  useEffect(() => {
    const initializeSentry = async () => {
      try {
        await initSentry(sentryConfig);
      } catch (error) {
        console.error('Error initializing Sentry hub:', error.message);
      }
    };

    initializeSentry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country.shortName, environment, userId]);

  return children;
};

const AppRouter = ({ url, children }) => {
  if (typeof window === 'undefined') {
    return (
      <StaticRouter location={url} context={{}}>
        {children}
      </StaticRouter>
    );
  }

  return <BrowserRouter>{children}</BrowserRouter>;
};

const DeviceProvider = ({ children }) => {
  const { device: deviceEnv } = usePublicEnv();
  const device = deviceEnv ? deviceEnv.toLowerCase() : '';

  return <DeviceProviderBase device={device}>{children}</DeviceProviderBase>;
};

const AuthProvider = ({ children }) => {
  const { user } = usePublicEnv();

  return <AuthProviderBase user={user}>{children}</AuthProviderBase>;
};

const Root = ({ context, messages, name, url, tracker, fwf }) => (
  <TrackerProvider tracker={tracker}>
    <QueryClientProvider client={queryClient}>
      <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
        <SentryProvider>
          <WindowProvider>
            <FunWithFlagsProvider flags={flags} fwf={fwf}>
              <AppRouter url={url}>
                <ThemeProvider
                  theme={fenixTheme}
                  config={{ debugger: context?.environment === 'local' }}
                >
                  <FenixFonts />
                  <GlobalStyles />
                  <RootGlobalStyle />
                  <IntlProvider initialMessages={messages}>
                    <DeviceProvider>
                      <ToastProvider>
                        <AuthProvider>
                          <Router />
                          <ReactQueryDevtools initialIsOpen={false} />
                        </AuthProvider>
                      </ToastProvider>
                    </DeviceProvider>
                  </IntlProvider>
                </ThemeProvider>
              </AppRouter>
            </FunWithFlagsProvider>
          </WindowProvider>
        </SentryProvider>
      </PublicEnvProvider>
    </QueryClientProvider>
  </TrackerProvider>
);

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};
Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  tracker: PropTypes.object,
  fwf: PropTypes.object,
};

export default Root;
