import ChangeAvatarBottomSheet from '@components/ChangeAvatarBottomSheet';
import OverlayLoader from '@components/OverlayLoader';
import * as React from 'react';

type ChangeAvatarBottomSheetContextProps = {
  open: () => void;
};

const ChangeAvatarBottomSheetContext = React.createContext<ChangeAvatarBottomSheetContextProps>(
  {} as ChangeAvatarBottomSheetContextProps,
);
ChangeAvatarBottomSheetContext.displayName = 'ChangeAvatarBottomSheetContext';

type ChangeAvatarBottomSheetProviderProps = {
  children?: React.ReactElement;
};

const ChangeAvatarBottomSheetProvider = ({
  children,
}: ChangeAvatarBottomSheetProviderProps): JSX.Element => {
  const [isOpen, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return (
    <ChangeAvatarBottomSheetContext.Provider value={{ open }}>
      {children}
      {isLoading && <OverlayLoader />}
      <ChangeAvatarBottomSheet isOpen={isOpen} close={close} isAvatarChangeLoading={setIsLoading} />
    </ChangeAvatarBottomSheetContext.Provider>
  );
};

const useChangeAvatarBottomSheet = (): ChangeAvatarBottomSheetContextProps => {
  const context = React.useContext(ChangeAvatarBottomSheetContext);
  if (context === undefined) {
    throw new Error(
      'useChangeAvatarBottomSheet must be used within a ChangeAvatarBottomSheetProvider',
    );
  }

  return context;
};

export { ChangeAvatarBottomSheetProvider, useChangeAvatarBottomSheet };
