import { Typography as BaseTypography } from '@pedidosya/web-fenix/atoms';
import * as React from 'react';
import styled from 'styled-components';

const Typography = styled(BaseTypography)`
  margin: 0;
`;

type TitleProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  weight?: string;
  size?: string;
};

const Title = ({ children, style, weight = 'high', size = 'medium' }: TitleProps) => (
  <Typography
    component="h1"
    token={`font-headline-${weight}contrast-${size}`}
    color="text-color-primary"
    style={style}
  >
    {children}
  </Typography>
);

type SubtitleProps = {
  children: React.ReactNode;
  weight?: string;
};

const Subtitle = ({ children, weight = 'high' }: SubtitleProps) => (
  <Typography
    component="h3"
    token={`font-subtitle-${weight}contrast-sentence-large`}
    color="text-color-primary"
  >
    {children}
  </Typography>
);

type TextProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  size?: string;
  color?: string;
  onClick?: () => void;
};

const Text = ({
  children,
  style,
  size = 'medium',
  color = 'primary',
  onClick = () => {},
}: TextProps) => (
  <Typography
    component="p"
    token={`font-body-midcontrast-sentence-${size}`}
    color={`text-color-${color}`}
    style={style}
    onClick={onClick}
  >
    {children}
  </Typography>
);

type LabelProps = {
  children: React.ReactNode;
  size?: string;
  color?: string;
};

const Label = ({ children, size = 'small', color = 'primary' }: LabelProps) => (
  <Typography
    component="span"
    token={`font-label-midcontrast-${size}`}
    color={`text-color-${color}`}
  >
    {children}
  </Typography>
);

export { Label, Subtitle, Text, Title };
