import ServiceResponse from '@commons/services/models/ServiceResponse';
import { PHONE_VALIDATION_ENDPOINTS } from '@services/api-gateway-urls';
import * as queryKeys from '@utils/query-key-factory';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

type UseVerifyPhoneCodeParams = {
  phoneNumber: string;
  code: string;
  userId?: number;
};

export function useVerifyPhoneCode() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ phoneNumber, code, userId = null }: UseVerifyPhoneCodeParams) => {
      try {
        const { data } = await axios.post(PHONE_VALIDATION_ENDPOINTS.VERIFY_PHONE_CODE, {
          phone_number: phoneNumber,
          code,
          user_id: userId,
        });

        return new ServiceResponse({ data });
      } catch (error) {
        return new ServiceResponse({ error });
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.me());
      },
    },
  );
}
