import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import * as React from 'react';

function useNotScrollable(ref, open) {
  React.useEffect(() => {
    if (!open) {
      clearAllBodyScrollLocks();
    } else if (ref?.current) {
      disableBodyScroll(ref.current);
    }
  }, [open, ref]);
}

export default useNotScrollable;
