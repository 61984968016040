import { GlobalRoutePaths } from '@app/routes';
import { TrackingEvents } from '@app/tracking';
import OverlayLoader from '@components/OverlayLoader';
import { Button } from '@pedidosya/web-fenix/atoms';
import { Modal } from '@pedidosya/web-fenix/organisms';
import { useIntl } from '@providers/IntlProvider';
import { useTracker } from '@providers/TrackerProvider';
import { useAuth } from '@providers/AuthProvider';
import { useLogout } from '@hooks/use-logout';
import * as React from 'react';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  accept: {
    id: 'logoutModal.action.accept',
    defaultMessage: 'Cerrar sesión',
  },
  cancel: {
    id: 'logoutModal.action.cancel',
    defaultMessage: 'Cancelar',
  },
  subtitle: {
    id: 'logoutModal.subtitle',
    defaultMessage: 'Tendrás que volver a ingresar para hacer un pedido.',
  },
  title: {
    id: 'logoutModal.title',
    defaultMessage: '¿Quieres salir de tu cuenta?',
  },
});

export enum TrackingActions {
  Cancel = 'cancel',
  Logout = 'logout',
}

export enum TrackingClickLocation {
  Button = 'button',
  Outside = 'outside',
}

export enum TrackingTypes {
  Logout = 'logout',
}

type LogoutModalProps = {
  isOpen: boolean;
  accept: () => void;
  close: () => void;
};

function LogoutModal({ isOpen, accept, close }: LogoutModalProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { mutateAsync } = useLogout();
  const { user } = useAuth();
  const tracker = useTracker();

  const handleAcceptPress = async (): Promise<void> => {
    try {
      tracker.track(TrackingEvents.ModalClosed, {
        modalType: TrackingTypes.Logout,
        action: TrackingActions.Logout,
        clickLocation: TrackingClickLocation.Button,
      });
      accept();
      const { success } = await mutateAsync();
      if (success) {
        tracker.track(TrackingEvents.LogoutSubmitted, {
          userId: user?.id,
        });
      }
    } finally {
      window.location.href = GlobalRoutePaths.Home;
    }
  };

  const handleCancelPress = (): void => {
    tracker.track(TrackingEvents.ModalClosed, {
      modalType: TrackingTypes.Logout,
      action: TrackingActions.Cancel,
      clickLocation: TrackingClickLocation.Button,
    });
    close();
  };

  const handleClose = (): void => {
    tracker.track(TrackingEvents.ModalClosed, {
      modalType: TrackingTypes.Logout,
      action: TrackingActions.Cancel,
      clickLocation: TrackingClickLocation.Outside,
    });
    close();
  };

  React.useEffect(() => {
    if (isOpen) {
      tracker.track(TrackingEvents.ModalLoaded, {
        modalType: TrackingTypes.Logout,
      });
    }
  }, [isOpen, tracker]);

  return (
    <Modal
      data-testid="modal"
      open={isOpen}
      onClose={handleClose}
      title={formatMessage(messages.title)}
      text={formatMessage(messages.subtitle)}
      primaryButton={
        <Button
          fullWidth
          label={formatMessage(messages.accept)}
          size="large"
          onClick={handleAcceptPress}
        />
      }
      secondaryButton={
        <Button
          fullWidth
          hierarchy="secondary"
          label={formatMessage(messages.cancel)}
          size="large"
          onClick={handleCancelPress}
        />
      }
    />
  );
}

type LogoutContextProps = {
  open: () => void;
};

const LogoutContext = React.createContext<LogoutContextProps>({} as LogoutContextProps);
LogoutContext.displayName = 'LogoutContext';

type LogoutProviderProps = {
  children?: React.ReactElement;
};

export function LogoutProvider({ children }: LogoutProviderProps): JSX.Element {
  const [isOpen, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  function open(): void {
    setOpen(true);
  }

  function close(): void {
    setOpen(false);
  }

  function accept(): void {
    close();
    setIsLoading(true);
  }

  return (
    <LogoutContext.Provider value={{ open }}>
      {children}
      {isLoading && <OverlayLoader />}
      <LogoutModal isOpen={isOpen} close={close} accept={accept} />
    </LogoutContext.Provider>
  );
}

export function useLogoutModal(): LogoutContextProps {
  const context = React.useContext(LogoutContext);
  if (context === undefined) {
    throw new Error('useLogoutModal must be used within a LogoutProvider');
  }

  return context;
}
