import { TrackingActions, TrackingEvents } from '@app/tracking';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';
import { isIOSDevice } from '@utils/utils';
import * as React from 'react';
import styled from 'styled-components';

import DropDownOption from './DropDownOption';

const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpeg'];

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const FileInput = styled.input.attrs({ type: 'file' })`
  position: absolute;
  width: 100%;
  opacity: 0;
  visibility: hidden;
`;

export type FileProps = {
  img: string;
  type: string;
};

type ChoosePhotoItemProps = {
  label: string;
  errorMessage: string;
  onSuccess: (file: FileProps) => void;
  onError: () => void;
  onClick: () => void;
};

function ChoosePhotoItem({
  label,
  errorMessage,
  onSuccess,
  onError,
  onClick,
}: ChoosePhotoItemProps) {
  const isIOS = isIOSDevice();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const tracker = useTracker();
  const toast = useToast();

  const handleFileInputChange = () => {
    // Get a reference to the file
    const files = fileInputRef.current?.files;
    const file = files.length > 0 ? files[0] : null;

    if (file && ACCEPTED_FILE_TYPES.includes(file.type)) {
      // Encode the file using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString();
        onSuccess({ img: base64String, type: file.type });
      };
      reader.readAsDataURL(file);
    } else {
      tracker.track(TrackingEvents.MyAccountUpdateFailed, {
        action: isIOS ? TrackingActions.ChangePicture : TrackingActions.SelectPicture,
        errorMessage,
      });
      toast.error(errorMessage);
      onError();
    }

    fileInputRef.current.value = '';
  };

  const handleClick = () => {
    onClick();
    fileInputRef.current && fileInputRef.current.click();
  };

  return (
    <ItemContainer>
      <FileInput
        ref={fileInputRef}
        data-testid="file-input"
        accept={ACCEPTED_FILE_TYPES.join(',')}
        onChange={handleFileInputChange}
      />
      <DropDownOption
        label={label}
        icon={<FenixIcon token="picture" color="icon-color-tertiary" size="small" />}
        onClick={handleClick}
      />
    </ItemContainer>
  );
}

export default ChoosePhotoItem;
