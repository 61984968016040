import BrandLogo from '@pedidosya/web-fenix/atoms/BrandLogo';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import * as React from 'react';
import styled from 'styled-components';

export const StickyHeader = styled.header`
  display: flex;
  height: 56px;
  align-items: center;
  position: sticky;
  z-index: 900;
  text-align: center;
  top: -1px;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  overflow: hidden;
  transition: box-shadow 200ms ease-in-out;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled(FlexContainer)`
  width: 60%;
`;

const SideContainer = styled(FlexContainer)<{ align?: string }>`
  justify-content: ${({ align }) => align || 'center'};
  width: 20%;
`;

const BackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
`;

type BrandHeaderProps = {
  onBack: () => void;
};

const Header = ({ onBack }: BrandHeaderProps): JSX.Element => (
  <StickyHeader>
    <SideContainer align="left">
      <BackContainer>
        <FenixIcon
          token="chevron-left"
          size="medium"
          color="default"
          style={{ outline: 'none' }}
          onClick={onBack}
        />
        <BrandLogo width={105} />
      </BackContainer>
    </SideContainer>
    <MainContainer />
    <SideContainer />
  </StickyHeader>
);

export { Header };
