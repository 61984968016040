import { Skeleton } from '@pedidosya/web-fenix/atoms';
import * as React from 'react';
import styled from 'styled-components';

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 0;
  margin-top: ${({ theme }) => theme.space('spacing-8')};
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Identity = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme.space('spacing-component-medium')};
`;

const BannerContainer = styled.div`
  display: flex;
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-large')} ${theme.space('spacing-component-xlarge')}`};
`;

const ShortcutsContainer = styled.div`
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  padding-top: ${({ theme }) => theme.space('spacing-layout-medium')};
  padding-bottom: ${({ theme }) => theme.space('spacing-20')};
  margin: 0 ${({ theme }) => theme.space('spacing-05')};
`;

const ShortcutItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const GroupContainer = styled.div`
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  padding-bottom: ${({ theme }) => theme.space('spacing-12')};
  margin-bottom: ${({ theme }) => theme.space('spacing-04')};
  &:last-child {
    margin-bottom: ${({ theme }) => theme.space('spacing-0')};
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space('spacing-08')};
  width: 100%;
  box-sizing: border-box;
  height: ${({ theme }) => theme.size('shape-size-action-15')};
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const BorderBottom = styled.div`
  width: calc(100% - ${({ theme }) => theme.space('spacing-16')});
  background-color: ${({ theme }) => theme.color('shape-color-stroke-primary')};
  margin: 0 auto;
  height: 1px;
`;

function HomeSkeleton(): JSX.Element {
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      <UserInfoContainer>
        <AvatarContainer>
          <Skeleton
            width="shape-size-action-14"
            height="shape-size-action-14"
            shape="circle"
            style={{ marginLeft: 'auto' }}
          />
        </AvatarContainer>
        <Identity>
          <Skeleton
            width="shape-size-action-50"
            height="shape-size-action-4"
            shape="square"
            style={{ marginBottom: '8px' }}
          />
          <Skeleton
            width="shape-size-action-36"
            height="shape-size-action-4"
            shape="square"
            style={{ marginBottom: '4px' }}
          />
          <Skeleton width="shape-size-action-13" height="shape-size-action-4" shape="square" />
        </Identity>
      </UserInfoContainer>
      <BannerContainer>
        <Skeleton height="shape-size-action-08" shape="square" style={{ width: '100%' }} />
      </BannerContainer>
      <ShortcutsContainer>
        {Array.from({ length: 3 })
          .fill('')
          .map((_n, i) => (
            <ShortcutItemContainer key={i}>
              <Skeleton
                width="shape-size-action-16"
                height="shape-size-action-16"
                shape="square"
                style={{ marginBottom: '8px' }}
              />
              <Skeleton width="shape-size-action-16" height="shape-size-action-4" shape="square" />
            </ShortcutItemContainer>
          ))}
      </ShortcutsContainer>
      <GroupContainer>
        <Skeleton
          width="shape-size-action-50"
          height="shape-size-action-07"
          shape="square"
          style={{ marginBottom: '8px', marginLeft: '16px' }}
        />
        {Array.from({ length: 12 })
          .fill('')
          .map((_n, i) => (
            <React.Fragment key={i}>
              <ItemContainer>
                <ItemContent>
                  <Skeleton
                    width="shape-size-action-06"
                    height="shape-size-action-06"
                    shape="circle"
                    style={{ marginRight: '12px' }}
                  />
                  <Skeleton
                    width="shape-size-action-50"
                    height="shape-size-action-4"
                    shape="square"
                  />
                </ItemContent>
                <Skeleton
                  width="shape-size-action-05"
                  height="shape-size-action-05"
                  shape="square"
                />
              </ItemContainer>
              <BorderBottom />
            </React.Fragment>
          ))}
      </GroupContainer>
    </>
  );
}

export { HomeSkeleton };
