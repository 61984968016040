interface HomeConfiguration {
  groups: Group[];
}

export interface Group {
  title?: string;
  order: number;
  items: Item[];
}

export interface Item {
  title: string;
  icon: string;
  order: number;
  link: string;
  tracking: {
    name: string;
  };
  mobileOnly?: boolean;
  isSidemenuItem?: boolean;
  isShortcut?: boolean;
}

export const PLUS_ITEM: Item = {
  title: 'plus_subscription_title',
  icon: 'brand-peya-plus',
  order: 1,
  link: '/loyalty-program?presentation=push&origin=profile',
  tracking: {
    name: 'loyalty',
  },
  mobileOnly: true,
  isShortcut: true,
};

export const SUPPORT_ITEMS: Item[] = [
  {
    title: 'help_title',
    icon: 'help-center',
    order: 0,
    link: '/customer-service',
    tracking: {
      name: 'online_help',
    },
    mobileOnly: true,
    isSidemenuItem: true,
    isShortcut: true,
  },
  {
    title: 'terms_title',
    icon: 'information',
    order: 1,
    link: '/about/tyc_generals',
    tracking: {
      name: 'terms_and_conditions',
    },
  },
  {
    title: 'policies_title',
    icon: 'lock',
    order: 2,
    link: '/about/privacy_policies',
    tracking: {
      name: 'privacy',
    },
  },
];

function sortByOrder<T extends Group | Item>(groupOrItem: T[]): T[] {
  return groupOrItem.sort((left, right) => left.order - right.order);
}

export async function getHomeConfiguration(): Promise<HomeConfiguration> {
  const payload = {
    groups: [
      {
        title: 'profile_title',
        order: 0,
        items: [
          {
            title: 'personal_info_title',
            icon: 'user',
            order: 0,
            link: '/my-account/edit-personal-information',
            tracking: {
              name: 'personal_info',
            },
            isShortcut: true,
          },
          {
            title: 'addresses_title',
            icon: 'location',
            order: 1,
            link: '/addresses',
            tracking: {
              name: 'addresses',
            },
            mobileOnly: true,
            isSidemenuItem: true,
          },
          {
            title: 'favorites_title',
            icon: 'favorite',
            order: 2,
            link: '/my-account/favorites',
            tracking: {
              name: 'favorites',
            },
            mobileOnly: true,
            isSidemenuItem: true,
          },
        ],
      },
      {
        title: 'benefits_title',
        order: 2,
        items: [PLUS_ITEM],
      },
      {
        title: 'my_activity_title',
        order: 3,
        items: [],
      },
      {
        title: 'support_title',
        order: 4,
        items: SUPPORT_ITEMS,
      },
    ],
  };

  return {
    groups: sortByOrder(
      payload.groups.map((group) => ({
        ...group,
        items: sortByOrder(group.items),
      })),
    ),
  };
}
