export const PHONE_VALIDATION_TYPE = {
  CALL: 'call',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
};

export const IRL_VALID_URLS = [
  'https://stg-authenticate.pedidosya.com',
  'https://authenticate.pedidosya.com',
];
