import type { UserPersonalData } from '@app/types';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import * as queryKeys from '@utils/query-key-factory';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export function useUserPersonalData(): UseQueryResult<UserPersonalData, any> {
  return useQuery(
    queryKeys.userPersonalData(),
    async function getUserPersonalData(): Promise<unknown> {
      const response = await axios.get<unknown>(USER_ENDPOINTS.PERSONAL_DATA);

      return response.data;
    },
    {
      retry: 0,
    },
  );
}
