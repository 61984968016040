import { ChannelPhoneConfig } from '@app/types';
import { PHONE_VALIDATION_ENDPOINTS } from '@services/api-gateway-urls';
import * as queryKeys from '@utils/query-key-factory';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

type ChannelsPhoneConfigResponse = {
  channels: ChannelPhoneConfig[];
};

export function useChannelsPhoneConfig(): UseQueryResult<ChannelsPhoneConfigResponse, any> {
  return useQuery(
    queryKeys.channelsPhoneConfiguration(),
    async function getChannelsPhoneConfig(): Promise<unknown> {
      const { data } = await axios.get<ChannelsPhoneConfigResponse>(
        PHONE_VALIDATION_ENDPOINTS.CHANNELS_PHONE_CONFIG,
      );

      return data;
    },
    {
      retry: 0,
    },
  );
}
