import { defineMessages } from 'react-intl';

const messages = defineMessages({
  SMS: {
    id: 'phoneValidation.button.sms',
    defaultMessage: 'Enviar código por SMS',
  },
  WHATSAPP: {
    id: 'phoneValidation.button.whatsapp',
    defaultMessage: 'Enviar código por Whatsapp',
  },
  redirectToValidationErrorMessage: {
    id: 'phoneValidation.redirectToValidation.errorMessage',
    defaultMessage: 'El parámetro redirectTo no es una URL válida.',
  },
});

export default messages;
