import { RoutePaths } from '@app/routes';
import { isValidUrl, validateUrl } from '@commons/utils/string/url';
import OverlayLoader from '@components/OverlayLoader';
import fenixTheme from '@pedidosya/web-fenix/theme';
import { useDevice } from '@providers/DeviceProvider';
import { useIntl } from '@providers/IntlProvider';
import { useToast } from '@providers/ToastProvider';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Error from '@pedidosya/web-fenix/pages/Error';
import styled from 'styled-components';
import { CodeValidator } from './components/CodeValidator/CodeValidator';
import {
  EnterPhoneNumber,
  EnterPhoneNumberSuccessData,
} from './components/EnterPhoneNumber/EnterPhoneNumber';
import { PHONE_VALIDATION_TYPE, IRL_VALID_URLS } from './constants';
import messages from './messages';

export const Container = styled.div<{ isDesktop: boolean }>`
  padding: 0 ${({ isDesktop }) => (isDesktop ? '15%' : '0')};
`;

function PhoneValidation(): JSX.Element {
  const { isDesktop } = useDevice();
  const navigate = useNavigate();
  const toast = useToast();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showValidator, setShowValidator] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneValidationType, setPhoneValidationType] = useState(PHONE_VALIDATION_TYPE.SMS);
  const [sendChannels, setSendChannels] = useState([]);
  const redirectTo = searchParams.get('redirectTo');
  const validRedirectUrl = validateUrl(redirectTo, IRL_VALID_URLS);

  useEffect(() => {
    if (!isDesktop) {
      document.body.style.backgroundColor = fenixTheme.color('shape-color-background-primary');
    }

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [isDesktop]);

  const handleGoBackPress = () => navigate(-1);

  const handleRequestCodeSuccess = ({
    phoneNumber: number,
    phoneValidationType: type,
    channels,
  }: EnterPhoneNumberSuccessData) => {
    setPhoneNumber(number);
    setPhoneValidationType(type);
    setSendChannels(channels);
    setShowValidator(true);
  };

  const handleCodeValidatorBackPress = () => setShowValidator(false);

  const handleCodeValidatorSuccess = (token?: string) => {
    setIsLoading(true);

    if (redirectTo) {
      let redirectToDecoded = decodeURIComponent(redirectTo);
      const startWithSlash = redirectToDecoded.startsWith('/');

      if (!startWithSlash && !isValidUrl(redirectToDecoded)) {
        setIsLoading(false);
        toast.error(`${formatMessage(messages.redirectToValidationErrorMessage)}`);

        return;
      }

      if (token) {
        const hasParams = startWithSlash
          ? redirectToDecoded.indexOf('?') > 0
          : new URL(redirectToDecoded).search !== '';
        redirectToDecoded = `${redirectToDecoded}${hasParams ? '&' : '?'}token=${token}`;
      }

      window.location.assign(redirectToDecoded);
    } else {
      navigate(`${RoutePaths.Home}?pv=success`, { replace: true });
    }
  };

  if (redirectTo && !validRedirectUrl) {
    return <Error />;
  }

  return (
    <>
      {isLoading && <OverlayLoader />}
      <Container isDesktop={isDesktop}>
        {!showValidator && (
          <EnterPhoneNumber onBack={handleGoBackPress} onSuccess={handleRequestCodeSuccess} />
        )}
        {showValidator && (
          <CodeValidator
            phoneNumber={phoneNumber}
            phoneValidationType={phoneValidationType}
            onBack={handleCodeValidatorBackPress}
            onSuccess={handleCodeValidatorSuccess}
            channels={sendChannels}
          />
        )}
      </Container>
    </>
  );
}

export { PhoneValidation };
