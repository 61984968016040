import { FormFooter } from '@components/Form';
import { Skeleton } from '@pedidosya/web-fenix/atoms';
import * as React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-08')} ${theme.space('spacing-20')}`};
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.space('spacing-12')};
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ChipsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
`;

function PersonalDataSkeleton(): JSX.Element {
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      <SectionContainer>
        <QuestionContainer>
          <Skeleton width="shape-size-action-41" height="shape-size-action-4" shape="square" />
        </QuestionContainer>
        <ListItemContainer>
          {Array.from({ length: 3 })
            .fill('')
            .map((_n, i) => (
              <React.Fragment key={i}>
                <Skeleton height="shape-size-action-12" shape="square" style={{ width: '100%' }} />
              </React.Fragment>
            ))}
        </ListItemContainer>
      </SectionContainer>
      <SectionContainer>
        <QuestionContainer>
          <Skeleton width="shape-size-action-41" height="shape-size-action-4" shape="square" />
        </QuestionContainer>
        <ListItemContainer>
          {Array.from({ length: 1 })
            .fill('')
            .map((_n, i) => (
              <React.Fragment key={i}>
                <Skeleton height="shape-size-action-12" shape="square" style={{ width: '100%' }} />
              </React.Fragment>
            ))}
        </ListItemContainer>
      </SectionContainer>
      <SectionContainer>
        <QuestionContainer>
          <Skeleton width="shape-size-action-41" height="shape-size-action-4" shape="square" />
        </QuestionContainer>
        <ChipsContainer>
          {Array.from({ length: 4 })
            .fill('')
            .map((_n, i) => (
              <React.Fragment key={i}>
                <Skeleton
                  width="shape-size-action-24"
                  height="shape-size-action-7"
                  shape="square"
                />
              </React.Fragment>
            ))}
        </ChipsContainer>
      </SectionContainer>
      <FormFooter>
        <Skeleton height="shape-size-action-12" shape="square" style={{ width: '100%' }} />
      </FormFooter>
    </>
  );
}

export { PersonalDataSkeleton };
