export const OTHER_GENDER_ID = 'other';

export const RULE_ERROR_MESSAGES = {
  minimum_length: 'errorMessageMinimumLength',
  only_letters: 'errorMessageOnlyLetters',
};

export const TRACKING_ORIGIN_MY_ACCOUNT = 'my_account';

export const TRACKING_SECTION_FIRST_NAME = 'firstName';
export const TRACKING_SECTION_LAST_NAME = 'lastName';
export const TRACKING_SECTION_NICK_NAME = 'nickName';
export const TRACKING_SECTION_BIRTHDAY = 'birthday';
export const TRACKING_SECTION_GENDER = 'gender';

export const TRACKING_CLICK_LOCATION_FIRST_NAME = 'firstName';
export const TRACKING_CLICK_LOCATION_LAST_NAME = 'lastName';
export const TRACKING_CLICK_LOCATION_NICK_NAME = 'nickName';
export const TRACKING_CLICK_LOCATION_BIRTHDAY_START = 'start';
export const TRACKING_CLICK_LOCATION_BIRTHDAY_CONTINUE = 'continue';
export const TRACKING_CLICK_LOCATION_BIRTHDAY_CANCEL = 'cancel';
export const TRACKING_CLICK_LOCATION_BIRTHDAY_DELETE = 'delete';
export const TRACKING_CLICK_LOCATION_GENDER = 'gender';
export const TRACKING_CLICK_LOCATION_GENDER_CUSTOM = 'gender_custom';
export const TRACKING_CLICK_LOCATION_BACK = 'back';

export const TRACKING_CHANGES_FIRST_NAME = 'firstName';
export const TRACKING_CHANGES_LAST_NAME = 'lastName';
export const TRACKING_CHANGES_NICK_NAME = 'nickName';
export const TRACKING_CHANGES_BIRTHDAY = 'birthday';
export const TRACKING_CHANGES_GENDER = 'gender';

export const TRACKING_ERR_MSG_UNKNOWN = 'UNKNOWN ERROR';

export const ONLY_LETTERS_REGEX = /^[A-Za-zñÑ\s]+$/;
export const VALIDATED_STATUS = 'VALIDATED';
export const MAX_NAME_LENGTH = 30;
