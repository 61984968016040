import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import * as queryKeys from '@utils/query-key-factory';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

enum ImageTypes {
  JPEG = 'jpeg',
  PNG = 'png',
}

type UpdateAvatar = {
  avatar: string;
  type?: string;
};

export function useUpdateUserAvatar() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ avatar, type }: UpdateAvatar) => {
      const extension = type ? (type.toUpperCase() as keyof typeof ImageTypes) : 'PNG';
      await axios.put(USER_ENDPOINTS.AVATAR, {
        avatar,
        extension: ImageTypes[extension] || ImageTypes.PNG,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.me());
      },
    },
  );
}
