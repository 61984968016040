import styled from 'styled-components';

const BottomSheetContent = styled.div`
  position: absolute;
  overflow: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  border-top-left-radius: ${({ theme }) => theme.borderRadius('border-radius-03')};
  border-top-right-radius: ${({ theme }) => theme.borderRadius('border-radius-03')};
  transform: translateY(130%);
  transition-timing-function: ease-in;
  transition: 0.3s;
  max-height: 100%;
`;

export default BottomSheetContent;
