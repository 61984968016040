import ChevronLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import IconButton from '@pedidosya/web-fenix/atoms/IconButton';
import { Navbar, LargeNavbar } from '@pedidosya/web-fenix/organisms';
import { Title, Subtitle } from '@components/Fenix/Typography';
import * as React from 'react';
import styled from 'styled-components';

const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const EmptyContainer = styled.div`
  height: 40px;
  min-width: 40px;
`;

const SpaceContainer = styled.div`
  height: 20px;
  width: 100%;
`;

type BackButtonProps = {
  onClick?: () => void;
};

const BackButton = ({ onClick = () => {} }: BackButtonProps): JSX.Element => (
  <IconButton icon={<ChevronLeft />} size="medium" withStroke={false} onClick={onClick} />
);

type MobileHeaderProps = {
  title?: string;
  showBack?: boolean;
  withMarginBottom?: boolean;
  onBack?: () => void;
};

export const MobileHeader = ({
  title = '',
  showBack = true,
  withMarginBottom = true,
  onBack = () => {},
}: MobileHeaderProps): JSX.Element => (
  <>
    <LargeNavbar
      mainHeader={
        <MainHeader>
          {showBack ? <BackButton onClick={onBack} /> : <EmptyContainer />}
          {!!title && <Title style={{ padding: '8px 16px' }}>{title}</Title>}
        </MainHeader>
      }
      secondHeader={
        <Navbar>
          <Container>
            {showBack ? <BackButton onClick={onBack} /> : <EmptyContainer />}
            <Subtitle>{title}</Subtitle>
            <EmptyContainer />
          </Container>
        </Navbar>
      }
    />
    {!!title && withMarginBottom && <SpaceContainer />}
  </>
);
