import { RoutePaths } from '@app/routes';
import { FIXED_FOOTER_ID } from '@components/ShellFooter/constants';
import { useFlagVariations } from '@hooks/useFlagVariations';
import { usePrevious } from '@hooks/use-previous';
import { Snackbar } from '@pedidosya/web-fenix/molecules';
import { useDevice } from '@providers/DeviceProvider';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const ToastRefId = 'toast-ref';
const ToastRef = styled.div``;

enum SNACKBAR_TYPE {
  INFO = 'informative',
  POSITIVE = 'positive',
  WARNING = 'warning',
  ERROR = 'error',
}

enum SNACKBAR_POSITION {
  TOP = 'top-right',
  BOTTOM = 'bottom-center',
}

enum ToastType {
  Error = 'error',
  Info = 'info',
  None = 'none',
  Success = 'success',
}

interface Toast {
  type: ToastType;
  message: string;
  actionLabel?: string;
  onClickAction?: () => void;
}

interface ToastContextProps {
  error: (message: string, actionLabel?: string, onClickAction?: () => void) => void;
  info: (message: string, actionLabel?: string, onClickAction?: () => void) => void;
  success: (message: string, actionLabel?: string, onClickAction?: () => void) => void;
}

const ToastContext = React.createContext<ToastContextProps>({} as ToastContextProps);
ToastContext.displayName = 'ToastContext';

type ToastProviderProps = {
  children?: React.ReactNode;
};

const snackbarTypeToMessageTypeMap = {
  [ToastType.Error]: SNACKBAR_TYPE.ERROR,
  [ToastType.Info]: SNACKBAR_TYPE.INFO,
  [ToastType.None]: SNACKBAR_TYPE.INFO,
  [ToastType.Success]: SNACKBAR_TYPE.POSITIVE,
};

const noToast = { type: ToastType.None, message: '' };

function ToastProvider({ children }: ToastProviderProps): JSX.Element {
  const [toast, setToast] = React.useState<Toast>(noToast);
  const previousToast = usePrevious(toast);
  const { isDesktop } = useDevice();
  const location = useLocation();
  const toastRef = React.useRef<HTMLDivElement>(null);
  const { isBottomNavHomeEnabled } = useFlagVariations();

  const close = React.useCallback(() => setToast(noToast), [setToast]);

  const error = React.useCallback(
    (message: string, actionLabel?: string, onClickAction?: any) =>
      setToast({ type: ToastType.Error, message, actionLabel, onClickAction }),
    [setToast],
  );
  const info = React.useCallback(
    (message: string, actionLabel?: string, onClickAction?: any) =>
      setToast({ type: ToastType.Info, message, actionLabel, onClickAction }),
    [setToast],
  );
  const success = React.useCallback(
    (message: string, actionLabel?: string, onClickAction?: any) =>
      setToast({ type: ToastType.Success, message, actionLabel, onClickAction }),
    [setToast],
  );

  const value = React.useMemo(
    () => ({ error, info, setToast, success }),
    [error, info, setToast, success],
  );

  const hasToast = toast.type !== ToastType.None;
  const toastToBeShown = hasToast ? toast : previousToast;

  React.useEffect(() => {
    const positionSnackbarElement = toastRef.current?.querySelector(
      `#${ToastRefId} > div`,
    ) as HTMLDivElement;
    const isToastShownInNonDesktopDevice = hasToast && positionSnackbarElement && !isDesktop;
    if (isToastShownInNonDesktopDevice) {
      const fixedFooterElementHeight = document.getElementById(FIXED_FOOTER_ID)?.clientHeight ?? 0;
      const isHomeRoute = location.pathname === RoutePaths.Home;
      const spaceExtra = isHomeRoute && isBottomNavHomeEnabled ? 16 : 0;
      if (fixedFooterElementHeight > 0)
        positionSnackbarElement.style.bottom = `${fixedFooterElementHeight + spaceExtra}px`;
    }

    return () => {
      if (positionSnackbarElement) positionSnackbarElement.style.bottom = null;
    };
  }, [hasToast, isBottomNavHomeEnabled, isDesktop, location]);

  React.useEffect(() => {
    let timeoutHandler: NodeJS.Timeout;
    if (toast) {
      timeoutHandler = setTimeout(() => {
        close();
      }, 4000);
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [close, toast]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastRef id={ToastRefId} ref={toastRef}>
        <Snackbar
          type={snackbarTypeToMessageTypeMap[toastToBeShown.type]}
          label={toastToBeShown.message}
          position={isDesktop ? SNACKBAR_POSITION.TOP : SNACKBAR_POSITION.BOTTOM}
          show={hasToast}
          onClose={close}
          onClickAction={toast?.onClickAction}
          actionLabel={toast?.actionLabel}
        />
      </ToastRef>
    </ToastContext.Provider>
  );
}

function useToast(): ToastContextProps {
  const context = React.useContext(ToastContext);
  if (context === undefined) {
    throw new Error(`useToast must be used within an ToastProvider`);
  }

  return context;
}

export { ToastProvider, useToast };
