import { DeviceWidthBreakpoint } from '@providers/DeviceProvider';
import styled from 'styled-components';

type MEDIA_QUERIES_STYLES = Record<string, (styles: string) => string>;

const media: MEDIA_QUERIES_STYLES = {
  sm: (styles: string) => `@media only screen and (max-width: 0px) {
    ${styles}
  }`,
  md: (styles: string) => `@media only screen and (max-width: ${DeviceWidthBreakpoint.Mobile}px) {
    ${styles}
  }`,
  lg: (styles: string) => `@media only screen and (max-width: ${DeviceWidthBreakpoint.Desktop}px) {
    ${styles}
  }`,
};

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  min-height: auto;
`;

export const Row = styled.div<{ fullSize: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${({ fullSize }) => fullSize && `flex: 1 0;`};
`;

export const Col = styled.div<{ size: number; collapse?: string }>`
  flex: ${({ size }) => size};
  width: 0;
  flex-basis: inherit;
  ${({ collapse }) => collapse && media[collapse](`display: none;`)};
`;
