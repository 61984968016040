function timeLeftFormatter(millis) {
  if (!millis || millis < 0) return '0:00';
  const seconds = Math.floor(millis / 1000);

  const minutes = Math.floor(seconds / 60);
  const remainderSeconds = seconds % 60;
  const display = `${minutes}:${remainderSeconds < 10 ? '0' : ''}${remainderSeconds}`;
  return display;
}

export default timeLeftFormatter;
