export const homeConfiguration = () => ['home-configuration'] as const;
export const me = () => ['me'] as const;
export const userAvailableCredentials = () => ['user-available-credentials'] as const;
export const userPersonalData = () => ['user-personal-data'] as const;
export const userValidations = () => ['user-validations'] as const;
export const userPlusSubscription = () => ['user-plus-subscription'] as const;
export const userPlusEntrypointAvailable = () => ['user-plus-entrypoint-available'] as const;
export const userFavoriteVendors = () => ['user-favorite-vendors'] as const;
export const countriesPhoneConfiguration = () => ['countries-phone-configuration'] as const;
export const channelsPhoneConfiguration = () => ['channels-phone-configuration'] as const;
