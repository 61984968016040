const ENDPOINT_URL_BASE = '/api/my-account';

const USER_URL = `${ENDPOINT_URL_BASE}/user`;
const PHONE_VALIDATION_URL = `${ENDPOINT_URL_BASE}/phone-validation`;

export const USER_ENDPOINTS = {
  ME: `${USER_URL}/me`,
  AVAILABLE_CREDENTIALS: `${USER_URL}/me/available-credentials`,
  PLUS_SUBSCRIPTION: `${USER_URL}/me/plus-subscription`,
  NAMES: `${USER_URL}/names`,
  AVATAR: `${USER_URL}/avatar`,
  PERSONAL_DATA: `${USER_URL}/personal-data`,
  FAVORITE_VENDORS: `${USER_URL}/favorites/vendors`,
  PASSWORD_RULES: `${USER_URL}/password-rules`,
  CHANGE_PASSWORD: `${USER_URL}/change-password`,
  LOGOUT_FROM_OTHER_DEVICES: `${USER_URL}/logout-from-other-devices`,
  LOGOUT: `${USER_URL}/logout`,
  USER_VALIDATIONS: `${USER_URL}/user-validations`,
};

export const PHONE_VALIDATION_ENDPOINTS = {
  COUNTRIES_PHONE_CONFIG: `${PHONE_VALIDATION_URL}/countries`,
  CHANNELS_PHONE_CONFIG: `${PHONE_VALIDATION_URL}/channels`,
  SEND_PHONE_CODE: `${PHONE_VALIDATION_URL}/send-phone-code`,
  VERIFY_PHONE_CODE: `${PHONE_VALIDATION_URL}/verify-phone-code`,
};

export const GLOBAL_ENDPOINTS = {
  LOYALTY_ENTRYPOINT_AVAILABLE: '/loyalty/api/user/entrypointAvailable',
};
