import styled from 'styled-components';

const Handler = styled.div`
  margin: ${({ theme }) => theme.space('spacing-06')} auto 0;
  width: ${({ theme }) => theme.size('size-12')};
  height: ${({ theme }) => theme.size('size-01')};
  border-radius: ${({ theme }) => theme.borderRadius('border-radius-circle')};
  background-color: ${({ theme }) => theme.color('shape-color-surface-action-oncontent-pressed')};
`;

export default Handler;
