import * as queryKeys from '@app/utils/query-key-factory';
import { GLOBAL_ENDPOINTS } from '@services/api-gateway-urls';
import axios from 'axios';
import { useQuery } from 'react-query';

interface UserPlusEntrypointAvailable {
  available: boolean;
}

export function useUserPlusEntrypointAvailable() {
  return useQuery(
    queryKeys.userPlusEntrypointAvailable(),
    async function getUserPlusEntrypointAvailable() {
      try {
        const response = await axios.get<UserPlusEntrypointAvailable>(
          GLOBAL_ENDPOINTS.LOYALTY_ENTRYPOINT_AVAILABLE,
        );

        return response.data;
      } catch (error) {
        return { available: false };
      }
    },
  );
}
