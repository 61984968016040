import { GlobalRoutePaths } from '@app/routes';
import { TrackingEvents } from '@app/tracking';
import seeYouSoon from '@assets/see-you-soon.svg';
import { HttpStatusCode } from '@commons/http';
import { HttpApiError } from '@commons/services/models/ServiceResponse';
import { Label, Title } from '@components/Fenix/Typography';
import OverlayLoader from '@components/OverlayLoader';
import ShellFooter from '@components/ShellFooter';
import { useDeleteAccount } from '@hooks/use-delete-account';
import { Button } from '@pedidosya/web-fenix/atoms';
import Error from '@pedidosya/web-fenix/pages/Error';
import { useAuth } from '@providers/AuthProvider';
import { DeviceWidthBreakpoint } from '@providers/DeviceProvider';
import { useIntl } from '@providers/IntlProvider';
import { useTracker } from '@providers/TrackerProvider';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  genericErrorMessages,
  sessionErrorMessages,
  successMessages,
  tokenErrorMessages,
} from './messages';

const Main = styled.main`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-right: ${({ theme }) => theme.space('spacing-layout-small')};
  padding-left: ${({ theme }) => theme.space('spacing-layout-small')};
  background: ${({ theme }) => theme.color('shape-color-background-primary')};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: auto;
  min-height: 100svh;
  @media (max-width: ${DeviceWidthBreakpoint.Desktop}px) {
    justify-content: space-between;
  }
`;

const Content = styled.div`
  @media (max-width: ${DeviceWidthBreakpoint.Desktop}px) {
    margin: 0 20px;
  }
`;

const ImageContainer = styled.section`
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.space('spacing-layout-medium')};
`;

const Image = styled.img`
  display: block;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
`;

const TitleContainer = styled.section`
  text-align: center;
  padding-bottom: ${({ theme }) => theme.space('spacing-layout-xsmall')};
`;

const MessageContainer = styled.section`
  text-align: center;
  padding-bottom: ${({ theme }) => theme.space('spacing-layout-xsmall')};
`;

function Delete(): JSX.Element {
  const [showBrandError, setShowBrandError] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(genericErrorMessages);
  const { user } = useAuth();
  const { isLoading, mutateAsync } = useDeleteAccount();
  const { formatMessage } = useIntl();
  const tracker = useTracker();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const goToHome = React.useCallback(() => {
    window.location.href = GlobalRoutePaths.Home;
  }, []);

  const getErrorMessage = React.useCallback((error: any) => {
    if (error.messages && error.messages.length > 0) {
      return error.messages[0];
    }

    if (error.errors && error.errors.length > 0) {
      return error.errors[0].message;
    }

    return 'UNKNOWN ERROR';
  }, []);

  const makeError = React.useCallback(
    (error: HttpApiError) => {
      setShowBrandError(true);

      if (error.networkStatus === HttpStatusCode.Forbidden) {
        setErrorMessages(tokenErrorMessages);
      }

      if (error.networkStatus === HttpStatusCode.Unauthorized) {
        setErrorMessages(sessionErrorMessages);
      }

      tracker.track(TrackingEvents.DeleteAccountFailed, {
        userId: user?.id,
        errorMessage: getErrorMessage(error),
      });
    },
    [user, tracker, setErrorMessages, getErrorMessage],
  );

  React.useEffect(() => {
    if (!token) {
      goToHome();

      return;
    }

    async function tryToDeleteAccount() {
      try {
        const { error, success } = await mutateAsync({ token });
        if (error) {
          makeError(error);
        }

        if (success) {
          tracker.track(TrackingEvents.DeleteAccountCompleted, {
            userId: user?.id,
          });
        }
      } catch (error) {
        makeError(error);
      }
    }

    tryToDeleteAccount();
  }, []);

  if (isLoading) {
    return <OverlayLoader />;
  }

  if (showBrandError) {
    return (
      <Error
        errorCode={500}
        title={formatMessage(errorMessages.title)}
        message={formatMessage(errorMessages.subtitle)}
        primaryActionLabel={formatMessage(errorMessages.done)}
        primaryActionClick={goToHome}
      />
    );
  }

  return (
    <Main>
      <Container>
        <div />
        <Content>
          <ImageContainer>
            <picture>
              <Image
                alt={formatMessage(successMessages.title)}
                width={256}
                height={160}
                src={seeYouSoon}
                aria-hidden="true"
              />
            </picture>
          </ImageContainer>
          <TitleContainer>
            <Title size="small">{formatMessage(successMessages.title)}</Title>
          </TitleContainer>
          <MessageContainer>
            <Label size="medium" color="secondary">
              {formatMessage(successMessages.subtitle)}
            </Label>
          </MessageContainer>
        </Content>
        <ShellFooter withPadding={true} hideBorderTop={true}>
          <Button
            label={formatMessage(successMessages.done)}
            size="large"
            onClick={goToHome}
            fullWidth
          />
        </ShellFooter>
      </Container>
    </Main>
  );
}

export { Delete };
