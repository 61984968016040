import * as Sentry from '@sentry/browser';

async function initSentry(sentryConfig) {
  const { dsn, isEnabled, environment } = sentryConfig;

  if (isEnabled) {
    try {
      Sentry.init({
        dsn,
        integrations: [Sentry.browserTracingIntegration()],
        environment,
        release: `${APP_NAME}@${APP_VERSION}`,
        tracesSampleRate: 0.1,
      });

      return Sentry;
    } catch (error) {
      console.error(error);
      return null;
    }
  } else {
    return null;
  }
}

export default initSentry;
