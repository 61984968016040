import { ErrorPage } from '@app/pages/error';
import ChangePassword from '@app/pages/ChangePassword';
import Delete from '@app/pages/Delete';
import EditAvatar from '@app/pages/EditAvatar';
import EditPersonalInformation from '@app/pages/EditPersonalInfo';
import Favorites from '@app/pages/Favorites';
import Home from '@app/pages/Home';
import { Offline } from '@app/pages/offline';
import PersonalData from '@app/pages/PersonalData';
import PhoneValidation from '@app/pages/PhoneValidation';
import { LoggedOutUserMenu } from '@app/pages/logged-out-user-menu';
import { GlobalRoutePaths, RoutePaths, ShellRoutePaths } from '@app/routes';
import { TrackingEvents } from '@app/tracking';
import { HttpStatusCode } from '@commons/http';
import { ErrorBoundary } from '@components/ErrorBoundary';
import OverlayLoader from '@components/OverlayLoader';
import Shell from '@components/Shell';
import useOffline from '@hooks/useOffline';
import { useAuth } from '@providers/AuthProvider';
import { useDevice } from '@providers/DeviceProvider';
import { useTracker } from '@providers/TrackerProvider';
import { useWindow } from '@providers/WindowProvider';
import * as React from 'react';
import {
  Link as BaseLink,
  LinkProps,
  Route,
  Routes,
  useMatch,
  useSearchParams,
} from 'react-router-dom';

export function Link({ to, ...props }: LinkProps): JSX.Element {
  return <BaseLink to={to} {...props} />;
}

const routes: Record<ShellRoutePaths, JSX.Element> = {
  [RoutePaths.ChangePassword]: <ChangePassword />,
  [RoutePaths.Home]: <Home />,
  [RoutePaths.EditAvatar]: <EditAvatar />,
  [RoutePaths.EditPersonalInformation]: <EditPersonalInformation />,
  [RoutePaths.PersonalData]: <PersonalData />,
};

function Router(): JSX.Element {
  const { isAuthenticated } = useAuth();
  const { isDesktop } = useDevice();
  const windowObject = useWindow();
  const isOffline = useOffline();
  const tracker = useTracker();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get('origin');
  const redirectToParam = searchParams.get('redirectTo');

  const isDeleteRoute = useMatch({ path: RoutePaths.Delete }) !== null;
  const isPhoneValidationRoute = useMatch({ path: RoutePaths.PhoneValidation }) !== null;

  const isV2 = useMatch({ path: '/my-account-v2/*' }) !== null;

  React.useEffect(() => {
    if (isV2 && windowObject) {
      const href = windowObject.location.href;
      const newHref = href.replace('/my-account-v2', RoutePaths.Home);
      windowObject.location.replace(newHref);
    } else if (
      (!isAuthenticated && isDesktop && !isDeleteRoute && !isPhoneValidationRoute) ||
      (!isAuthenticated && isPhoneValidationRoute && !redirectToParam)
    ) {
      if (windowObject) windowObject.location.href = GlobalRoutePaths.Home;
    } else if (!isDeleteRoute) {
      tracker.track(TrackingEvents.MyAccountLoaded, {
        userLoggedIn: isAuthenticated,
        origin,
      });
    }
  }, [
    windowObject,
    isV2,
    isAuthenticated,
    isDesktop,
    isDeleteRoute,
    isPhoneValidationRoute,
    redirectToParam,
  ]);

  if (isOffline) {
    return <Offline />;
  }

  if (isV2 || (!isAuthenticated && isPhoneValidationRoute && !redirectToParam)) {
    return <OverlayLoader />;
  }

  if (!isAuthenticated && !isDeleteRoute && !isPhoneValidationRoute) {
    if (isDesktop) {
      return <OverlayLoader />;
    }

    return <LoggedOutUserMenu />;
  }

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Routes>
        <Route path={RoutePaths.Home} element={<Shell />}>
          {Object.entries(routes).map(([path, Element]) => (
            <Route key={path} path={path} element={Element} />
          ))}
        </Route>
        <Route path={RoutePaths.PhoneValidation} element={<PhoneValidation />} />
        <Route path={RoutePaths.Favorites} element={<Favorites />} />
        <Route path={RoutePaths.Delete} element={<Delete />} />
        <Route path="*" element={<ErrorPage errorCode={HttpStatusCode.NotFound} />} />
      </Routes>
    </ErrorBoundary>
  );
}

export { Router };
