import * as React from 'react';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { PublicEnvironment } from '@app/types';
import { initializeFWF } from '@fwf/utils';

enum ObservableEvents {
  RelevantContextChange = '@fwf/RELEVANT_CONTEXT_CHANGE',
  MultiFlagRequest = '@fwf/MULTI_FLAG_REQUEST',
  SingleFlagRequest = '@fwf/SINGLE_FLAG_REQUEST',
  FlagsUpdatedInBackground = '@fwf/FLAGS_UPDATED_IN_BACKGROUND',
  EventSentToTracker = '@fwf/EVENT_SENT_TO_TRACKER',
  OnReady = '@fwf/ON_READY',
}

type FlagPayload<T> = {
  variation: T;
  abTest: boolean;
  explanation: {
    kind: string;
    from: string;
  };
  relevantContext: string;
  trackInfo: {
    variationName: string;
    flagType: string;
    flagEnabled: boolean;
    trackerServices: Array<string>;
  };
  dateCached: string;
};

type FlagResponse<T> = Record<string, FlagPayload<T>>;

type Flag<T> = {
  isEnabled: boolean;
  isAbTest: boolean;
  keyName: string;
  variation: T;
};

type SubscribeObserverFunction = (response: SubscribeObserverResponse<unknown>) => void;

export type FWFContext = {
  googleClientId: string;
  userId?: string;
  email?: string;
  country?: string;
  globalEntityId?: string;
  platform?: string;
};

export type FunWithFlagsApi = {
  setContext: (context: FWFContext) => void;
  getFlag: <T>(key: string, fallback: T) => Promise<Flag<T>>;
  subscribeFeatures: (flagNames: Array<string>) => Promise<FlagResponse<unknown>>;
  unsubscribeFeatures: (flagNames: Array<string>) => void;
  subscribeObserver: (subscribeObserverFn: SubscribeObserverFunction) => void;
  unsubscribeObserver: (subscribeObserverFn: SubscribeObserverFunction) => void;
};

type FunWithFlagsContextProperties = {
  flags: FlagResponse<unknown>;
  fwf: FunWithFlagsApi;
};

const FunWithFlagsContext = React.createContext<FunWithFlagsContextProperties>(
  {} as FunWithFlagsContextProperties,
);
FunWithFlagsContext.displayName = 'FunWithFlagsContext';

type FunWithFlagsProviderProperties = {
  children: React.ReactNode;
  flags: string[];
  fwf: FunWithFlagsApi;
  initialState?: FlagResponse<unknown>;
};

type SubscribeObserverResponse<T> = {
  type: string;
  data: FlagResponse<T>;
};

function maybeUpdateFlags(
  previousFlags: FlagResponse<unknown>,
  maybeNewFlags: FlagResponse<unknown>,
) {
  const newFlags = JSON.parse(JSON.stringify(previousFlags));
  for (const [key, flag] of Object.entries(maybeNewFlags)) {
    if (!newFlags[key] || flag.variation !== newFlags[key].variation) {
      newFlags[key] = flag;
    }
  }

  return newFlags;
}

function FunWithFlagsProvider({
  children,
  fwf,
  flags: flagNames,
  initialState = {},
}: FunWithFlagsProviderProperties): JSX.Element {
  const publicEnv: PublicEnvironment = usePublicEnv();
  const [flags, setFlags] = React.useState<FlagResponse<unknown>>(initialState);

  function flagsUpdatedObserver(response: SubscribeObserverResponse<unknown>): void {
    if (
      response.type === ObservableEvents.FlagsUpdatedInBackground ||
      response.type === ObservableEvents.RelevantContextChange
    ) {
      const maybeNewFlags = response.data;

      setFlags((previousFlags) => maybeUpdateFlags(previousFlags, maybeNewFlags));
    }
  }

  React.useEffect(() => {
    initializeFWF(fwf, publicEnv);
  }, [fwf, publicEnv]);

  React.useEffect(() => {
    fwf.subscribeFeatures(flagNames).then((maybeNewFlags) => {
      setFlags((previousFlags) => maybeUpdateFlags(previousFlags, maybeNewFlags));
    });
    fwf.subscribeObserver(flagsUpdatedObserver);

    return () => {
      fwf.unsubscribeFeatures(flagNames);
      fwf.unsubscribeObserver(flagsUpdatedObserver);
    };
  }, [flagNames, fwf]);

  const value = React.useMemo(() => ({ flags, fwf }), [flags, fwf]);

  return <FunWithFlagsContext.Provider value={value}>{children}</FunWithFlagsContext.Provider>;
}

function useFunWithFlagsApi(): FunWithFlagsApi {
  const context = React.useContext(FunWithFlagsContext);
  if (context === undefined) {
    throw new Error('useFunWithFlags must be used within an FunWithFlagsProvider');
  }

  return context.fwf;
}

function useFlags(): FlagResponse<unknown> {
  const context = React.useContext(FunWithFlagsContext);
  if (context === undefined) {
    throw new Error('useFlags must be used within an FunWithFlagsProvider');
  }

  return context?.flags || {};
}

type UseFlagResult<T> = FlagPayload<T> | { variation: T };

function useFlag<T>(name: string, fallback: T): UseFlagResult<T> {
  const flags = useFlags();

  return (flags[name] as FlagPayload<T>) ?? { variation: fallback };
}

function useFlagVariation<T>(name: string, fallback: T): T {
  const flag = useFlag(name, fallback);

  return flag.variation;
}

export { FunWithFlagsProvider, useFlag, useFlagVariation, useFunWithFlagsApi };
