import OverlayLoader from '@components/OverlayLoader';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

type LayoutProps = {
  children: React.ReactNode;
  isLoading?: boolean;
};

const Layout = ({ children, isLoading = false }: LayoutProps): JSX.Element => (
  <Container>
    {isLoading && <OverlayLoader />}
    {children}
  </Container>
);

export { Layout };
