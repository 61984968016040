export enum TrackingFavoriteTab {
  Partners = 'partners',
  Items = 'items',
}

export enum TrackingShopCLickOrigin {
  Partner = 'myFav_partnerTab',
  Item = 'myFav_itemTab',
}

export enum TrackingShopStatus {
  Open = 'open',
  OnlyPickup = 'only_pickup',
  OpensLaterPreorder = 'opens_later_preorder',
  OpensLaterNoPreorder = 'opens_later_no_preorder',
  NoDeliveryNow = 'no_delivery_now',
  MomentarilyClosed = 'momentarily_closed',
  ClosedToday = 'closed_today',
  OutOfLocation = 'out_of_location',
}

export enum TrackingFavoriteAction {
  Back = 'back',
  EmptySearchPartners = 'empty_search_partners',
  DeletePartner = 'delete_partner',
}

export const VENDOR_STATE_LABEL = {
  OPEN_IN: 'OPEN_IN',
  NO_DELIVERY_AVAILABLE: 'NO_DELIVERY_AVAILABLE',
  CLOSED: 'CLOSED',
  TEMPORARILY_CLOSED: 'TEMPORARILY_CLOSED',
  OUT_OF_REACH: 'OUT_OF_REACH',
};

export const VENDOR_STATE_TAG = {
  PICKUP: 'PICKUP',
  PRE_ORDER: 'PRE_ORDER',
};

export const VENDOR_CONDITIONS = {
  ONLY_CASH: 'ONLY_CASH',
  VENDOR_DELIVERY: 'VENDOR_DELIVERY',
};

export const VENDOR_DISCOUNT_TYPE = {
  FIXED: 'FIXED',
  UP_TO: 'UP_TO',
};
