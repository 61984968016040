import * as React from 'react';
import { IntlProvider as ReactIntlProvider, useIntl } from 'react-intl';
import OverlayLoader from '@components/OverlayLoader';

const getMessages = (locale: string) => import(`../../build/translations/${locale}.json`);

type IntlProviderProps = {
  children: React.ReactElement;
  locale: string;
  initialMessages: Record<string, string>;
};

function IntlProvider({ children, locale, initialMessages }: IntlProviderProps): JSX.Element {
  const [messages, setMessages] = React.useState(initialMessages);
  const setNoMessages = () => setMessages({});

  React.useEffect(() => {
    if (messages) return;
    getMessages(locale).then(setMessages).catch(setNoMessages);
  }, [messages]);

  if (!messages) return <OverlayLoader />;

  return (
    <ReactIntlProvider messages={messages} locale={locale} defaultLocale="es">
      {children}
    </ReactIntlProvider>
  );
}

export { IntlProvider, useIntl };
