import { HttpStatusCode } from '@commons/http';
import Error from '@pedidosya/web-fenix/pages/Error';
import * as React from 'react';
import { useNavigate } from 'react-router';

type ErrorPageProps = {
  errorCode?: HttpStatusCode;
};

function ErrorPage({
  errorCode = HttpStatusCode.InternalServerError,
}: ErrorPageProps): JSX.Element {
  const navigate = useNavigate();
  const reload = () => navigate(0);
  const back = () => navigate(-1);

  if (errorCode === HttpStatusCode.NotFound) {
    return (
      <Error
        errorCode={HttpStatusCode.NotFound}
        primaryActionLabel="Regresar"
        primaryActionClick={back}
      />
    );
  }

  return (
    <Error
      errorCode={errorCode}
      primaryActionLabel="Reintentar"
      primaryActionClick={reload}
      secondaryActionLabel="Regresar"
      secondaryActionClick={back}
    />
  );
}

export { ErrorPage };
