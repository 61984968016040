import PropTypes from 'prop-types';
import * as React from 'react';
import Modal from 'react-responsive-modal';

const styles = {
  modal: {
    borderRadius: '6px',
  },
};

// react-responsive-modal must not be used directly.
// Instead we must use this wrapper.
const ResponsiveModal = React.forwardRef(({ children, open, blockScroll, ...props }, ref) => (
  <>
    {open && (
      <div ref={ref}>
        <Modal
          {...props}
          open={open}
          blockScroll={blockScroll}
          focusTrapped={false}
          styles={styles}
        >
          {children}
        </Modal>
      </div>
    )}
  </>
));

ResponsiveModal.displayName = 'ResponsiveModal';

ResponsiveModal.defaultProps = {
  blockScroll: true,
};

ResponsiveModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  blockScroll: PropTypes.bool,
};

export default ResponsiveModal;
