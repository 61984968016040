import ServiceResponse from '@commons/services/models/ServiceResponse';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import * as queryKeys from '@utils/query-key-factory';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

type UpdatePersonalData = {
  firstName: string;
  lastName: string;
  nickname: string;
  birthday: string;
  gender: {
    id: string;
    description: string;
  };
};

export function useUpdateUserPersonalData() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdatePersonalData) => {
      try {
        await axios.put(USER_ENDPOINTS.PERSONAL_DATA, payload);

        return new ServiceResponse({ success: true });
      } catch (error) {
        return new ServiceResponse({ error });
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.me());
        queryClient.invalidateQueries(queryKeys.userPersonalData());
      },
    },
  );
}
