import PropTypes from 'prop-types';
import React from 'react';
import SplitInput from '@pedidosya/web-fenix/molecules/SplitInput';
import styled from 'styled-components';

const Country = styled.div`
  align-items: center;
  display: flex;
`;

Country.Flag = styled.img`
  height: 14px;
  width: 20px;
`;

Country.Prefix = styled.div`
  margin: 0 4px;
`;

const ONLY_NUMBERS_REGEX = /^[0-9\b]+$/;
let oldValue = '';

const PhoneContainer = ({
  country,
  onCountryPress,
  onInputBlur,
  onInputFocus,
  phoneNumber,
  handleChange,
}) => {
  const imageSrc = (countryShortname) =>
    `https://pedidosya.dhmedia.io/image/pedidosya/flags/v2/flag-${countryShortname}.png?quality=70&width=100`;

  const handleKeyDown = (event) => {
    const input = event.target;
    oldValue = input.value;
  };

  const handleInput = (event) => {
    const input = event.target;
    if (!ONLY_NUMBERS_REGEX.test(input.value)) {
      input.value = input.value ? oldValue : '';
    }
  };

  const onChange = (event) => {
    const input = event.target;
    if (input.value !== oldValue && (input.value === '' || ONLY_NUMBERS_REGEX.test(input.value))) {
      handleChange(event);
    }
  };

  return (
    <SplitInput
      data-testid="split-input"
      aria-label="Número de celular"
      label="Número de celular"
      type="tel"
      inputValue={phoneNumber}
      prefixLabel={
        <Country>
          <Country.Flag src={imageSrc(country.shortName)} alt={country.phonePrefix} />
          <Country.Prefix>{country.phonePrefix}</Country.Prefix>
        </Country>
      }
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      onChange={onChange}
      onOpen={onCountryPress}
      placeholder={`Ej: ${country.phonePlaceholder}`}
    />
  );
};

PhoneContainer.propTypes = {
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    phonePlaceholder: PropTypes.string.isRequired,
    phonePrefix: PropTypes.string.isRequired,
    shortName: PropTypes.string.isRequired,
  }),
  handleChange: PropTypes.func,
  onCountryPress: PropTypes.func,
  onInputBlur: PropTypes.func,
  onInputFocus: PropTypes.func,
  phoneNumber: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PhoneContainer;
