import * as queryKeys from '@app/utils/query-key-factory';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import axios from 'axios';
import { useQuery } from 'react-query';

interface UserPlusSubscription {
  active: boolean;
}

export function useUserPlusSubscription() {
  return useQuery(
    queryKeys.userPlusSubscription(),
    async function getUserPlusSubscription() {
      try {
        const { data } = await axios.get<UserPlusSubscription>(USER_ENDPOINTS.PLUS_SUBSCRIPTION);

        return data.active;
      } catch (error) {
        return false;
      }
    },
    {
      retry: 0,
    },
  );
}
