import styled from 'styled-components';

export const CodesContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space('spacing-16')} 0 ${theme.space('spacing-12')}`};
  margin: 0 -${({ theme }) => theme.space('spacing-08')} 0 0 !important;
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 !important;
`;
