import BottomSheetContent from '@components/BottomSheet/components/BottomSheetContent';
import styled, { css } from 'styled-components';

const Overlay = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  position: fixed;
  z-index: 901;
  left: 0;
  top: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme, showOverlay }) =>
    showOverlay ? theme.color('shape-color-background-secondary') : 'transparent'};
  ${({ toggle }) =>
    toggle &&
    css`
      visibility: visible;
      opacity: 1;
      ${BottomSheetContent} {
        transition: 0.3s;
        transition-timing-function: ease-out;
        transform: translateY(0);
      }
    `}
`;

export default Overlay;
