import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'edit_personal_information.title',
    defaultMessage: 'Información personal',
  },
  personalDataLabel: {
    id: 'edit_personal_information.personal_data.label',
    defaultMessage: 'Mis datos personales',
  },
  personalDataCaptionLabel: {
    id: 'edit_personal_information.personal_data.caption',
    defaultMessage: 'Nombre, fecha de nacimiento y género',
  },
  phoneNumberLabel: {
    id: 'edit_personal_information.phone_number.label',
    defaultMessage: 'Número de celular',
  },
  phoneNumberAddButtonLabel: {
    id: 'edit_personal_information.phone_number.add_button.label',
    defaultMessage: 'Agregar',
  },
  phoneNumberEditButtonLabel: {
    id: 'edit_personal_information.phone_number.edit_button.label',
    defaultMessage: 'Editar',
  },
  phoneNumberVerifyButtonLabel: {
    id: 'edit_personal_information.phone_number.verify_button.label',
    defaultMessage: 'Validar',
  },
  emailLabel: {
    id: 'edit_personal_information.email.label',
    defaultMessage: 'E-mail',
  },
  emailEditError: {
    id: 'edit_personal_information.email.edit_error',
    defaultMessage: 'Por el momento este dato no se puede editar.',
  },
  changePasswordLabel: {
    id: 'edit_personal_information.change_password.label',
    defaultMessage: 'Cambiar contraseña',
  },
  validationsErrorResponse: {
    id: 'edit_personal_information.configuration.error_response',
    defaultMessage:
      'Ocurrió un error al querer ingresar a tu información personal. Intenta más tarde.',
  },
});

export default messages;
