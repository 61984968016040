import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useActionableTimer, { TIMER_TYPE } from '@hooks/useActionableTimer';
import { Button } from '@pedidosya/web-fenix/atoms';
import { useIntl } from '@providers/IntlProvider';
import timerLeftFormatter from './timeLeftFormatter';
import { CenterContainer } from './CodeValidatorComponents';
import { TIMER_TIME } from './../constants';
import messages from './../messages';

const noop = () => {};

const ResendCodeButton = ({ phoneValidationType, onClick, onTimeOver = noop }) => {
  const { formatMessage } = useIntl();

  const { isRunning, start, time } = useActionableTimer({
    initialTime: TIMER_TIME.TIMER_DURATION_IN_SECONDS,
    endTime: TIMER_TIME.END_TIME_IN_SECONDS,
    timerType: TIMER_TYPE.DECREMENTAL,
    onTimeOver,
  });

  useEffect(() => {
    start();
  }, [phoneValidationType]);

  const countLabel = isRunning
    ? formatMessage(messages.countDownLabel, {
        countDown: timerLeftFormatter(time * 1000),
      })
    : formatMessage(messages.sendCodeAgain);

  const handleClick = () => {
    if (!isRunning) {
      onClick();
      start();
    }
  };

  return (
    <CenterContainer>
      <Button
        label={countLabel}
        hierarchy="tertiary"
        size="medium"
        state={isRunning ? 'disabled' : 'enabled'}
        disabled={isRunning}
        onClick={handleClick}
      />
    </CenterContainer>
  );
};

ResendCodeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  customLabel: PropTypes.string,
  onTimeOver: PropTypes.func,
};

export default ResendCodeButton;
