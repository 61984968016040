import * as React from 'react';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { format } from '@pedidosya/currency-formatter';
import VendorItem from '@pedidosya/web-fenix/businessComponents/VendorItem';
import { useIntl } from '@providers/IntlProvider';
import Vendor from '../model';
import messages from '../messages';

type IntlMessage = keyof typeof messages;

type VendorListProps = {
  vendors: Vendor[];
  onFavoritePress: (id: number) => void;
  onVendorItemPress: (vendor: Vendor) => void;
};

function VendorList({ vendors, onFavoritePress, onVendorItemPress }: VendorListProps) {
  const { country } = usePublicEnv();
  const { formatMessage } = useIntl();

  const handleToggleFavorite = (vendor: Vendor) => {
    if (vendor.isFav) {
      onFavoritePress(vendor.id);
    }
  };

  return (
    <>
      {vendors.map((vendor, i, vendors) => {
        const discountMessage =
          vendor.discount?.type in messages
            ? formatMessage(messages[vendor.discount?.type as IntlMessage], {
                amount: vendor.discount?.amount,
              })
            : '';
        const statusTitle =
          vendor.state?.label in messages
            ? formatMessage(messages[vendor.state?.label as IntlMessage], {
                time: vendor.state?.until,
              })
            : '';
        const statusTag =
          vendor.state?.tag in messages
            ? formatMessage(messages[vendor.state?.tag as IntlMessage])
            : '';
        const deliveryTotalWithDiscount =
          vendor.delivery?.price?.totalWithDiscount != null
            ? format(vendor.delivery?.price?.totalWithDiscount, country.shortName)?.price
            : '';
        const deliveryTotal =
          vendor.delivery?.price?.total != null
            ? format(vendor.delivery?.price?.total, country.shortName)?.price
            : '';

        return (
          <VendorItem
            key={vendor.id}
            vendorName={vendor.name}
            variant="header-off"
            occasion={vendor.pickup ? 'pickup' : 'delivery'}
            rating={vendor.rating?.score}
            isNew={vendor.isNew}
            renderPlusTag={vendor.isPlus && vendor.discount?.amount != null}
            deliveryETAVariant={vendor.delivery?.time?.speed?.toLowerCase() ?? 'default'}
            deliveryFeeVariant={vendor.delivery?.feeVariant?.toLowerCase() ?? 'default'}
            images={{
              logo: `${vendor.logo}`,
            }}
            labels={{
              discountLabel: discountMessage,
              mainCategoriesLabels: vendor.categories,
            }}
            delivery={{
              deliveryTime: `${vendor.delivery?.time?.delay ?? ''}`,
              deliveryFeeWithDiscount: deliveryTotalWithDiscount,
              deliveryFee: deliveryTotal,
              pickupTime: `${vendor.pickup?.time?.delay ?? ''}`,
              distance: `${vendor.pickup?.distance?.value ?? ''}`,
            }}
            status={{
              title: statusTitle,
              action: statusTag,
            }}
            isMarkedAsFavorite={vendor.isFav}
            hideDivider={i === vendors.length - 1}
            onToggleFavorite={() => handleToggleFavorite(vendor)}
            onClick={() => onVendorItemPress(vendor)}
          />
        );
      })}
    </>
  );
}

export default VendorList;
