import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isExists from 'date-fns/isExists';
import isWithinInterval from 'date-fns/isWithinInterval';
import * as React from 'react';

import InputDateMask from './InputDateMask';
import { VALIDATED_STATUS } from '../constants';

const unformatDateValue = (value: string) => {
  if (!value) return '';

  const splitValue = value.split('/');
  if (splitValue.length !== 3) return '';

  return splitValue.reverse().join('-');
};

const formatDateValue = (value: string) => {
  if (!value) return '';

  const splitValue = value.split('-');
  if (splitValue.length !== 3) return '';

  return splitValue.reverse().join('/');
};

const dateValueExists = (value: string) => {
  if (!value) return false;

  const splitValue = value.split('-');
  if (splitValue.length !== 3) return false;

  return isExists(Number(splitValue[0]), Number(splitValue[1]) - 1, Number(splitValue[2]));
};

const getDateValue = (value: string) => {
  if (value) {
    const splitValue = value.split('-');

    if (splitValue.length !== 3) return;

    return new Date(Number(splitValue[0]), Number(splitValue[1]) - 1, Number(splitValue[2]));
  }

  return;
};

const isAllowedDate = (value: string, minValue: string, maxValue: string) => {
  if (!value) return false;

  const dateValue = getDateValue(value);
  const minDateValue = getDateValue(minValue);
  const maxDateValue = getDateValue(maxValue);

  if (minDateValue && maxDateValue)
    return isWithinInterval(dateValue, {
      start: minDateValue,
      end: maxDateValue,
    });
  if (minDateValue) return isAfter(dateValue, minDateValue);
  if (maxDateValue) return isBefore(dateValue, maxDateValue);

  return true;
};

const isValidDateValue = (value: string, minValue: string, maxValue: string) =>
  dateValueExists(value) && isAllowedDate(value, minValue, maxValue);

const isBirthdateValidated = (status: string) => status === VALIDATED_STATUS;

type InputDateProps = {
  label: string;
  min?: string;
  max?: string;
  autoFocus?: boolean;
  errorMessage?: string;
  value: string;
  dateStatus: string;
  onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (event: string | React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
  onValidate: (isValid: boolean) => void;
};

function InputDate({
  label,
  min,
  max,
  autoFocus,
  errorMessage,
  value,
  dateStatus,
  onFocus,
  onChange,
  onValidate,
}: InputDateProps) {
  const handleChange = (value: string) => {
    const dateValue = unformatDateValue(value);
    if (dateValue) {
      onValidate(isValidDateValue(dateValue, min, max));
      onChange(dateValue);
    } else {
      onValidate(true);
      onChange('');
    }
  };

  return (
    <InputDateMask
      autoFocus={autoFocus}
      label={label}
      errorMessage={errorMessage}
      disabled={isBirthdateValidated(dateStatus)}
      value={value ? formatDateValue(value) : ''}
      onFocus={onFocus}
      onChange={handleChange}
    />
  );
}

export default InputDate;
