import { defineMessages } from 'react-intl';
import {
  VENDOR_CONDITIONS,
  VENDOR_DISCOUNT_TYPE,
  VENDOR_STATE_LABEL,
  VENDOR_STATE_TAG,
} from './constants';

const messages = defineMessages({
  title: {
    id: 'favorites.title',
    defaultMessage: 'Favoritos',
  },
  subtitle: {
    id: 'favorites.subtitle',
    defaultMessage: 'Pide a donde más te gusta',
  },
  descriptionDefault: {
    id: 'favorites.description.default',
    defaultMessage: '{nro} de tus locales favoritos están abiertos ahora.',
  },
  descriptionOnlyOnce: {
    id: 'favorites.description.only_once',
    defaultMessage: '1 de tus locales favoritos está abierto ahora.',
  },
  descriptionEmpty: {
    id: 'favorites.description.empty',
    defaultMessage: 'Tus locales favoritos están cerrados en este momento.',
  },
  descriptionOutOfReach: {
    id: 'favorites.description.out_of_reach',
    defaultMessage: 'Tus locales favoritos no entregan en tu ubicación actual.',
  },
  emptyStateTitle: {
    id: 'favorites.empty_state.title',
    defaultMessage: 'Aún no tienes favoritos',
  },
  emptyStateSubtitle: {
    id: 'favorites.empty_state.subtitle',
    defaultMessage: 'Agrega tus locales favoritos y encuéntralos más rápido aquí.',
  },
  emptyStateCTA: {
    id: 'favorites.empty_state.cta',
    defaultMessage: 'Ver locales',
  },
  [VENDOR_STATE_LABEL.OPEN_IN]: {
    id: 'favorites.vendor.state.label.open_in',
    defaultMessage: 'Abre a las {time} hs',
  },
  [VENDOR_STATE_LABEL.NO_DELIVERY_AVAILABLE]: {
    id: 'favorites.vendor.state.label.no_delivery_available',
    defaultMessage: 'Sin delivery disponible',
  },
  [VENDOR_STATE_LABEL.CLOSED]: {
    id: 'favorites.vendor.state.label.closed',
    defaultMessage: 'Cerrado por hoy',
  },
  [VENDOR_STATE_LABEL.TEMPORARILY_CLOSED]: {
    id: 'favorites.vendor.state.label.temporarily_closed',
    defaultMessage: 'Cerrado por ahora',
  },
  [VENDOR_STATE_LABEL.OUT_OF_REACH]: {
    id: 'favorites.vendor.state.label.out_of_reach',
    defaultMessage: 'Fuera de zona de entrega',
  },
  [VENDOR_STATE_TAG.PICKUP]: {
    id: 'favorites.vendor.state.tag.pickup',
    defaultMessage: 'Retira en el local',
  },
  [VENDOR_STATE_TAG.PRE_ORDER]: {
    id: 'favorites.vendor.state.tag.pre_order',
    defaultMessage: 'Programa tu pedido',
  },
  [VENDOR_DISCOUNT_TYPE.FIXED]: {
    id: 'favorites.vendor.discount.fixed',
    defaultMessage: '{amount} DTO',
  },
  [VENDOR_DISCOUNT_TYPE.UP_TO]: {
    id: 'favorites.vendor.discount.up_to',
    defaultMessage: 'Hasta {amount} DTO',
  },
  [VENDOR_CONDITIONS.ONLY_CASH]: {
    id: 'favorites.vendor.conditions.only_cash',
    defaultMessage: 'Sólo acepta efectivo',
  },
  [VENDOR_CONDITIONS.VENDOR_DELIVERY]: {
    id: 'favorites.vendor.conditions.vendor_delivery',
    defaultMessage: 'Delivery del local',
  },
  deleteFavoriteVendorSuccessMessage: {
    id: 'favorites.vendor.delete.success',
    defaultMessage: 'Quitaste el local de “Favoritos”.',
  },
  deleteFavoriteVendorErrorMessage: {
    id: 'favorites.vendor.delete.error',
    defaultMessage: 'No pudimos eliminar tu favorito. Reintenta en unos minutos.',
  },
  serviceFailureTitle: {
    id: 'favorites.service_failure.title',
    defaultMessage: 'Tuvimos un problema y lo estamos resolviendo',
  },
  serviceFailureSubtitle: {
    id: 'favorites.service_failure.subtitle',
    defaultMessage: 'Lo sentimos. Vuelve a intentar en unos minutos.',
  },
  serviceFailureCTA: {
    id: 'favorites.service_failure.cta',
    defaultMessage: 'Reintentar',
  },
});

export default messages;
