import ServiceResponse from '@commons/services/models/ServiceResponse';
import { PHONE_VALIDATION_ENDPOINTS } from '@services/api-gateway-urls';
import axios from 'axios';
import { useMutation } from 'react-query';

type UseSendPhoneCodeParams = {
  type: string;
  phoneNumber: string;
  userId?: number;
};

export function useSendPhoneCode() {
  return useMutation(async ({ type, phoneNumber, userId = null }: UseSendPhoneCodeParams) => {
    try {
      const { data } = await axios.post(PHONE_VALIDATION_ENDPOINTS.SEND_PHONE_CODE, {
        type,
        phone_number: phoneNumber,
        user_id: userId,
      });

      return new ServiceResponse({ data });
    } catch (error) {
      return new ServiceResponse({ error });
    }
  });
}
