import styled from 'styled-components';

const CloseContainer = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
`;

export default CloseContainer;
