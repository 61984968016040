import * as React from 'react';
import styled from 'styled-components';
import { Subtitle, Text } from '@components/Fenix/Typography';
import { useIntl } from '@providers/IntlProvider';
import Vendor, { VendorStateStatus } from '../model';
import messages from '../messages';

const SectionContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.space('spacing-06')} ${theme.space('spacing-08')} ${theme.space('spacing-04')}`};
  margin-bottom: ${({ theme }) => theme.space('spacing-08')};
`;

type SectionProps = {
  vendorsAvailable: Vendor[];
  vendorsUnavailable: Vendor[];
};

function SubtitleSection({ vendorsAvailable, vendorsUnavailable }: SectionProps) {
  const { formatMessage } = useIntl();

  const getDescription = () => {
    if (
      vendorsAvailable.length === 0 &&
      vendorsUnavailable.length > 0 &&
      vendorsUnavailable.filter((v) => v.state?.status !== VendorStateStatus.OUT_OF_REACH)
        .length === 0
    ) {
      return messages.descriptionOutOfReach;
    }
    if (vendorsAvailable.length === 0) return messages.descriptionEmpty;
    if (vendorsAvailable.length === 1) return messages.descriptionOnlyOnce;
    return messages.descriptionDefault;
  };

  return (
    <SectionContainer>
      <Subtitle>{formatMessage(messages.subtitle)}</Subtitle>
      <Text color="tertiary" size="small">
        {formatMessage(getDescription(), { nro: vendorsAvailable.length })}
      </Text>
    </SectionContainer>
  );
}

export default SubtitleSection;
