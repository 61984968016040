import type { Tracker } from '@app/types';
import * as React from 'react';

const noop = {
  track: (event: string, data?: unknown) =>
    console.log(`[NO_TRACKER_IMPLEMENTATION] e: ${event}, data: ${JSON.stringify(data)}`),
};

const TrackContext = React.createContext<Tracker>(undefined as Tracker);
TrackContext.displayName = 'TrackContext';

type TrackProviderProperties = {
  children: React.ReactNode;
  tracker: Tracker;
};

function TrackerProvider({ children, tracker = noop }: TrackProviderProperties): JSX.Element {
  const value = React.useMemo(() => tracker, [tracker]);

  return <TrackContext.Provider value={value}>{children}</TrackContext.Provider>;
}

function useTracker(): Tracker {
  const context = React.useContext(TrackContext);
  if (context === undefined) {
    throw new Error('Tracker must be used within a TrackerProvider');
  }

  return context;
}

export { TrackerProvider, useTracker };
