import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'personal_data.title',
    defaultMessage: 'Mis datos personales',
  },
  nameLabel: {
    id: 'personal_data.name.label',
    defaultMessage: '¿Cómo te llamas?',
  },
  firstNameInputLabel: {
    id: 'personal_data.first_name.input_label',
    defaultMessage: 'Nombre (s)',
  },
  lastNameInputLabel: {
    id: 'personal_data.last_name.input_label',
    defaultMessage: 'Apellido (s)',
  },
  namingRequiredError: {
    id: 'personal_data.naming.error.required',
    defaultMessage: 'Es necesario que completes este dato.',
  },
  namingOnlyLettersError: {
    id: 'personal_data.naming.error.only_letters',
    defaultMessage: 'No puedes usar números ni caracteres especiales.',
  },
  namingMaxLengthError: {
    id: 'personal_data.names.error.max_length',
    defaultMessage: 'Alcanzaste el límite de caracteres.',
  },
  nicknameInputLabel: {
    id: 'personal_data.nickname.input_label',
    defaultMessage: 'Apodo (opcional)',
  },
  birthdayLabel: {
    id: 'personal_data.birthday.label',
    defaultMessage: '¿Cuándo naciste?',
  },
  birthdayInputLabel: {
    id: 'personal_data.birthday.input_label',
    defaultMessage: 'DD/MM/AAAA',
  },
  genderLabel: {
    id: 'personal_data.gender.label',
    defaultMessage: '¿Con qué género te identificas?',
  },
  save: {
    id: 'personal_data.save',
    defaultMessage: 'Guardar cambios',
  },
  errorMessageInvalidDate: {
    id: 'personal_data.error_message.invalid_date',
    defaultMessage: 'Ingresa una fecha válida.',
  },
  errorMessageMinimumLength: {
    id: 'personal_data.error_message.minimum_length',
    defaultMessage: 'Escribe al menos 1 letra.',
  },
  personalDataErrorResponse: {
    id: 'personal_data.ui_configuration.error_response',
    defaultMessage:
      'Ocurrió un error al querer ingresar a tus datos personales. Intenta más tarde.',
  },
  saveErrorResponse: {
    id: 'personal_data.save.error_response',
    defaultMessage: 'No pudimos guardar tus datos. Reintenta en unos minutos.',
  },
  saveSuccessResponse: {
    id: 'personal_data.save.success_response',
    defaultMessage: 'Guardaste tus datos.',
  },
  saveSuccessResponseFromHome: {
    id: 'personal_data.save.success_response_from_home',
    defaultMessage: 'Guardaste tus datos. Volver al inicio',
  },
});

export default messages;
