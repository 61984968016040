import { TrackingActions, TrackingEvents } from '@app/tracking';
import { UserPersonalData } from '@app/types';
import { UPDATE_PERSONAL_DATA_ERROR_CODES } from '@commons/services/constants/ErrorCodes';
import { HttpApiError } from '@commons/services/models/ServiceResponse';
import { Button, Chip, Input } from '@pedidosya/web-fenix/atoms';
import fenixTheme from '@pedidosya/web-fenix/theme';
import { Text } from '@components/Fenix/Typography';
import Form, { FormBody, FormFooter } from '@components/Form';
import { Layout } from '@components/Layout';
import OverlayLoader from '@components/OverlayLoader';
import { PageTitle } from '@components/Shell';
import { useMe } from '@hooks/use-me';
import { useUserPersonalData } from '@hooks/use-personal-data';
import { useUpdateUserPersonalData } from '@hooks/use-update-user-personal-data';
import { useDevice } from '@providers/DeviceProvider';
import { useIntl } from '@providers/IntlProvider';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';
import { FormikProps, FormikErrors, useFormik } from 'formik';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import InputDate from './components/InputDate';
import {
  ONLY_LETTERS_REGEX,
  OTHER_GENDER_ID,
  RULE_ERROR_MESSAGES,
  MAX_NAME_LENGTH,
  TRACKING_CHANGES_BIRTHDAY,
  TRACKING_CHANGES_FIRST_NAME,
  TRACKING_CHANGES_GENDER,
  TRACKING_CHANGES_LAST_NAME,
  TRACKING_CHANGES_NICK_NAME,
  TRACKING_CLICK_LOCATION_BACK,
  TRACKING_CLICK_LOCATION_BIRTHDAY_START,
  TRACKING_CLICK_LOCATION_FIRST_NAME,
  TRACKING_CLICK_LOCATION_GENDER,
  TRACKING_CLICK_LOCATION_GENDER_CUSTOM,
  TRACKING_CLICK_LOCATION_LAST_NAME,
  TRACKING_CLICK_LOCATION_NICK_NAME,
  TRACKING_ERR_MSG_UNKNOWN,
  TRACKING_ORIGIN_MY_ACCOUNT,
  TRACKING_SECTION_BIRTHDAY,
  TRACKING_SECTION_FIRST_NAME,
  TRACKING_SECTION_GENDER,
  TRACKING_SECTION_LAST_NAME,
  TRACKING_SECTION_NICK_NAME,
} from './constants';
import messages from './messages';
import { PersonalDataSkeleton } from './skeleton';

const Container = styled.div``;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ChipsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
`;

type IntlMessage = keyof typeof messages;
type RuleErrorMessage = keyof typeof RULE_ERROR_MESSAGES;

type PersonalDataFormValues = {
  firstName: string;
  lastName: string;
  nickname: string;
  birthday: string;
  gender: string;
  other_gender: string;
};

const isOtherGenderId = (genderId: string) => genderId === OTHER_GENDER_ID;

const getTrackingErrorMessage = (error: HttpApiError) =>
  error?.code || error.message || TRACKING_ERR_MSG_UNKNOWN;

const getChangedFields = (
  initialValues: PersonalDataFormValues,
  currentValues: PersonalDataFormValues,
) => {
  const hasFirstNameChanged = initialValues.firstName !== currentValues.firstName;
  const hasLastNameChanged = initialValues.lastName !== currentValues.lastName;
  const hasNickNameChanged = initialValues.nickname !== currentValues.nickname;
  const hasBirthdayChanged = initialValues.birthday !== currentValues.birthday;
  const hasGenderChanged = initialValues.gender !== currentValues.gender;

  const changedFields = [];
  hasFirstNameChanged && changedFields.push(TRACKING_CHANGES_FIRST_NAME);
  hasLastNameChanged && changedFields.push(TRACKING_CHANGES_LAST_NAME);
  hasNickNameChanged && changedFields.push(TRACKING_CHANGES_NICK_NAME);
  hasBirthdayChanged && changedFields.push(TRACKING_CHANGES_BIRTHDAY);
  hasGenderChanged && changedFields.push(TRACKING_CHANGES_GENDER);

  return changedFields.length > 0 ? changedFields.join('-') : '';
};

const getOtherGenderRuleErrorMessage = (
  personalData: UserPersonalData,
  otherGenderValue: string,
) => {
  let failedRuleId = '';

  for (const matchRule of personalData.gender.matchRules.other) {
    if (!failedRuleId) {
      const rule = personalData.rules.find((rule) => rule.ruleId === matchRule);

      if (rule) {
        const rgx = new RegExp(rule.regex);
        failedRuleId = !rgx.test(otherGenderValue.trimStart()) ? rule.ruleId : '';
      }
    }
  }

  return failedRuleId && RULE_ERROR_MESSAGES.hasOwnProperty(failedRuleId)
    ? RULE_ERROR_MESSAGES[failedRuleId as RuleErrorMessage]
    : undefined;
};

function PersonalData(): JSX.Element {
  const { isDesktop } = useDevice();
  const navigate = useNavigate();
  const toast = useToast();
  const tracker = useTracker();
  const { formatMessage } = useIntl();
  const { data: user, isLoading: isLoadingMe } = useMe();
  const {
    data: personalData,
    error: personalDataError,
    isLoading: isLoadingPersonalData,
  } = useUserPersonalData();
  const { mutateAsync: updatePersonalData, isLoading: isLoadingUpdatePersonalData } =
    useUpdateUserPersonalData();
  const [formErrors, setFormErrors] = React.useState({});

  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== 'default';

  const goBack = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const validateFormOnBlur = (values: PersonalDataFormValues) => {
    const errors: FormikErrors<PersonalDataFormValues> = formErrors;

    const validateField = ({
      fieldName,
      isMandatory = false,
    }: {
      fieldName: keyof PersonalDataFormValues;
      isMandatory?: boolean;
    }) => {
      if (isMandatory && !values[fieldName]) {
        errors[fieldName] = formatMessage(messages.namingRequiredError);
      } else if (values[fieldName] && !ONLY_LETTERS_REGEX.test(values[fieldName])) {
        errors[fieldName] = formatMessage(messages.namingOnlyLettersError);
      } else {
        delete errors[fieldName];
      }
    };

    validateField({ fieldName: 'firstName', isMandatory: true });
    validateField({ fieldName: 'lastName', isMandatory: true });
    validateField({ fieldName: 'nickname' });

    if (personalData && isOtherGenderId(values.gender)) {
      const otherGenderRuleErrorMessage = getOtherGenderRuleErrorMessage(
        personalData,
        values.other_gender,
      );

      if (otherGenderRuleErrorMessage) {
        errors.other_gender = formatMessage(messages[otherGenderRuleErrorMessage as IntlMessage]);
      }
    }

    setFormErrors(errors);
    return errors;
  };

  const validateFormOnChange = (
    values: PersonalDataFormValues,
    fieldName?: keyof PersonalDataFormValues,
  ) => {
    const errors: FormikErrors<PersonalDataFormValues> = {};

    if (values[fieldName].length >= MAX_NAME_LENGTH) {
      errors[fieldName] = formatMessage(messages.namingMaxLengthError);
    } else {
      delete errors[fieldName];
    }
    return { ...formErrors, ...errors };
  };

  const formik: FormikProps<PersonalDataFormValues> = useFormik<PersonalDataFormValues>({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.name || '',
      lastName: user?.lastName || '',
      nickname: user?.nickname || '',
      birthday: personalData?.birthday.value || '',
      gender: personalData?.gender.value?.genderId || '',
      other_gender: personalData?.gender.value?.additionalInformation?.value || '',
    },
    onSubmit: async (values, formikHelpers) => {
      const data = {
        ...values,
        birthday: values.birthday || null,
        gender: values.gender
          ? {
              id: values.gender,
              description: isOtherGenderId(values.gender) ? values.other_gender : null,
            }
          : null,
      };

      const changedFiles = getChangedFields(formik.initialValues, values);

      const { error, success } = await updatePersonalData(data);

      if (success) {
        tracker.track(TrackingEvents.MyAccountUpdated, {
          action: TrackingActions.ChangeUsername,
        });
        tracker.track(TrackingEvents.MyPersonalInfoUpdateCompleted, {
          origin: TRACKING_ORIGIN_MY_ACCOUNT,
          changes: changedFiles,
        });
        toast.success(formatMessage(messages.saveSuccessResponse));
        if (doesAnyHistoryEntryExist) {
          goBack();
        }
      }

      if (error) {
        tracker.track(TrackingEvents.MyAccountUpdateFailed, {
          action: TrackingActions.ChangeUsername,
          errorMessage: getTrackingErrorMessage(error),
        });
        tracker.track(TrackingEvents.MyPersonalInfoUpdateFailed, {
          origin: TRACKING_ORIGIN_MY_ACCOUNT,
          changes: changedFiles,
          errorMessage: getTrackingErrorMessage(error),
        });
        if (error?.code === UPDATE_PERSONAL_DATA_ERROR_CODES.INVALID_PARAMS) {
          formikHelpers.setErrors(validateFormOnBlur(values));
        } else {
          toast.error(formatMessage(messages.saveErrorResponse));
        }
      }
    },
    validateOnChange: false,
    validate: (values) => validateFormOnBlur(values),
  });

  React.useEffect(() => {
    if (personalDataError) {
      tracker.track(TrackingEvents.MyPersonalInfoUpdateAppFailed, {
        errorMessage: getTrackingErrorMessage(personalDataError),
      });
      toast.error(formatMessage(messages.personalDataErrorResponse));
      goBack();
    } else if (personalData) {
      tracker.track(TrackingEvents.MyPersonalInfoUpdateLoaded, {
        origin: TRACKING_ORIGIN_MY_ACCOUNT,
      });
    }
  }, [personalData, personalDataError]);

  React.useEffect(() => {
    if (!isDesktop) {
      document.body.style.backgroundColor = fenixTheme.color('shape-color-background-primary');
    }

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [isDesktop]);

  const handleTrackClicked = (section: string, clickLocation: string) => {
    tracker.track(TrackingEvents.MyPersonalInfoUpdateClicked, {
      origin: TRACKING_ORIGIN_MY_ACCOUNT,
      section,
      clickLocation,
    });
  };

  const handleGoBackPress = () => {
    handleTrackClicked('', TRACKING_CLICK_LOCATION_BACK);
    goBack();
  };

  const handleInputDateValidate = async (isValid: boolean) => {
    await formik.setFieldTouched('birthday', true, true);
    formik.setFieldError(
      'birthday',
      !isValid ? formatMessage(messages.errorMessageInvalidDate) : undefined,
    );
  };

  const handleChipClick = async (genderId: string) => {
    handleTrackClicked(
      TRACKING_SECTION_GENDER,
      isOtherGenderId(genderId)
        ? TRACKING_CLICK_LOCATION_GENDER_CUSTOM
        : TRACKING_CLICK_LOCATION_GENDER,
    );
    await formik.setFieldValue('gender', formik.values.gender === genderId ? '' : genderId);
    (document.activeElement as HTMLElement)?.blur();
  };

  const handleNamesChange = (fieldName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_NAME_LENGTH) formik.setFieldValue(fieldName, newValue);

    const updatedValues = { ...formik.values, [fieldName]: newValue };
    formik.setErrors(
      validateFormOnChange(updatedValues, fieldName as keyof PersonalDataFormValues),
    );
  };

  const isButtonDisabled = !formik.dirty || !formik.isValid || formik.isSubmitting;

  if (personalDataError) {
    return <></>;
  }

  if (isLoadingMe || isLoadingPersonalData) {
    return (
      <Layout>
        <PageTitle title={formatMessage(messages.title)} onBack={handleGoBackPress} />
        {isDesktop ? <OverlayLoader /> : <PersonalDataSkeleton />}
      </Layout>
    );
  }

  return (
    <Layout isLoading={isLoadingUpdatePersonalData}>
      <PageTitle title={formatMessage(messages.title)} onBack={handleGoBackPress} />
      <Form onSubmit={formik.handleSubmit}>
        <FormBody>
          <Container>
            <Text size="medium" color="action-enabled-loud" style={{ padding: '16px 0' }}>
              {formatMessage(messages.nameLabel)}
            </Text>
            <InputsContainer>
              <Input
                autoFocus
                name="firstName"
                aria-label={formatMessage(messages.firstNameInputLabel)}
                label={formatMessage(messages.firstNameInputLabel)}
                isError={!!formik.errors.firstName}
                errorMessage={formik.errors.firstName || ''}
                value={formik.values.firstName}
                onFocus={() =>
                  handleTrackClicked(
                    TRACKING_SECTION_FIRST_NAME,
                    TRACKING_CLICK_LOCATION_FIRST_NAME,
                  )
                }
                onChange={handleNamesChange('firstName')}
                onBlur={formik.handleBlur}
                isMandatory
              />
              <Input
                aria-label={formatMessage(messages.lastNameInputLabel)}
                name="lastName"
                label={formatMessage(messages.lastNameInputLabel)}
                isError={!!formik.errors.lastName}
                errorMessage={formik.errors.lastName || ''}
                value={formik.values.lastName}
                onFocus={() =>
                  handleTrackClicked(TRACKING_SECTION_LAST_NAME, TRACKING_CLICK_LOCATION_LAST_NAME)
                }
                onChange={handleNamesChange('lastName')}
                onBlur={formik.handleBlur}
                isMandatory
              />
              <Input
                aria-label={formatMessage(messages.nicknameInputLabel)}
                name="nickname"
                label={formatMessage(messages.nicknameInputLabel)}
                isError={!!formik.errors.nickname}
                errorMessage={formik.errors.nickname || ''}
                value={formik.values.nickname}
                onFocus={() =>
                  handleTrackClicked(TRACKING_SECTION_NICK_NAME, TRACKING_CLICK_LOCATION_NICK_NAME)
                }
                onChange={handleNamesChange('nickname')}
                onBlur={formik.handleBlur}
              />
            </InputsContainer>
          </Container>
          <Container>
            <Text size="medium" color="action-enabled-loud" style={{ padding: '16px 0' }}>
              {formatMessage(messages.birthdayLabel)}
            </Text>
            <InputDate
              label={formatMessage(messages.birthdayInputLabel)}
              min={personalData.birthday.minimumDate}
              max={personalData.birthday.maximumDate}
              errorMessage={formik.errors.birthday || ''}
              value={formik.values.birthday}
              dateStatus={personalData.birthday.status}
              onFocus={() =>
                handleTrackClicked(
                  TRACKING_SECTION_BIRTHDAY,
                  TRACKING_CLICK_LOCATION_BIRTHDAY_START,
                )
              }
              onChange={formik.handleChange('birthday')}
              onValidate={(isValid) => handleInputDateValidate(isValid)}
            />
          </Container>
          <Container>
            <Text size="medium" color="action-enabled-loud" style={{ padding: '16px 0' }}>
              {formatMessage(messages.genderLabel)}
            </Text>
            <ChipsContainer>
              {personalData.gender.catalog.map((gender) => (
                <Chip
                  key={gender.genderId}
                  aria-label={gender.description}
                  label={gender.description}
                  selected={formik.values.gender === gender.genderId}
                  onClick={() => handleChipClick(gender.genderId)}
                />
              ))}
            </ChipsContainer>
          </Container>
        </FormBody>
        <FormFooter>
          <Button
            label={formatMessage(messages.save)}
            size="large"
            type="submit"
            state={isButtonDisabled ? 'disabled' : 'enabled'}
            disabled={isButtonDisabled}
            fullWidth
          />
        </FormFooter>
      </Form>
    </Layout>
  );
}

export { PersonalData };
