import { usePublicEnv } from '@app/contexts/PublicEnv';
import { GlobalRoutePaths } from '@app/routes';
import { TrackingEvents, TrackingOrigins } from '@app/tracking';
import { Location, PublicEnvironment } from '@app/types';
import heart from '@assets/icons/heart.svg';
import format from '@commons/utils/string/format';
import { Label, Title } from '@components/Fenix/Typography';
import { FormFooter } from '@components/Form';
import { Button } from '@pedidosya/web-fenix/atoms';
import { useIntl } from '@providers/IntlProvider';
import { useTracker } from '@providers/TrackerProvider';

import * as React from 'react';
import styled from 'styled-components';

import { TrackingFavoriteAction } from './constants';
import messages from './messages';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  max-width: 272px;
  margin: auto;
  text-align: center;
  padding-bottom: 60px;
  height: calc(100vh - 244px);
`;

const Image = styled.img``;

type EmptyProps = {
  onButtonClick: () => void;
};

function Empty({ onButtonClick }: EmptyProps): JSX.Element {
  const { location }: PublicEnvironment = usePublicEnv();
  const { formatMessage } = useIntl();
  const tracker = useTracker();

  const goToShopList = ({ latitude, longitude }: Location) =>
    window.location.assign(format(GlobalRoutePaths.ShopList, latitude, longitude));
  const goToHome = () => window.location.assign(GlobalRoutePaths.Home);

  const handleButtonClick = () => {
    tracker.track(TrackingEvents.FavoritesClicked, {
      favoriteAction: TrackingFavoriteAction.EmptySearchPartners,
      favoriteOrigin: TrackingOrigins.Profile,
    });
    onButtonClick();
    location?.latitude && location?.longitude ? goToShopList(location) : goToHome();
  };

  return (
    <>
      <Body>
        <Image src={heart} alt="Favoritos" />
        <Title size="small">{formatMessage(messages.emptyStateTitle)}</Title>
        <Label size="medium" color="secondary">
          {formatMessage(messages.emptyStateSubtitle)}
        </Label>
      </Body>
      <FormFooter>
        <Button
          label={formatMessage(messages.emptyStateCTA)}
          size="large"
          type="submit"
          fullWidth
          onClick={handleButtonClick}
        />
      </FormFooter>
    </>
  );
}

export { Empty };
