export const CODES_LENGTH = 4;
export const TIMER_TIME = {
  ONE_MINUTE_IN_SECONDS: 60,
  TIMER_DURATION_IN_SECONDS: 20,
  END_TIME_IN_SECONDS: -1,
  SECONDS_LEFT_TO_DISPLAY_HELP_INDICATOR: 5,
};
export const KEYS = {
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
};
