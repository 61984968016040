import { Title } from '@components/Fenix/Typography';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import { useDevice } from '@providers/DeviceProvider';
import * as React from 'react';
import styled from 'styled-components';
import { MobileHeader } from './MobileHeader';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: ${({ theme }) =>
    `${theme.space('spacing-16')} ${theme.space('spacing-08')} ${theme.space('spacing-11')}`};
`;

const BackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
`;

type PageTitleProps = {
  title?: string;
  showBack?: boolean;
  withMobileMarginBottom?: boolean;
  onBack?: () => void;
};

const DesktopTitle = ({
  title = '',
  showBack = true,
  onBack = () => {},
}: PageTitleProps): JSX.Element => (
  <Container>
    {showBack && (
      <BackContainer data-testid="back-button">
        <FenixIcon
          token="chevron-left"
          size="small"
          color="default"
          style={{ outline: 'none' }}
          onClick={onBack}
        />
      </BackContainer>
    )}
    {!!title && <Title>{title}</Title>}
  </Container>
);

export const PageTitle = ({
  title,
  showBack,
  withMobileMarginBottom = true,
  onBack,
}: PageTitleProps): JSX.Element => {
  const { isDesktop } = useDevice();

  return (
    <React.Fragment>
      {isDesktop ? (
        <DesktopTitle title={title} showBack={showBack} onBack={onBack} />
      ) : (
        <MobileHeader
          title={title}
          showBack={showBack}
          onBack={onBack}
          withMarginBottom={withMobileMarginBottom}
        />
      )}
    </React.Fragment>
  );
};
