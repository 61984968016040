import { defineMessages } from 'react-intl';

const messages = defineMessages({
  forgotPassword: {
    id: 'change_password.forgot_password',
    defaultMessage: 'Olvidé mi contraseña',
  },
  accept: {
    id: 'change_password.accept',
    defaultMessage: 'Cambiar contraseña',
  },
  newPasswordLabel: {
    id: 'change_password.new_password.label',
    defaultMessage: 'Contraseña nueva',
  },
  oldPasswordLabel: {
    id: 'change_password.old_password.label',
    defaultMessage: 'Contraseña actual',
  },
  passwordRulesErrorResponse: {
    id: 'change_password.password_rules.error_response',
    defaultMessage: 'Lo sentimos, tuvimos un problema. Reintenta más tarde.',
  },
  saveErrorResponse: {
    id: 'change_password.save.error_response',
    defaultMessage: 'Lo sentimos, tuvimos un problema. Reintenta en unos minutos.',
  },
  saveSuccessResponse: {
    id: 'change_password.save.success_response',
    defaultMessage: '¡Listo! Cambiaste tu contraseña.',
  },
  title: {
    id: 'change_password.title',
    defaultMessage: 'Cambiar contraseña',
  },
  oldPasswordErrorMessage: {
    id: 'change_password.old_password.error_message',
    defaultMessage: 'Contraseña incorrecta',
  },
  newPasswordSuccessMessage: {
    id: 'change_password.new_password.success_message',
    defaultMessage: '¡Buena elección!',
  },
});

export default messages;
