import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { GlobalRoutePaths } from '@app/routes';
import BoxMessage from '@pedidosya/web-fenix/molecules/BoxMessage';
import { useIntl } from '@providers/IntlProvider';
import messages from './../messages';

const HelpTooltip = ({ show }) => {
  const { formatMessage } = useIntl();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setShowTooltip(show);
  }, [show]);

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const redirectToHelp = async () => {
    window.location.href = GlobalRoutePaths.CustomerService;
  };

  return (
    <>
      {showTooltip && (
        <BoxMessage
          style={{ marginBottom: '8px' }}
          actionLabel={formatMessage(messages.tooltipContentButton)}
          message={formatMessage(messages.tooltipContentSubtitle)}
          onClickAction={redirectToHelp}
          onClose={handleTooltipClose}
          title={formatMessage(messages.tooltipContentTitle)}
          type="informative"
          variant="quiet"
        />
      )}
    </>
  );
};

HelpTooltip.propTypes = {
  show: PropTypes.bool,
};

HelpTooltip.defaultProps = {
  show: false,
};

export default HelpTooltip;
