import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CodeInput from '@pedidosya/web-fenix/molecules/CodeInput';
import { CodesContainer } from './CodeValidatorComponents';
import { KEYS } from '../constants';
import Digit from './Digit';

const Codes = ({ length, onCompleted, showCodeInputError, setCodeInputError }) => {
  const [digits, setDigits] = useState(Array(length).fill(''));
  const [currentInputIndex, setCurrentInputIndex] = useState(0);

  const updateDigitAt = ({ digit, index }) => {
    setDigits((previousDigits) => {
      const newDigits = [...previousDigits];
      newDigits[index] = digit;

      const code = newDigits.join('');
      if (code.length === length) {
        onCompleted(code);
      }

      return newDigits;
    });
  };

  const updateAndFocus = ({ digit, index }) => {
    updateDigitAt({ digit, index });
    focusNextInput();
  };

  const focusInput = (index) => {
    const lowerInputIndex = Math.min(length - 1, index);
    setCurrentInputIndex(Math.max(lowerInputIndex, 0));
  };

  const focusPreviousInput = () => focusInput(currentInputIndex - 1);

  const focusNextInput = () => focusInput(currentInputIndex + 1);

  const handleInputKeyDown = (index) => (event) => {
    if (event.key === KEYS.BACKSPACE) {
      event.preventDefault();
      updateDigitAt({ digit: '', index });
      focusPreviousInput();
    } else if (event.key === KEYS.DELETE) {
      event.preventDefault();
      updateDigitAt({ digit: '', index });
    }
  };

  return (
    <CodesContainer>
      <CodeInput
        data-testid="code-input"
        errorMessage={showCodeInputError}
        state={showCodeInputError ? 'error' : 'enabled'}
      >
        {digits.map((code, index) => (
          <Digit
            key={index}
            current={index}
            code={code}
            onFocus={focusInput}
            onKeyDown={handleInputKeyDown}
            onChange={updateAndFocus}
            currentInput={currentInputIndex}
            showCodeInputError={showCodeInputError}
            setCodeInputError={setCodeInputError}
          />
        ))}
      </CodeInput>
    </CodesContainer>
  );
};

Codes.propTypes = {
  length: PropTypes.number.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

export default Codes;
