import Vendor from '@app/pages/Favorites/model';
import ServiceResponse from '@commons/services/models/ServiceResponse';
import { USER_ENDPOINTS } from '@services/api-gateway-urls';
import * as queryKeys from '@utils/query-key-factory';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

type DeleteFavoriteVendorRequest = {
  id: number;
};

export function useDeleteFavoriteVendor() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id }: DeleteFavoriteVendorRequest) => {
      try {
        await axios.delete(USER_ENDPOINTS.FAVORITE_VENDORS, {
          params: { vendorId: id },
        });

        return new ServiceResponse({ success: true, data: id });
      } catch (error) {
        return new ServiceResponse({ error });
      }
    },
    {
      onSuccess: (resp: ServiceResponse<number>) => {
        const { data: id } = resp;
        const queryKey = queryKeys.userFavoriteVendors();
        const favoriteVendors = queryClient.getQueryData<Vendor[]>(queryKey);
        queryClient.setQueryData(
          queryKey,
          favoriteVendors.filter((v) => v.id !== id),
        );
      },
    },
  );
}
