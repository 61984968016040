import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import BottomSheetBody from '@components/BottomSheet/components/BottomSheetBody';
import BottomSheetContent from '@components/BottomSheet/components/BottomSheetContent';
import CloseContainer from '@components/BottomSheet/components/CloseContainer';
import Handler from '@components/BottomSheet/components/Handler';
import Overlay from '@components/BottomSheet/components/Overlay';
import TitleContainer from '@components/BottomSheet/components/TitleContainer';
import { Text } from '@components/Fenix/Typography';
import useOnClickOutside from '@hooks/useOnClickOutside';
import PropTypes from 'prop-types';
import * as React from 'react';

const BottomSheet = ({ children, title, open, onCancel, onClickOut, showOverlay }) => {
  const bottomSheetRef = React.useRef(null);
  const closeBottomSheet = React.useCallback(() => {
    onClickOut(false);
  }, [onClickOut]);
  useOnClickOutside(bottomSheetRef, closeBottomSheet);

  return (
    <Overlay toggle={open} showOverlay={showOverlay}>
      <BottomSheetContent ref={bottomSheetRef}>
        <Handler />
        <TitleContainer>
          <Text size="large">{title}</Text>
          <CloseContainer aria-label="BottomSheetCloseButton" role="button" onClick={onCancel}>
            <FenixIcon token="close" color="icon-color-primary" size="medium" />
          </CloseContainer>
        </TitleContainer>
        <BottomSheetBody>{children}</BottomSheetBody>
      </BottomSheetContent>
    </Overlay>
  );
};

BottomSheet.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onClickOut: PropTypes.func,
  showOverlay: PropTypes.bool,
};

BottomSheet.defaultProps = {
  onCancel: () => {},
  onClickOut: () => {},
  showOverlay: true,
};

BottomSheet.displayName = 'BottomSheet';

export default BottomSheet;
