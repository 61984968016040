import { useWindow } from '@providers/WindowProvider';
import * as React from 'react';

function useOffline({ reloadOnOnline = true } = {}) {
  const windowObject = useWindow();
  const [offline, setOffline] = React.useState(() =>
    windowObject ? !windowObject.navigator.onLine : false,
  );

  React.useEffect(() => {
    if (windowObject) setOffline(!windowObject.navigator.onLine);
  }, [windowObject]);

  React.useEffect(() => {
    if (!windowObject) {
      return;
    }

    const setOnlineStatus = () => {
      if (reloadOnOnline) {
        windowObject.location.reload();
      } else {
        setOffline(false);
      }
    };
    const setOfflineStatus = () => setOffline(true);

    windowObject.addEventListener('online', setOnlineStatus);
    windowObject.addEventListener('offline', setOfflineStatus);

    return () => {
      windowObject.removeEventListener('online', setOnlineStatus);
      windowObject.removeEventListener('offline', setOfflineStatus);
    };
  }, [reloadOnOnline, windowObject]);

  return offline;
}

export default useOffline;
