import { TrackingActions, TrackingEvents } from '@app/tracking';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import defaultAvatarPNG from '@assets/default-avatar.png';
import { useUpdateUserAvatar } from '@hooks/use-update-user-avatar';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';
import * as React from 'react';

import DropDownOption from './DropDownOption';

type DeletePhotoItemProps = {
  label: string;
  successMessage: string;
  errorMessage: string;
  onLoading: (value: boolean) => void;
  onSuccess: () => void;
  onError: () => void;
  onClick: () => void;
};

function DeletePhotoItem({
  label,
  successMessage,
  errorMessage,
  onLoading,
  onSuccess,
  onError,
  onClick,
}: DeletePhotoItemProps) {
  const tracker = useTracker();
  const toast = useToast();
  const { mutateAsync: updateUserAvatarAsync, isLoading: isUpdateUserAvatarLoading } =
    useUpdateUserAvatar();

  React.useEffect(() => {
    onLoading(isUpdateUserAvatarLoading);
  }, [onLoading, isUpdateUserAvatarLoading]);

  const deleteAvatar = async (): Promise<void> => {
    try {
      const avatar = defaultAvatarPNG.split(',').pop();
      await updateUserAvatarAsync({ avatar });
      tracker.track(TrackingEvents.MyAccountUpdated, {
        action: TrackingActions.DeletePicture,
      });
      toast.success(successMessage);
      onSuccess();
    } catch (error) {
      tracker.track(TrackingEvents.MyAccountUpdateFailed, {
        action: TrackingActions.DeletePicture,
        errorMessage: error?.code || error.message,
      });
      toast.error(errorMessage);
      onError();
    }
  };

  const handleClick = () => {
    onClick();
    deleteAvatar();
  };

  return (
    <DropDownOption
      label={label}
      icon={<FenixIcon token="delete" color="icon-color-feedback-error" size="small" />}
      state="error"
      onClick={handleClick}
    />
  );
}

export default DeletePhotoItem;
