import React from 'react';
import styled from 'styled-components';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color('shape-color-background-secondary')};
  z-index: 1001;
`;

const LoaderContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  border-radius: ${({ theme }) => theme.borderRadius('border-radius-default')};
  /* box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space('spacing-component-xlarge')};
  text-align: center;
`;

const OverlayLoader = () => (
  <Overlay>
    <LoaderContainer>
      <PeyaLoader />
    </LoaderContainer>
  </Overlay>
);

export default OverlayLoader;
