import Error from '@pedidosya/web-fenix/pages/Error';
import { useIntl } from '@providers/IntlProvider';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { useNavigate } from 'react-router';

const messages = defineMessages({
  title: {
    id: 'offline.title',
    defaultMessage: 'Parece que no hay Internet',
  },
  description: {
    id: 'offline.description',
    defaultMessage: 'Asegúrate de que tu conexión funcione correctamente.',
  },
  cta: {
    id: 'offline.cta',
    defaultMessage: 'Reintentar',
  },
});

function Offline(): JSX.Element {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <Error
      errorCode={504}
      title={formatMessage(messages.title)}
      message={formatMessage(messages.description)}
      primaryActionLabel={formatMessage(messages.cta)}
      primaryActionClick={() => navigate(0)}
    />
  );
}

export { Offline };
