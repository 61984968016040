export const MIN_PHONE_LENGTH = 5;
export const BUTTONS_ORDER = {
  FIRST_ORDER: 0,
  SECOND_ORDER: 1,
};

export const DEFAULT_SEND_CHANNELS = [
  { channel: 'WHATSAPP', priority: 0 },
  { channel: 'SMS', priority: 1 },
];
