import ResponsiveModal from '@components/ResponsiveModal';
import useNotScrollable from '@hooks/useNotScrollable';
import { Button } from '@pedidosya/web-fenix/atoms';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Buttons = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: ${({ theme }) => theme.space('spacing-12')};
`;

function Modal({
  open,
  cancelLabel,
  acceptLabel,
  onClose,
  onAccept,
  closeOnOutsideClick,
  children,
  showCloseIcon,
}) {
  const modalRef = React.createRef();

  const classes = {
    overlay: 'modal__overlay',
  };

  useNotScrollable(modalRef, open);

  return (
    <ResponsiveModal
      classNames={classes}
      open={open}
      onClose={onClose}
      showCloseIcon={showCloseIcon}
      center
      closeOnEsc={closeOnOutsideClick}
      closeOnOverlayClick={closeOnOutsideClick}
      ref={modalRef}
      blockScroll={false}
    >
      <ChildrenContainer id="modal__content">{children}</ChildrenContainer>
      <Buttons data-testid="modal-buttons">
        <Button fullWidth label={acceptLabel || 'Aceptar'} size="large" onClick={onAccept} />
        <Button
          fullWidth
          hierarchy="secondary"
          label={cancelLabel || 'Cancelar'}
          size="large"
          onClick={onClose}
        />
      </Buttons>
    </ResponsiveModal>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  cancelLabel: PropTypes.string,
  acceptLabel: PropTypes.string,
  closeOnOutsideClick: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  children: PropTypes.node,
  showCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  closeOnOutsideClick: true,
  showCloseIcon: false,
};

export default Modal;
