import { SEND_PHONE_CODE_ERROR_CODES } from '@commons/services/constants/ErrorCodes';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'phoneValidation.enterPhoneNumber.title',
    defaultMessage: 'Ingresa tu celular',
  },
  countriesTitle: {
    id: 'phoneValidation.enterPhoneNumber.countriesTitle',
    defaultMessage: 'Elegir país',
  },
  [SEND_PHONE_CODE_ERROR_CODES.INVALID_PHONE_NUMBER]: {
    id: 'phoneValidation.enterPhoneNumber.sendCode.errors.invalidPhoneNumber',
    defaultMessage: 'El formato es incorrecto',
  },
  [SEND_PHONE_CODE_ERROR_CODES.QUOTA_EXCEEDED]: {
    id: 'phoneValidation.enterPhoneNumber.sendCode.errors.quotaExceeded',
    defaultMessage: 'Lo sentimos, hubo demasiados intentos. Por favor vuelve a probar más tarde.',
  },
  genericError: {
    id: 'phoneValidation.enterPhoneNumber.sendCode.errors.genericError',
    defaultMessage: 'No pudimos validar tu número de celular. Reintenta en unos minutos.',
  },
});

export default messages;
