import { usePublicEnv } from '@app/contexts/PublicEnv';
import tagPlus from '@assets/plus/plus-tag.svg';
import { useMe } from '@hooks/use-me';
import { useUserPlusSubscription } from '@hooks/use-user-plus-subscription';
import { UserCardMenu } from '@pedidosya/web-bottom-nav';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${({ theme }) => theme.space('spacing-08')};
`;

const MenuNavigation = ({ onCameraClick }) => {
  const { environment, country } = usePublicEnv();
  const { data: me, isLoading: isLoadingMe } = useMe();
  const { data: isPlus } = useUserPlusSubscription();
  const user = me
    ? {
        avatar: me?.avatar,
        name: me?.name,
        lastName: me?.lastName,
        nickname: me?.nickname,
        email: me?.email,
        isPlus,
        tagPlusSrc: tagPlus,
      }
    : null;

  return (
    <Container id="parcel-container">
      {!isLoadingMe && (
        <UserCardMenu
          env={environment}
          countryShortName={country.shortName}
          user={user}
          onCameraClick={onCameraClick}
        />
      )}
    </Container>
  );
};

MenuNavigation.propTypes = {
  onCameraClick: PropTypes.func,
};

export { MenuNavigation };
