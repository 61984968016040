import * as React from 'react';
import { GlobalRoutePaths } from '@app/routes';
import Error from '@pedidosya/web-fenix/pages/Error';

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface State {
  showComponent: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showComponent: true };
  }

  private static isChunkLoadingError(error: Error): boolean {
    return (
      error?.message.indexOf('Loading chunk') !== -1 ||
      error?.message.indexOf('Loading CSS chunk') !== -1
    );
  }

  public componentDidCatch(error: Error): void {
    if (!ErrorBoundary.isChunkLoadingError(error)) {
      this.setState({
        showComponent: false,
      });

      this.log(error);
    }
  }

  goToHome() {
    window.location.href = GlobalRoutePaths.Home;
  }

  private log(error: Error): void {
    console.log('[APP_ERROR]', { error });
  }

  public render(): React.ReactNode {
    const { showComponent } = this.state;
    const { children, fallback } = this.props;

    return showComponent
      ? children
      : fallback || (
          <Error primaryActionLabel="Volver al inicio" primaryActionClick={this.goToHome} />
        );
  }
}
