import {
  DEV_ENVIRONMENT,
  STG_ENVIRONMENT,
  PROD_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
} from '@appscore/web-constants/environments';

const BASE_URL = {
  [`${LOCAL_ENVIRONMENT}`]: 'https://stg-authenticate.pedidosya.com',
  [`${DEV_ENVIRONMENT}`]: 'https://stg-authenticate.pedidosya.com',
  [`${STG_ENVIRONMENT}`]: 'https://stg-authenticate.pedidosya.com',
  [`${PROD_ENVIRONMENT}`]: 'https://authenticate.pedidosya.com',
};

const ORIGINS = {
  [`${LOCAL_ENVIRONMENT}`]: 'bG9jYWwtcGVkaWRvc3lhLXdlYg==',
  [`${DEV_ENVIRONMENT}`]: 'ZGV2LXBlZGlkb3N5YS13ZWI=',
  [`${STG_ENVIRONMENT}`]: 'cGVkaWRvc3lhLXdlYg%3D%3D',
  [`${PROD_ENVIRONMENT}`]: 'cGVkaWRvc3lhLXdlYg%3D%3D',
};

type Params = Record<string, string>;

type GetLoginUrlProps = {
  environment: string;
  countryShortName: string;
  path?: string;
  params?: Params;
};

function getJoinedParameters(params: Params) {
  const entries = Object.entries(params);
  if (entries.length === 0) {
    return '';
  }

  let joinedParams = '';
  for (const [key, val] of entries) {
    joinedParams += `&${key}=${val}`;
  }

  return joinedParams;
}

export function getExternalLoginUrl({
  environment,
  countryShortName,
  path = '',
  params = {},
}: GetLoginUrlProps) {
  let env = environment;
  if (![LOCAL_ENVIRONMENT, DEV_ENVIRONMENT, PROD_ENVIRONMENT].includes(env)) {
    env = STG_ENVIRONMENT;
  }

  const QUERY_PARAMS = getJoinedParameters(params);

  return `${BASE_URL[env]}/${path || ''}?c=.${countryShortName}&loginFrom=${
    ORIGINS[env]
  }${QUERY_PARAMS}`;
}
