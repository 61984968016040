import { Flags } from '@fwf/flags';
import { useFlagVariation } from '@providers/FunWithFlagsProvider';

export function useFlagVariations() {
  const isAuthenticationWithDeviceEnabled = useFlagVariation<boolean>(
    Flags.MyAccountAuthenticateWithDevice,
    false,
  );
  const isBottomNavHomeEnabled =
    useFlagVariation<string>(Flags.BottomNavHomeExperiment, 'Control') !== 'Control';
  const isPhoneValidationEnabled = useFlagVariation<boolean>(Flags.PhoneValidation, false);

  const isMyAccountBannerEnabled = useFlagVariation<boolean>(Flags.MyAccountBannerEnabled, false);

  return {
    isAuthenticationWithDeviceEnabled,
    isBottomNavHomeEnabled,
    isPhoneValidationEnabled,
    isMyAccountBannerEnabled,
  };
}
