export enum TrackingEvents {
  BottomSheetClicked = 'bottom_sheet.clicked',
  BottomSheetShown = 'bottom_sheet.shown',
  ModalClosed = 'modal.closed',
  ModalLoaded = 'modal.loaded',
  MyAccountLoaded = 'my_account.loaded',
  MyAccountClicked = 'my_account.clicked',
  MyAccountUpdated = 'my_account.updated',
  MyAccountUpdateFailed = 'my_account_update.failed',
  DeleteAccountCompleted = 'delete_account.completed',
  DeleteAccountFailed = 'delete_account.failed',
  PersonalInfoScreenClicked = 'personal_info_screen.clicked',
  MyPersonalInfoUpdateAppFailed = 'my_personal_info_update.app_failed',
  MyPersonalInfoUpdateLoaded = 'my_personal_info_update.loaded',
  MyPersonalInfoUpdateClicked = 'my_personal_info_update.clicked',
  GenderCustomFilled = 'gender_custom.filled',
  MyPersonalInfoUpdateCompleted = 'my_personal_info_update.completed',
  MyPersonalInfoUpdateFailed = 'my_personal_info_update.failed',
  ChangePasswordLoaded = 'change_password.loaded',
  ChangePasswordCompleted = 'change_password.completed',
  ChangePasswordFailed = 'change_password.failed',
  PhoneValidationStarted = 'phone_validation.started',
  PhoneValidationInsertCode = 'phone_validation.insert_code',
  PhoneValidationSent = 'phone_validation.sent',
  PhoneValidationResent = 'phone_validation.resent',
  PhoneValidationCompleted = 'phone_validation.completed',
  PhoneValidationFailed = 'phone_validation.failed',
  BannerLoaded = 'highlight_banner.loaded',
  BannerClicked = 'highlight_banner.clicked',
  LogoutSubmitted = 'logout.submitted',
  MyFavoritesLoaded = 'my_favorites.loaded',
  FavoritesClicked = 'favorites.clicked',
  ShopClicked = 'shop.clicked',
}

export enum TrackingActions {
  Cancel = 'cancel',
  ChangePassword = 'change_password',
  ChangeUsername = 'change_username',
  TakePicture = 'take_picture',
  SelectPicture = 'select_picture',
  ChangePicture = 'change_picture',
  DeletePicture = 'delete_picture',
}

export enum TrackingClickLocations {
  ChangePassword = 'change_password',
  LoginRegister = 'login_register',
  Logout = 'logout',
  LogoutAll = 'logout_all',
  Username = 'user_name',
  ProfilePicture = 'profile_picture',
}

export enum TrackingOrigins {
  Home = 'home',
  Profile = 'profile',
}

export enum TrackingStatuses {
  Off = 'off',
  On = 'on',
}

export enum BottomSheetTrackingView {
  Low = 'low',
}

export enum BottomSheetTrackingVariation {
  ProfilePicture = 'my_account_profile_picture',
}

export enum TrackingBannerScreenType {
  Profile = 'profile',
}

export enum TrackingBannerHighlightType {
  SubscribeToPlus = 'suscribite_a_plus',
}

export enum Platforms {
  PedidosYa = 'pedidosya',
}

export enum Device {
  Desktop = 'desktop',
  Huawei = 'huawei_webview',
  Mobile = 'mobile',
  Tablet = 'tablet',
}
