import type { CountryPhoneConfig } from '@app/types';
import { PHONE_VALIDATION_ENDPOINTS } from '@services/api-gateway-urls';
import * as queryKeys from '@utils/query-key-factory';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

type CountryPhoneConfigResponse = {
  id: string;
  name: string;
  phone_placeholder: string;
  phone_prefix: string;
  short_name: string;
};

type CountriesPhoneConfigResponse = {
  countries: CountryPhoneConfigResponse[];
};

export function useCountriesPhoneConfig(): UseQueryResult<CountryPhoneConfig[], any> {
  return useQuery(
    queryKeys.countriesPhoneConfiguration(),
    async function getCountriesPhoneConfig(): Promise<unknown> {
      const { data } = await axios.get<CountriesPhoneConfigResponse>(
        PHONE_VALIDATION_ENDPOINTS.COUNTRIES_PHONE_CONFIG,
      );
      const mappedCountries = data.countries.map((country) => {
        return {
          id: country.id,
          name: country.name,
          phonePlaceholder: country.phone_placeholder,
          phonePrefix: country.phone_prefix,
          shortName: country.short_name,
        };
      });

      return mappedCountries;
    },
    {
      retry: 0,
    },
  );
}
