import * as React from 'react';

type ViewPortWindowHeightHackFix = {
  /**
   * Viewport property name.
   */
  name?: string;
};

export function ViewPortWindowHeightHackFix({
  name = '--vh',
}: ViewPortWindowHeightHackFix): JSX.Element {
  React.useEffect(() => {
    function setViewPortStyleProperty(): void {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty(name, `${vh}px`);
    }

    function setViewPortStylePropertyOnResize(): void {
      setViewPortStyleProperty();
    }

    window.addEventListener('resize', setViewPortStylePropertyOnResize);

    setViewPortStyleProperty();

    return () => {
      document.documentElement.style.removeProperty(name);
      window.removeEventListener('resize', setViewPortStylePropertyOnResize);
    };
  }, [name]);

  return null;
}
