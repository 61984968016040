import BottomSheet from '@components/BottomSheet';
import { Text } from '@components/Fenix/Typography';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import PropTypes from 'prop-types';
import React from 'react';

import {
  BottomSheetContainer,
  CountryContainer,
  CountryContent,
  CountryFlag,
  CountryName,
} from './CountryConfigurationsComponents';

function CountryConfigurations({ title, countries, selectedCountry, onSelect, open, onClickOut }) {
  const selectConfiguration = (newSelectedCountry) => {
    onSelect(newSelectedCountry);
    onClickOut();
  };

  const imageSrc = (countryShortname) =>
    `https://pedidosya.dhmedia.io/image/pedidosya/flags/v2/flag-${countryShortname}.png?quality=70&width=100`;

  if (!countries) return <></>;

  return (
    <BottomSheet open={open} onClickOut={onClickOut} onCancel={onClickOut} title={title}>
      <BottomSheetContainer>
        {countries?.map((country) => {
          const isSelectedCountry = selectedCountry?.id === country.id;

          return (
            <CountryContainer key={country.id} onClick={() => selectConfiguration(country)}>
              <CountryContent>
                <CountryFlag src={imageSrc(country.shortName)} />
                <CountryName>
                  <Text size="medium">{country.name}</Text>
                </CountryName>
                {isSelectedCountry && (
                  <FenixIcon token="check" color="icon-color-primary" size="medium" />
                )}
              </CountryContent>
            </CountryContainer>
          );
        })}
      </BottomSheetContainer>
    </BottomSheet>
  );
}

const CountryShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  phonePlaceholder: PropTypes.string.isRequired,
  phonePrefix: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
});

CountryConfigurations.propTypes = {
  title: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(CountryShape),
  selectedCountry: CountryShape,
  onClickOut: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CountryConfigurations;
