import { TrackingEvents, TrackingOrigins } from '@app/tracking';
import { Layout } from '@components/Layout';
import OverlayLoader from '@components/OverlayLoader';
import { PageTitle } from '@components/Shell';
import { useDeleteFavoriteVendor } from '@hooks/use-delete-favorite-vendor';
import { useUserFavoriteVendors } from '@hooks/use-user-favorite-vendors';
import fenixTheme from '@pedidosya/web-fenix/theme';
import Error from '@pedidosya/web-fenix/pages/Error';
import { useDevice } from '@providers/DeviceProvider';
import { useIntl } from '@providers/IntlProvider';
import { useToast } from '@providers/ToastProvider';
import { useTracker } from '@providers/TrackerProvider';

import * as React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import {
  TrackingFavoriteAction,
  TrackingFavoriteTab,
  TrackingShopCLickOrigin,
  TrackingShopStatus,
} from './constants';
import { Empty } from './empty';
import messages from './messages';
import Vendor, { VendorStateStatus } from './model';
import { FavoritesSkeleton } from './skeleton';
import SubtitleSection from './components/SubtitleSection';
import VendorList from './components/VendorList';

const ListContainer = styled.div<{ isDesktop: boolean }>`
  padding: 0 ${({ isDesktop }) => (isDesktop ? '10%' : '0')};
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.color('shape-color-surface-quaternary')};
  width: 100%;
  height: ${({ theme }) => theme.space('spacing-04')};
`;

const SHOP_STATUS_TRACKING = {
  [VendorStateStatus.OPEN]: TrackingShopStatus.Open,
  [VendorStateStatus.PICKUP]: TrackingShopStatus.OnlyPickup,
  [VendorStateStatus.PRE_ORDER]: TrackingShopStatus.OpensLaterPreorder,
  [VendorStateStatus.OPEN_IN]: TrackingShopStatus.OpensLaterNoPreorder,
  [VendorStateStatus.NO_DELIVERY_AVAILABLE]: TrackingShopStatus.NoDeliveryNow,
  [VendorStateStatus.TEMPORARILY_CLOSED]: TrackingShopStatus.MomentarilyClosed,
  [VendorStateStatus.CLOSED]: TrackingShopStatus.ClosedToday,
  [VendorStateStatus.OUT_OF_REACH]: TrackingShopStatus.OutOfLocation,
};

function Favorites(): JSX.Element {
  const [isNavigating, setIsNavigating] = React.useState(false);
  const [vendorsToShow, setVendorsToShow] = React.useState({
    available: [],
    unavailable: [],
    length: 0,
  });
  const { formatMessage } = useIntl();
  const { isDesktop } = useDevice();
  const navigate = useNavigate();
  const toast = useToast();
  const tracker = useTracker();
  const { data: favoriteVendors, isLoading: isLoadingFavoriteVendors } = useUserFavoriteVendors();
  const { mutateAsync: deleteFavoriteVendorAsync } = useDeleteFavoriteVendor();

  const handleGoBackPress = () => {
    tracker.track(TrackingEvents.FavoritesClicked, {
      favoriteAction: TrackingFavoriteAction.Back,
      favoriteOrigin: TrackingOrigins.Profile,
    });
    navigate(-1);
  };

  const handleFavoritePress = async (id: number) => {
    tracker.track(TrackingEvents.FavoritesClicked, {
      favoriteAction: TrackingFavoriteAction.DeletePartner,
      favoriteOrigin: TrackingOrigins.Profile,
    });

    const vendorsAvailableWithUnfavorite = vendorsToShow.available.map((v) => ({
      ...v,
      isFav: !(v.id === id),
    }));
    const vendorsUnavailableWithUnfavorite = vendorsToShow.unavailable.map((v) => ({
      ...v,
      isFav: !(v.id === id),
    }));
    setVendorsToShow({
      available: vendorsAvailableWithUnfavorite,
      unavailable: vendorsUnavailableWithUnfavorite,
      length: vendorsAvailableWithUnfavorite.length + vendorsUnavailableWithUnfavorite.length,
    });

    const { success, error } = await deleteFavoriteVendorAsync({ id });
    if (success) {
      const vendorsAvailableFiltered = vendorsToShow.available.filter((v) => v.id !== id);
      const vendorsUnavailableFiltered = vendorsToShow.unavailable.filter((v) => v.id !== id);
      setVendorsToShow({
        available: vendorsAvailableFiltered,
        unavailable: vendorsUnavailableFiltered,
        length: vendorsAvailableFiltered.length + vendorsUnavailableFiltered.length,
      });
      toast.success(formatMessage(messages.deleteFavoriteVendorSuccessMessage));
    }
    if (error) {
      const favoriteVendorsAvailable = vendorsToShow.available.map((v) => ({
        ...v,
        isFav: true,
      }));
      const favoriteVendorsUnavailable = vendorsToShow.unavailable.map((v) => ({
        ...v,
        isFav: true,
      }));
      setVendorsToShow({
        available: favoriteVendorsAvailable,
        unavailable: favoriteVendorsUnavailable,
        length: favoriteVendorsAvailable.length + favoriteVendorsUnavailable.length,
      });
      toast.error(formatMessage(messages.deleteFavoriteVendorErrorMessage));
    }
  };

  const handleVendorItemPress = ({ id, name, url, state }: Vendor) => {
    tracker.track(TrackingEvents.ShopClicked, {
      screenType: TrackingOrigins.Profile,
      shopClickOrigin: TrackingShopCLickOrigin.Partner,
      shopId: id,
      shopName: name,
      shopStatus: SHOP_STATUS_TRACKING[state.status] ?? '(not_set)',
    });
    if (!!url) {
      setIsNavigating(true);
      window.location.assign(url);
    }
  };

  React.useEffect(() => {
    document.body.style.backgroundColor = fenixTheme.color('shape-color-background-primary');
  }, []);

  React.useEffect(() => {
    if (!isLoadingFavoriteVendors && favoriteVendors !== null) {
      const vendors = favoriteVendors ?? [];
      const vendorsAvailable = vendors.filter((v) => v.available);
      const vendorsUnavailable = vendors.filter((v) => !v.available);
      setVendorsToShow({
        available: vendorsAvailable,
        unavailable: vendorsUnavailable,
        length: vendorsAvailable.length + vendorsUnavailable.length,
      });

      tracker.track(TrackingEvents.MyFavoritesLoaded, {
        favoriteOrigin: TrackingOrigins.Profile,
        favoriteTab: TrackingFavoriteTab.Partners,
        myFavoritesQuantity: vendors.length,
        myFavoritesOpenQuantity: vendorsAvailable.length,
        myFavoritesClosedNowQuantity: vendorsUnavailable.filter(
          (v) =>
            v.state?.status === VendorStateStatus.OPEN_IN ||
            v.state?.status === VendorStateStatus.PRE_ORDER,
        ).length,
        myFavoritesClosedTodayQuantity: vendorsUnavailable.filter(
          (v) => v.state?.status === VendorStateStatus.CLOSED,
        ).length,
        myFavoritesDeliverToLocationQuantity: vendors.filter(
          (v) => v.state?.status !== VendorStateStatus.OUT_OF_REACH,
        ).length,
        itemsQuantityTotal: '(not_set)',
      });
    }
  }, [isLoadingFavoriteVendors]);

  if (isLoadingFavoriteVendors) {
    return (
      <Layout>
        <ListContainer isDesktop={isDesktop}>
          <PageTitle onBack={handleGoBackPress} />
          {isDesktop ? <OverlayLoader /> : <FavoritesSkeleton />}
        </ListContainer>
      </Layout>
    );
  }

  if (favoriteVendors === null) {
    return (
      <Error
        errorCode={504}
        title={formatMessage(messages.serviceFailureTitle)}
        message={formatMessage(messages.serviceFailureSubtitle)}
        primaryActionLabel={formatMessage(messages.serviceFailureCTA)}
        primaryActionClick={() => navigate(0)}
      />
    );
  }

  return (
    <Layout isLoading={isNavigating}>
      <ListContainer isDesktop={isDesktop}>
        <PageTitle
          title={formatMessage(messages.title)}
          onBack={handleGoBackPress}
          withMobileMarginBottom={false}
        />
        {vendorsToShow.length === 0 && <Empty onButtonClick={() => setIsNavigating(true)} />}
        {vendorsToShow.length > 0 && (
          <SubtitleSection
            vendorsAvailable={vendorsToShow.available}
            vendorsUnavailable={vendorsToShow.unavailable}
          />
        )}
        {vendorsToShow.available.length > 0 && (
          <VendorList
            vendors={vendorsToShow.available}
            onFavoritePress={handleFavoritePress}
            onVendorItemPress={handleVendorItemPress}
          />
        )}
        {vendorsToShow.available.length > 0 && vendorsToShow.unavailable.length > 0 && (
          <Separator />
        )}
        {vendorsToShow.unavailable.length > 0 && (
          <VendorList
            vendors={vendorsToShow.unavailable}
            onFavoritePress={handleFavoritePress}
            onVendorItemPress={handleVendorItemPress}
          />
        )}
      </ListContainer>
    </Layout>
  );
}

export { Favorites };
