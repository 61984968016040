import { getTimeFromTimezoneDate } from '@utils/utils';
import {
  VENDOR_CONDITIONS,
  VENDOR_DISCOUNT_TYPE,
  VENDOR_STATE_LABEL,
  VENDOR_STATE_TAG,
} from './constants';

type DeliveryTimeDTO = {
  lower: number;
  upper: number;
  speed: string;
};

type DeliveryPriceDTO = {
  total: number;
  totalWithDiscount?: number;
};

type DeliveryDTO = {
  type: string;
  feeVariant: string;
  deliveryTime?: DeliveryTimeDTO;
  price?: DeliveryPriceDTO;
};

type PickupDistanceDTO = {
  valueInMts?: number;
};

type PickupTimeDTO = {
  valueInMin?: number;
};

type PickupDTO = {
  distance?: PickupDistanceDTO;
  time?: PickupTimeDTO;
};

type VendorStateDTO = {
  status: string;
  until?: string;
};

type RatingDTO = {
  score: number;
};

enum DiscountDetailType {
  SUBSIDIZED_PRODUCT = 'SUBSIDIZED_PRODUCT',
  WEEKLY = 'WEEKLY',
  SUBSIDIZED_PRODUCT_PLUS = 'SUBSIDIZED_PRODUCT_PLUS',
  JOKER = 'JOKER',
}

enum DiscountDetailVariant {
  PERCENTAGE = 'PERCENTAGE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

type DiscountDetailDTO = {
  amount: number;
  type: DiscountDetailType;
  variant: DiscountDetailVariant;
};

type VendorDTO = {
  id: number;
  name: string;
  logo?: string;
  url?: string;
  categories?: string[];
  delivery?: DeliveryDTO;
  pickup?: PickupDTO;
  state?: VendorStateDTO;
  rating?: RatingDTO;
  discountDetail?: DiscountDetailDTO;
  isNew?: boolean;
  isCashOnly?: boolean;
  hasLoyaltyDiscount: boolean;
};

export enum VendorListState {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

type VendorListDTO = {
  state: VendorListState;
  vendors: VendorDTO[];
};

export type FavoriteItemsResult = {
  items: VendorListDTO[];
};

export enum VendorStateStatus {
  OPEN = 'OPEN',
  OPEN_IN = 'OPEN_IN',
  PICKUP = 'PICKUP',
  CLOSED = 'CLOSED',
  TEMPORARILY_CLOSED = 'TEMPORARILY_CLOSED',
  NO_DELIVERY_AVAILABLE = 'NO_DELIVERY_AVAILABLE',
  PRE_ORDER = 'PRE_ORDER',
  OUT_OF_REACH = 'OUT_OF_REACH',
}

type VendorState = {
  status: VendorStateStatus;
  label?: string;
  tag?: string;
  until?: string;
};

type Rating = {
  score: string;
};

type Discount = {
  amount: string;
  type: string;
};

enum VendorDeliveryTimeSpeed {
  DEFAULT = 'DEFAULT',
  QUICK = 'QUICK',
  DELAY = 'DELAY',
}

type DeliveryTime = {
  delay: string;
  speed: VendorDeliveryTimeSpeed;
};

type DeliveryPrice = {
  total: number;
  totalWithDiscount?: number;
};

enum VendorDeliveryFeeVariant {
  DEFAULT = 'DEFAULT',
  FREE = 'FREE',
  VARIABLE = 'VARIABLE',
  PLUS_FREE = 'PLUS-FREE',
  PLUS_DISCOUNT = 'PLUS-DISCOUNT',
}

type Delivery = {
  feeVariant: VendorDeliveryFeeVariant;
  time?: DeliveryTime;
  price?: DeliveryPrice;
};

type PickupDistance = {
  value: string;
};

type PickupTime = {
  delay: string;
};

type Pickup = {
  distance?: PickupDistance;
  time?: PickupTime;
};

interface IVendor {
  id: number;
  state?: VendorState;
  name: string;
  logo?: string;
  isNew: boolean;
  rating?: Rating;
  categories?: string[];
  isPlus: boolean;
  discount?: Discount;
  conditions?: string[];
  delivery?: Delivery;
  pickup?: Pickup;
  url?: string;
}

function getLabel(state: VendorStateDTO) {
  switch (state.status.toUpperCase()) {
    case VendorStateStatus.OPEN_IN:
    case VendorStateStatus.PRE_ORDER:
      return VENDOR_STATE_LABEL.OPEN_IN;
    case VendorStateStatus.NO_DELIVERY_AVAILABLE:
      return VENDOR_STATE_LABEL.NO_DELIVERY_AVAILABLE;
    case VendorStateStatus.CLOSED:
      return VENDOR_STATE_LABEL.CLOSED;
    case VendorStateStatus.TEMPORARILY_CLOSED:
      return VENDOR_STATE_LABEL.TEMPORARILY_CLOSED;
    case VendorStateStatus.OUT_OF_REACH:
      return VENDOR_STATE_LABEL.OUT_OF_REACH;
    default:
      return '';
  }
}

function getTag(state: VendorStateDTO) {
  switch (state.status.toUpperCase()) {
    case VendorStateStatus.PICKUP:
      return VENDOR_STATE_TAG.PICKUP;
    case VendorStateStatus.PRE_ORDER:
      return VENDOR_STATE_TAG.PRE_ORDER;
    default:
      return '';
  }
}

function getVendorState(state: VendorStateDTO): VendorState {
  const vendorStateStatus = Object.values(VendorStateStatus);
  const status = vendorStateStatus.find((s) => state.status.toUpperCase() === s);
  const label = getLabel(state);
  const tag = getTag(state);
  const until =
    label === VENDOR_STATE_LABEL.OPEN_IN ? getTimeFromTimezoneDate(state.until) : undefined;

  return { status, label, tag, until };
}

function getVendorLogo(logo?: string) {
  const PEYA_IMAGES_URL = `https://pedidosya.dhmedia.io/image/pedidosya`;

  return logo ? `${PEYA_IMAGES_URL}/restaurants/${logo}?quality=70&width=100` : '';
}

function getDiscount(discountDetail: DiscountDetailDTO): Discount {
  const isPercentageDiscount = discountDetail.variant === DiscountDetailVariant.PERCENTAGE;
  const isWeeklyType = discountDetail.type === DiscountDetailType.WEEKLY;

  return {
    amount: `${discountDetail.amount}${isPercentageDiscount ? '%' : ''}`,
    type: isWeeklyType ? VENDOR_DISCOUNT_TYPE.FIXED : VENDOR_DISCOUNT_TYPE.UP_TO,
  };
}

function getConditions(isCashOnly?: boolean, delivery?: DeliveryDTO) {
  const conditions: string[] = [];

  if (isCashOnly) {
    conditions.push(VENDOR_CONDITIONS.ONLY_CASH);
  }

  if (delivery && delivery.type === VENDOR_CONDITIONS.VENDOR_DELIVERY) {
    conditions.push(VENDOR_CONDITIONS.VENDOR_DELIVERY);
  }

  return conditions;
}

function getDelivery(delivery: DeliveryDTO): Delivery {
  const { lower, upper, speed } = delivery.deliveryTime || {};

  const time = {
    delay: lower != null && upper != null ? `${lower}-${upper} min` : '',
    speed: speed as VendorDeliveryTimeSpeed,
  };

  return {
    time,
    price: delivery.price,
    feeVariant: delivery.feeVariant as VendorDeliveryFeeVariant,
  };
}

function getPickup(pickup: PickupDTO): Pickup {
  const { valueInMts } = pickup.distance || {};
  const { valueInMin } = pickup.time || {};

  const distance = valueInMts != null ? { value: `${valueInMts} m` } : undefined;
  const time = valueInMin != null ? { delay: `${valueInMin} min` } : undefined;

  return { distance, time };
}

class Vendor implements IVendor {
  id: number;
  state?: VendorState;
  name: string;
  logo?: string;
  isNew: boolean;
  rating?: Rating;
  categories?: string[];
  isPlus: boolean;
  discount?: Discount;
  conditions?: string[];
  delivery?: Delivery;
  pickup?: Pickup;
  url: string;
  isFav: boolean;
  available: boolean;

  constructor(dto: VendorDTO, available: boolean) {
    this.id = dto.id;
    this.state = getVendorState(dto.state);
    this.logo = getVendorLogo(dto.logo);
    this.name = dto.name;
    this.isNew = dto.isNew ?? false;
    this.rating = dto.rating ? { score: dto.rating.score.toString() } : undefined;
    this.categories = dto.categories;
    this.isPlus =
      dto.hasLoyaltyDiscount ||
      dto.discountDetail?.type === DiscountDetailType.SUBSIDIZED_PRODUCT_PLUS;
    this.discount = dto.discountDetail ? getDiscount(dto.discountDetail) : undefined;
    this.conditions = getConditions(dto.isCashOnly, dto.delivery);
    this.delivery = dto.delivery != null ? getDelivery(dto.delivery) : undefined;
    this.pickup = dto.pickup != null ? getPickup(dto.pickup) : undefined;
    this.url = dto.url;
    this.isFav = true;
    this.available = available;
  }
}

export default Vendor;
